<template>
    <el-container>
        <el-main>
            <search-cosing v-on:search-end="searchFreash" v-on:re-search="reLoadList"></search-cosing>
            <!-- <div class="empty-box"></div>
            <el-pagination background class="t-center"
                :current-page.sync="page_no"
                :page-size="page_size"
                layout="prev, pager, next"
                :total="total"
                @current-change="pageChange">
            </el-pagination>
            <div class="empty-box"></div> -->
            <el-table :data="dataList" stripe>
                <el-table-column prop="id" label="ID" width="100"></el-table-column>
                <el-table-column prop="inci_name" label="原料名称" min-width="300"></el-table-column>
                <el-table-column prop="cas_num" label="CAS编号"></el-table-column>
                <el-table-column prop="sccs_opinions" label="SCCS">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.sccs_opinions" type="success">有</el-tag>
                        <!-- <el-tag v-if="scope.row.type == 1" type="success">色素</el-tag>
                        <el-tag v-if="scope.row.type == 2" type="success">防腐剂</el-tag>
                        <el-tag v-if="scope.row.type == 3" type="success">防晒剂</el-tag> -->
                    </template>
                </el-table-column>
                <el-table-column label="查看" width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-view" type="primary" plain round  @click="viewDetail(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="编辑" width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-edit" type="warning" plain round  @click="editRef(scope.row)">修改</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
            <div class="empty-box"></div> 
            <el-pagination background class="t-center"
                @current-change="pageChange"
                :current-page.sync="page_now"
                :page-size="page_size"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
            <div class="empty-box"></div>
        </el-main>
        <view-pdf ref="viewPDFCOM"></view-pdf>
        <view-cosing ref="viewCosingTem" v-on:view-pdf="viewPdf"></view-cosing>
    </el-container>
</template>

<script>
import SearchCosing from '../components/search-cosing.vue';
// import UsInciList from '../components/us-inci-list.vue';
import {getCosingList, postSearchCosing} from "../service/api";

import viewPdf from '../components/view-pdf.vue';
import ViewCosing from '../components/cosing/view-cosing';
export default {
    components: {SearchCosing, viewPdf, ViewCosing},
    data (){
        return {
            page_size: 20,
            page_no: 1,
            page_now: 1,
            total: 0,
            dataList: [],
            key: ''
        }
    },
    methods:{
        handleSelect(key, index){
            this.checkIndex = index;
            this.country = key;
            this.getList();
        },
        initData(data){
            this.total = data.total;
            this.page_now = parseInt(data.page_no);
            this.dataList = data.list;
            localStorage.setItem('cosing_pageNo',this.page_now);
        },
        getList(){
            // if()
            // console.log(country);
            getCosingList({
                page_no: this.page_no,
                page_size: this.page_size
            }).then(res => {
                let data = res.data;
                if(data.total && data.total > 0){
                    this.initData(data);
                    // console.log(222);
                }
                // console.log(res);
            }, err => {
                this.$message.error(err.msg);
            });
        },
        searchList(key){
            if(key){
                this.key = key;

                let keyWord = this.key.replace(/^[\s]|[\s*]$/g, '');
                postSearchCosing({
                    key: encodeURIComponent(keyWord),
                    page_no: this.page_no,
                    page_size: this.page_size
                }).then(res => {
                    // this.$emit('search-end', res.data);
                    let data = res.data;
                    if(data.total && data.total > 0){
                        this.initData(data);
                        // console.log(222);
                    }
                }, err => {
                    this.$message.error(err.msg);
                });
            }
        },
        pageChange(page){
            // console.log(page, page !== this.page_no);
            // console.log(this.page_no);
            if(page !== this.page_no) {
                if(this.key !== ''){
                    this.page_no = page;
                    this.searchList(this.key);
                }else{
                    // console.log(2222);
                    this.page_no = page;
                    this.getList();
                }
            }
        },
        viewPdf(data){
            this.$refs.viewPDFCOM.showDrawer({
                title: data.title,
                url: data.url
            });
        },
        viewDetail(ite){
            this.$refs.viewCosingTem.open(ite.id);
        },
        doneAdd(){
            this.getList();
        },
        searchFreash(key){
            if(key){
                this.key = key;
                this.page_no = 1;
                this.searchList(this.key);
            }else{
                this.key = '';
                this.getList();
            }
            // localStorage.cosing_key = this.key;
            localStorage.setItem('cosing_key', this.key);
        },
        reLoadList(){
            this.page_no = 1;
            this.key = '';
            localStorage.setItem('cosing_key', this.key);
            this.getList();
        }
    },
    mounted: function(){
        // let query = this.$route.query;
        // console.log(query);
        let localPage = localStorage.getItem('cosing_pageNo');
        let localKey = localStorage.getItem('cosing_key');
        let key = localKey ? localKey : '';
        this.page_no = localPage ? localPage : 1;
        this.searchFreash(key);
    }
}
</script>
<style>
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 150px;
    font-size: 12px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>