<template>
    <el-container>
        <el-main>
            <h3>欧洲/加拿大 禁用原料查询</h3>
            <!-- <h3 v-if="country == 1">美国（US）INCI</h3>
            <h3 v-if="country == 2">欧盟 (EU) INCI</h3>
            <h3 v-if="country == 3">欧盟 (CA)) INCI</h3> -->
            <el-form label-width="100px">
                <el-form-item label="国家/地区">
                    <el-radio-group v-model="country">
                        <el-radio label="2">欧洲（US）</el-radio>
                        <el-radio label="3">加拿大（UA）</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="原料名称">
                    <el-input type="textarea" rows="4" v-model="keys" placeholder="请输入原料名称/CAS编号，每一行代表一个名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="doSearch('name')">化学名称查询</el-button>
                    <el-button type="danger" @click="doSearch('cas')">CAS编号查询</el-button>
                </el-form-item>
            </el-form>

            <el-table :data="dataList"
            stripe :row-class-name="tableRowClassName">
                <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
                <el-table-column prop="key" label="查询名称"></el-table-column>
                <el-table-column prop="chemical_name" label="INCI/化学名称"></el-table-column>
                <el-table-column prop="cas_num" label="CAS"></el-table-column>
                <el-table-column label="是否禁用" width="80">
                    <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.pair ? 'danger' : 'success'"
                    disable-transitions>{{scope.row.pair ? '禁用' : '可用'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="Cosing数据" v-if="country == 2">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-view"
                        v-if="scope.row.cosing_list"
                         type="success" plain round  @click="viewCosingList(scope.row)">查看</el-button>
                        
                        <el-button size="mini"
                        v-if="!scope.row.cosing_list" disable
                         type="info" plain round>未找到</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="编辑/修改" width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-edit" type="primary" plain round  @click="editRef(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <edit-prohibit ref="EditUaInci" v-on:add-suc="doneAdd"></edit-prohibit>
        <view-same-list ref="showCosingList"></view-same-list>
    </el-container>
</template>
<script>
import {checkProhibited} from "../service/api";
import EditProhibit from './edit-prohibit.vue';
import ViewSameList from './view-same-list.vue';
export default {
    components: {EditProhibit, ViewSameList},
    // props: ['country'],
    data(){
        return {
            dataList: [],
            keys: '',
            country: '2',
            searchBy: 'name'
        }
    }, 
    methods: {
        indexMethod(index) {
            return index + 1;
        },
        doSearch(tp){
            // console.log(1);
            if(!tp){
                tp = this.searchBy;
            }
            this.searchBy = tp;
            let searchNames = this.keys;
            if(searchNames){
                let searchArry;
                // console.log(searchNames.search(/\n/));
                if(searchNames.search(/\n/) < 0){
                    searchArry = [searchNames];
                }else{
                    searchArry = searchNames.split(/\n/);
                }

                let subArr = [];

                for(let i = 0; i < searchArry.length; i++){
                    let ite = searchArry[i];
                    if(ite && ite !== ''){
                        // ite = ite.replace(/(\s)*(）|\))(\s)*/g, '）');
                        // ite = ite.replace(/(\s)*(（|\()(\s)*/g, '（');
                        ite = ite.replace(/(^\s*)|(\s*$)/g, '');
                        // ite = ite.replace(/^\s*/g, '');
                        subArr.push(ite);
                    }
                }


                // console.log(subArr);
                checkProhibited({
                    search_type: tp,
                    country: this.country,
                    keys: encodeURIComponent(JSON.stringify(subArr))
                }).then(res => {
                    // let data = res.data;
                    this.dataList = res.data;
                    // console.log(res);
                }, err => {
                    this.$message.error(err.msg);
                });
            }else{
                this.$message.error(`请输入关键词！`);
            }
        },
        tableRowClassName({row}){
            // console.log(row);
            return row && row.pair ? 'none-pair' : '';

        },
        editRef(ite){
            // console.log(ite);
            this.$refs.EditUaInci.editData(ite);
        },
        doneAdd(){
            // this.searchBy = 'name';
            this.doSearch();
        },
        viewCosingList(ite){
            this.$refs.showCosingList.open(ite.cosing_list);
        }
    }
}
</script>