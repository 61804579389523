<template>
    <el-drawer :title="Title" :with-header="false" :visible.sync="showViews" 
    :before-close="handleClose" :append-to-body="true" :close-on-press-escape="false"
    v-loading="loading" body size="85%">
        <div class="my-draw-header">
            <div class="my-draw-title">{{Title}}</div>
            <div class="my-draw-edit-btns">
                <el-button type="warning" @click="handleClose" size="medium" round icon="el-icon-close">关闭</el-button>
            </div>
        </div>
        <div class="view-draw-box">
            <el-form :model="submitData" class="edit-formu-box" label-width="250px">
                <el-tabs :value="formStep" type="border-card" class="edit-iecic-tab">
                    <el-tab-pane label="IECIC数据" name="iecic_data">
                        <el-form-item label="中文名称">
                            <el-input v-model="submitData.cn_name" placeholder="请输入中文名称"></el-input>
                        </el-form-item>
                        <el-form-item label="INCI名称/英文名称">
                            <el-input v-model="submitData.inci_name" placeholder="请输入INCI名称/英文名称"></el-input>
                        </el-form-item>
                        <el-form-item label="中文序号">
                            <el-input v-model="submitData.iecic_num" placeholder="请输入中文序号"></el-input>
                        </el-form-item>
                        <el-form-item label="中文曾用名">
                            <el-input v-model="submitData.former_cn_name" placeholder="请输入曾用名"></el-input>
                        </el-form-item>
                        <el-form-item label="英文曾用名">
                            <el-input v-model="submitData.former_inci_name" placeholder="请输入英文曾用名"></el-input>
                        </el-form-item>
                        <el-form-item label="淋洗类最高用量">
                            <el-input v-model="submitData.iecic_rinse" placeholder="请输入淋洗类最高用量"></el-input>
                        </el-form-item>
                        <el-form-item label="驻留类类最高用量">
                            <el-input v-model="submitData.iecic_reside" placeholder="请输入驻留类类最高用量"></el-input>
                        </el-form-item>
                        <el-form-item label="备注">
                            <el-input v-model="submitData.remark" type="textarea" placeholder="请输入备注"></el-input>
                        </el-form-item>
                        <el-form-item label="《化妆品安全技术规范》要求">
                            <el-input v-model="submitData.csts_info" type="textarea" placeholder="请输入《化妆品安全技术规范》要求"></el-input>
                        </el-form-item>
                        <el-form-item label="评估结论">
                            <el-input v-model="submitData.evaluate" type="textarea" placeholder="请输入评估结论"></el-input>
                        </el-form-item>
                        <el-form-item label="是否被禁用">
                            <el-radio-group size="small" v-model="submitData.type">
                                <el-radio-button :label="0">否</el-radio-button>
                                <el-radio-button :label="1">是</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-tab-pane>
                    <el-tab-pane label="CIR数据" name="cir_data">
                        <el-form-item label="权威机构CIR评估结论">
                            <el-input v-model="submitData.aoac_cir" type="textarea" placeholder="请输入权威机构CIR评估结论"></el-input>
                        </el-form-item>
                        <el-form-item label="CIR淋洗类产品最高历史使用量（%）">
                            <el-input v-model="submitData.cir_rinse" placeholder="请输入CIR淋洗类产品最高历史使用量（%）"></el-input>
                        </el-form-item>
                        <el-form-item label="CIR驻留类产品最高历史使用量（%）">
                            <el-input v-model="submitData.cir_reside" placeholder="请输入CIR驻留类产品最高历史使用量（%）"></el-input>
                        </el-form-item>
                        <el-form-item label="CIR儿童类产品最高历史使用量（%）">
                            <el-input v-model="submitData.cir_baby" placeholder="请输入CIR驻留类产品最高历史使用量（%）"></el-input>
                        </el-form-item>
                        <el-form-item label="CIR其它类产品最高历史使用量（%）">
                            <el-input v-model="submitData.cir_other" placeholder="请输入CIR驻留类产品最高历史使用量（%）"></el-input>
                        </el-form-item>
                        <el-form-item label="参考文献页码">
                            <el-input v-model="submitData.cir_pdf_pages" placeholder="请输入参考文献页码，多个用逗号分隔"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="权威参考文献编号">
                            <el-input placeholder="请选择权威参考文献编号" v-model="submitData.references_id" >
                                <el-button slot="append" icon="el-icon-zoom-in"  class="search-in-btn" @click="openReference">选择</el-button>
                            </el-input>
                        </el-form-item> -->
                        <el-form-item label="权威机构备注">
                            <el-input v-model="submitData.cir_remark" type="textarea" placeholder="请输入权威机构备注"></el-input>
                        </el-form-item>
                        <iecic-cir :iecicData="submitData" showEdit="true" v-on:view-pdf="viewPdf" :key="submitData.update_time + submitData.id"></iecic-cir>
                    </el-tab-pane>
                    <el-tab-pane label="风险物质" name="risk_data">
                        <!-- <el-form-item label="可能含有的风险物质">
                            <el-input v-model="submitData.risk_sustance" type="textarea" placeholder="请输入可能含有的风险物质"></el-input>
                        </el-form-item>
                        <el-form-item label="风险物质评估备注">
                            <el-input v-model="submitData.risk_remark" type="textarea" placeholder="请输入风险物质评估备注"></el-input>
                        </el-form-item> -->
                        <!-- <el-form-item label="风险物质">
                            <iecic-risk-detail :key="risk_fresh_key" :iecicData="submitData" v-on:edit-done="editRiskDone" v-on:change-data="reloadData"></iecic-risk-detail>
                        </el-form-item> -->
                        <iecic-risk :iecicData="submitData" showEdit="true" :key="submitData.update_time + submitData.id"></iecic-risk>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
        </div>
        <div class="empty-box"></div>   
        <div class="dialog-footer t-center">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" :disabled='btnStatus' @click="submitEdit">保 存</el-button>
        </div>
        <div class="empty-box"></div>
        <references-list ref="ReferenceList" :refereIds="submitData.references_id" v-on:select-done="referenceDone"></references-list>

        <!-- <iecic-edit-risk ref="EditRiskByIecic" v-on:edit-done="editRiskDone"></iecic-edit-risk> -->
    </el-drawer>
</template>
<script>
import {getIecicDetail, saveIecic} from '../../service/newApi';
import ReferencesList from '../references-list.vue';
import IecicRisk from '../risk/iecic-risk.vue';
import IecicCir from '../cir/iecic-cir.vue';
// import IecicEditRisk from './iecic-edit-risk.vue';
export default {
    components: {
        ReferencesList, 
        IecicRisk,
        IecicCir
    },
    data (){
        return {
            Title:`IECIC数据编辑/修改`,
            showViews: false,
            raw_id: 0,
            loading: false,
            showRefeList: false,
            referencesIds: '',
            formStep: `iecic_data`,
            submitData:{},
            dataChangeNum: 0,
            risk_fresh_key: 0,
            dataFresh: false
        }
    },
    watch: {
        submitData: {
            handler(){
                this.dataChangeNum += 1;
            },
            deep: true
        },
        dataChangeNum(val){
            if(val > 1){
                this.btnStatus = false;
            }else{
                this.btnStatus = true;
            }
        } 
    },
    methods: {
        close(){
            if(this.dataFresh){
                this.$emit('edit-done');
            }
            this.showViews = false;
        },
        handleClose(){
            // console.log(this.btnStatus,this.showViews);
            if(!this.btnStatus && this.showViews){
                this.$confirm(`修改内容尚未保存，是否要保存？`, '重要提示', {
                    confirmButtonText: `保存并关闭`,
                    cancelButtonText: `不保存`
                }).then(() => {
                        this.submitEdit('close');
                    }).catch(() => {
                        this.close();
                    });
            }else{
                this.close();
            }
        },
        submitEdit(isClose){
            this.loading = true;
            let editNow = this.submitData;

            // console.log(upObj);

            saveIecic(editNow).then(() => {
                this.$message({
                    message: '保存成功！',
                    type: 'success'
                });
                this.loading = false;
                this.dataFresh = true;
                this.btnStatus = true;
                if(isClose === 'close'){
                    this.close();
                }
            }, err => {
                this.$message.error(err.msg);
                this.loading = false;
            });

        },
        getRawInfo(id){
            // console.log(id);
            if(!id) return;
            getIecicDetail({
                id:id
            }).then(res => {
                    this.submitData = res.data;
                }, err => {
                    this.$message.error(err.msg);
                });
        },
        openReference(){
            // console.log(1)
            // this.showRefeList = true;
            this.referencesIds = this.submitData.references_id;
            // console.log(this.referencesIds);
            this.$refs.ReferenceList.openDrawer();
        }, 
        referenceDone(ids){
            console.log(ids);
            // this.submitData.references_id = ids;
        },
        open(data){
            if(data && data.id > 0){
                // this.submitData = data;
                this.getRawInfo(data.id);
            }else{
                this.submitData = {
                    id: 0,
                    cn_name: ``, //原料中文名
                    inci_name: ``, //INCI名，中文名
                    iecic_num: ``, //IECIC原料序号
                    former_cn_name: ``, //曾用中文名
                    former_inci_name: ``, //曾用英文名
                    iecic_rinse: ``, //IECIC淋洗用量
                    iecic_reside: ``, //IECIC驻留用量
                    cas: ``, //CAS编号
                    type: 0, //原料类型：0正常；1禁用；
                    csts_info: ``, //技术规范要求
                    remark: ``, //备注
                    cir_rinse: ``, //CIR淋洗
                    cir_reside: ``, //CIR驻留
                    cir_baby: ``, //CIR儿童
                    cir_other: ``, //CIR其它
                    cir_remark: ``, //CIR备注
                    aoac_cir: ``, //权威机构CIR评估结论
                    evaluate: ``, //评估结论
                }
            }
            this.showViews = true;
            this.dataFresh = false;
            this.dataChangeNum = 0;
            this.formStep = 'iecic_data';
        }
    },
    mounted: function(){
        // this.raw_id = this.$route.params.rawId;
        //调用子组件事件获取数据
        // this.getRawInfo(this.raw_id);
        // this.getRawInfo();
    }
}
</script>
<style>
.add-report{
    padding: 20px;
}
.steps-box{
    margin: 20px auto;
    /* max-width: 1000px; */
}
.my-form-box{
    max-width: 1000px;
}
.search-in-btn{
    width: 120px;
    background: #409EFF !important;
    border: #409EFF solid 0.5px !important;
    border-radius: 0 4px 4px 0;
    color: #fff !important;
}
</style>