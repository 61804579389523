<template>
    <el-dialog title="编辑欧美INCI数据" center :visible.sync="openDialg" width="65%">
        <el-form :model="referData">
            <el-form-item label="INCI名称" label-width="120px" prop="inci_name">
                <el-input v-model="referData.inci_name" type="textarea" :rows="3" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="中文标准名" label-width="120px" prop="cn_name">
                <el-input v-model="referData.cn_name" type="textarea" :rows="3" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="历史名称" label-width="120px" prop="old_name">
                <el-input v-model="referData.old_name" type="textarea" :rows="3" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="CAS编号" label-width="120px" prop="cas">
                <el-input v-model="referData.cas" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="原始序号" label-width="120px" prop="from_num">
                <el-input v-model="referData.from_num" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="国家/地区" label-width="120px" prop="country">
                <el-radio-group v-model="referData.country">
                    <el-radio :label="1">美国</el-radio>
                    <el-radio :label="2">欧盟</el-radio>
                    <el-radio :label="3">加拿大</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelEdit">取 消</el-button>
            <el-button type="primary" @click="saveData">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import {saveUAInci} from '../service/api';
export default {
    data(){
        return  {
            openDialg: false,
            referData: {
                id: 0,
                inci_name: '',
                cn_name: '',
                old_name: '',
                from_num: '',
                cas: '',
                country: 0
            }
        }
    },
    methods:{
        cancelEdit(){
            this.openDialg = false;
        },
        saveData(){
            
            saveUAInci({
                id: this.referData.id,
                inci_name: encodeURIComponent(this.referData.inci_name),
                cn_name: encodeURIComponent(this.referData.cn_name),
                old_name: encodeURIComponent(this.referData.old_name),
                cas: encodeURIComponent(this.referData.cas),
                from_num: this.referData.from_num,
                country: this.referData.country
            }).then(() => {
                // console.log(res);
                this.$message({
                    message: `保存成功`,
                    type: 'success'
                });
                this.$emit('add-suc');
                this.cancelEdit();
            }, err => {
                this.$message.error(err.msg);
            });
        },
        openAdd(){
            this.openDialg = true;
        },
        editData(data){
            if(data && data.id && data.id > 0){
                this.referData = data;
            }else{
                this.referData = {
                    id: 0,
                    inci_name: '',
                    cn_name: '',
                    old_name: '',
                    from_num: '',
                    cas: '',
                    country: 0
                }
            }
            this.openDialg = true;
        }
    },
    mounted: function(){
        // this.getList();
    }
}
</script>
<style>
</style>