<template>
    <el-container>
        <el-main>
            <h3>其它数据核对</h3>
            <!-- <h3 v-if="country == 1">美国（US）INCI</h3>
            <h3 v-if="country == 2">欧盟 (EU) INCI</h3>
            <h3 v-if="country == 3">欧盟 (CA)) INCI</h3> -->
            <el-form label-width="100px">
                <el-form-item label="数据源">
                    <el-radio-group v-model="type">
                        <el-radio label="1">使用方法（Function）</el-radio>
                        <!-- <el-radio label="3">加拿大（UA）</el-radio> -->
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="查询名称">
                    <el-input type="textarea" rows="4" v-model="keys" placeholder="请输入名称/关键字，每一行代表一个名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="doSearch('name')">名称核对</el-button>
                    <!-- <el-button type="danger" @click="doSearch('cas')">CAS编号查询</el-button> -->
                </el-form-item>
            </el-form>

            <el-table :data="dataList"
            stripe :row-class-name="tableRowClassName">
                <el-table-column prop="key" label="查询名称"></el-table-column>
                <el-table-column prop="name" label="匹配名称"></el-table-column>
                <el-table-column prop="description" label="CAS"></el-table-column>
                <el-table-column label="数据类型">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.type == 1" type="success">Function</el-tag>
                        <!-- <el-tag v-if="scope.row.type == 1" type="success">其它</el-tag> -->
                    </template>
                </el-table-column>
                <el-table-column label="结果" width="80">
                    <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.pair ? 'danger' : 'success'"
                    disable-transitions>{{scope.row.pair ? 'YES' : 'NO'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="编辑/修改" width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-edit" type="primary" plain round  @click="editRef(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <edit-comcheck ref="EditComcheck" v-on:add-suc="doneAdd"></edit-comcheck>
    </el-container>
</template>
<script>
import {ComCheckName} from "../service/api";
import EditComcheck from './edit-comcheck.vue';
export default {
    components: {EditComcheck},
    // props: ['country'],
    data(){
        return {
            dataList: [],
            keys: '',
            type: '1',
            searchBy: 'name'
        }
    }, 
    methods: {
        indexMethod(index) {
            return index + 1;
        },
        doSearch(tp){
            // console.log(1);
            if(!tp){
                tp = this.searchBy;
            }
            this.searchBy = tp;
            let searchNames = this.keys;
            if(searchNames){
                let searchArry;
                // console.log(searchNames.search(/\n/));
                if(searchNames.search(/\n/) < 0){
                    searchArry = [searchNames];
                }else{
                    searchArry = searchNames.split(/\n/);
                }

                let subArr = [];

                for(let i = 0; i < searchArry.length; i++){
                    let ite = searchArry[i];
                    if(ite && ite !== ''){
                        // ite = ite.replace(/(\s)*(）|\))(\s)*/g, '）');
                        // ite = ite.replace(/(\s)*(（|\()(\s)*/g, '（');
                        ite = ite.replace(/(^\s*)|(\s*$)/g, '');
                        // ite = ite.replace(/^\s*/g, '');
                        subArr.push(ite);
                    }
                }


                // console.log(subArr);
                ComCheckName({
                    search_type: tp,
                    type: this.type,
                    keys: encodeURIComponent(JSON.stringify(subArr))
                }).then(res => {
                    // let data = res.data;
                    this.dataList = res.data;
                    // console.log(res);
                }, err => {
                    this.$message.error(err.msg);
                });
            }else{
                this.$message.error(`请输入关键词！`);
            }
        },
        tableRowClassName({row}){
            // console.log(row);
            return row && row.pair ? '' : 'none-pair';

        },
        editRef(ite){
            // console.log(ite);
            this.$refs.EditComcheck.editData(ite);
            // this.$router.push(`/edit-limited/${ite.id}`);
        },
        doneAdd(){
            // this.searchBy = 'name';
            this.doSearch();
        },
        copyText(txt){
            // console.log(window);
            if(!txt){
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(txt)
                .then(() => {
                    this.$message({
                        message: `复制成功`,
                        type: 'success'
                    });
                }, err => {
                    this.$message.error(`复制失败，请手动复制 err=${err}`);
                });
        }
    }
}
</script>