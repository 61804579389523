<template>
    <el-drawer :title="Title" :with-header="false" :visible.sync="showViews" size="80%">
        <div class="my-draw-header">
            <div class="my-draw-title">{{Title}}</div>
            <div class="my-draw-edit-btns">
                <el-button type="primary" @click="editLimited" size="medium" round icon="el-icon-edit">编辑</el-button>
                <el-button type="warning" size="medium" @click="close" round icon="el-icon-close">关闭</el-button>
            </div>
        </div>
        <div class="view-draw-box">
            <el-form label-position="left" label-width="200px">
                <el-form-item label="化学名称: ">
                    <span>{{ LimitedInfo.chemical_name }}</span>
                    <el-button  class="copy-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(LimitedInfo.chemical_name)"></el-button>
                    <span>
                        <el-tag v-if="LimitedInfo.type == 0" type="success">普通原料</el-tag>
                        <el-tag v-if="LimitedInfo.type == 1" type="success">色素</el-tag>
                        <el-tag v-if="LimitedInfo.type == 2" type="success">防腐剂</el-tag>
                        <el-tag v-if="LimitedInfo.type == 3" type="success">防晒剂</el-tag>
                    </span>
                </el-form-item>
                <el-form-item label="通用名称: ">
                    <span>{{ LimitedInfo.common_name }}</span>
                    <el-button  class="copy-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(LimitedInfo.common_name)"></el-button>
                </el-form-item>
                <el-form-item label="CAS编号: ">
                    <span>{{ LimitedInfo.cas_num }}</span>
                    <el-button  class="copy-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(LimitedInfo.cas_num)"></el-button>
                </el-form-item>
                <el-form-item label="同义词: ">
                    <span>{{ LimitedInfo.synonym }}</span>
                    <el-button  class="copy-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(LimitedInfo.synonym)"></el-button>
                </el-form-item>
                <el-form-item label="使用类型: ">
                    <div class="html-box" v-html="LimitedInfo.type_of_use"></div>
                    <el-button  class="copy-btn fl-cp-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(LimitedInfo.type_of_use)"></el-button>
                </el-form-item>
                <el-form-item label="最大使用浓度: ">
                    <div class="html-box" v-html="LimitedInfo.max_concentration"></div>
                    <el-button  class="copy-btn fl-cp-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(LimitedInfo.max_concentration)"></el-button>
                </el-form-item>
                <el-form-item label="其他限制条件: ">
                    <div class="html-box" v-html="LimitedInfo.more_limitition"></div>
                    <el-button class="copy-btn fl-cp-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(LimitedInfo.more_limitition)"></el-button>
                </el-form-item>
                <el-form-item label="标签警示语: ">
                    <div class="html-box" v-html="LimitedInfo.warnings"></div>
                    <el-button class="copy-btn fl-cp-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(LimitedInfo.warnings)"></el-button>
                </el-form-item>
                <el-form-item label="颜色（色素专有）: ">
                    <span>{{ LimitedInfo.colour }}</span>
                    <el-button class="copy-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(LimitedInfo.colour)"></el-button>
                </el-form-item>
                <el-form-item label="色素编号（色素专有）: ">
                    <span>{{ LimitedInfo.ci_num }}</span>
                    <el-button class="copy-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(LimitedInfo.ci_num)"></el-button>
                </el-form-item>
            </el-form>
        </div>
        <edit-limited ref="EditLimiteDraw"></edit-limited>
    </el-drawer>
</template>
<script>
import EditLimited from './edit-limited.vue';
import { getLimitedById } from '../service/api';
export default {
    components: {EditLimited},
    data(){
        return {
            id: 0,
            Title: `禁用原料`,
            showViews: false,
            LimitedInfo: {}
        }
    },
    methods: {
        close(){
            this.showViews = false;
        },
        saveDone(){
            this.close();
        },
        getText(){
            if(this.id > 0){
                getLimitedById({
                    id: this.id
                }).then(res => {
                    console.log(res);
                    // this.reportText = res.data.text;
                    this.LimitedInfo = res.data;
                }, err => {
                    this.$message.error(err.msg);
                });
            }
        },
        copyText(txt){
            // console.log(window);
            if(!txt){
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(txt)
                .then(() => {
                    this.$message({
                        message: `复制成功`,
                        type: 'success'
                    });
                }, err => {
                    this.$message.error(`复制失败，请手动复制 err=${err}`);
                });
        },
        open(id){
            this.id = id;
            this.getText();
            this.showViews = true;
        },
        editLimited(){
            // this.$refs.EditLimiteDraw.open(this.id);
            this.$emit('edit-data', this.LimitedInfo);
        }
    },
    // mounted: function(){
    //     this.id = this.$route.params.id;
    //     // console.log(this.$route.params.id);
    //     this.getText();
    // }
}
</script>