import { get, post } from './http';


//登录
export const postUserLogin = (params) => post('login', params);

//获取原料列表
export const getRawList = (params) => get('getRawList', params);

//根据ID获取原料信息
export const getRawInfoById = (params) => get('getRawinfo', params);

//获取参考文献列表
export const getReferencedList = (params) => get('getReferences', params);

//保存参考文献
export const saveReference = (params) => post('addReference', params);

//删除参考文献
export const delReference = (params) => post('delReference', params);

//搜索参考文献
export const searchReference = (params) => post('searchReferences', params);

//根据ids获取参考文献列表
export const getReferencesByIds = (params) => get('getReferencesByIds', params);

//修改原料列表
export const updateIECIC = (params) => post('uplaadRawItem', params);

//获取化妆品分类列表
export const getProConfig = () => get('getProClass');

//创建报告并获取报告ID
export const createReport = (params) => post('createReport', params);

//上传配方表
// export const uploadFormula = (params) => post('/upload', params);
//获取配方表数据
export const getFormulaList = (params) => get('getFormulaList', params);

export const createReportText = (params) => post('creatReportText', params);

export const doneReport = (params) => post('saveReportText', params);

//获取报告列表
export const getReportList = (params) => get('getReportList', params);

//获取报告基本信息
export const getReportInfo = (params) => get('getReportInfo', params);

//获取报告TEXT信息
export const getReportText = (params) => get('getReportText', params);

//查看原料编号
export const postSearchIngredient = (params) => post('searchListBykey', params);

//批量查询
export const searchIECICList = (params) => post('searchListByNames', params);

//获取INCI列表
export const getUaInciList = (params) => get('getuainci', params);

//检车INCI名称正确性
export const checkInciNames = (params) => post('checkInciName', params);

//检查加拿大通用名称正确
export const checkCaGuide = (params) => post('checkCaGuideNames', params);

//保存INCI信息
export const saveUAInci = (params) => post('updateUAInci', params);

//根据CAS查询INCI信息
export const checkInciByCAS = (params) => post('checkInciByCAS', params);

//根据INCI查询数据
export const searchInciByKey = (params) => post('searchInciByKey', params);

//获取禁用原料列表
export const getProhibitedList = (params) => get('getprohibitedList', params);

//保存禁用原料数据
export const saveProhibited = (params) => post('saveProhibited', params);

//禁用原料搜索
export const searchProhibitedByKey = (params) => post('searchProhibitedByKey', params);

//检查是否存在禁用原料
export const checkProhibited = (params) => post('checkProhibited', params);

//检查是否存在禁用原料
export const checkCalifornia = (params) => post('checkCalifornia', params);

//获取限用原料列表
export const getLimitedList = (params) => get('getLimitedList', params);

//根据ID获取信息
export const getLimitedById = (params) => get('getLimitedById', params);

//检查限用原料列表
export const checkLimitedUse = (params) => post('checkLimitedUse', params);

//更新/添加限用原料
export const updateLimited = (params) => post('updateLimited', params);

//搜索限用原料
export const searchLimited = (params) => post('searchLimited', params);

//获取通用数据列表
export const getComCheckList = (params) => get('getComCheckList', params);

//检查名称
export const ComCheckName = (params) => post('ComCheckName', params);

//保存其它名称数据
export const saveComCheck = (params) => post('EditComCheck', params);

//根据关键词查询数据
export const searchComcheckByKey = (params) => post('searchComcheckByKey', params);

//欧美配方审核
export const postReviewFormula = (params) => post('postReviewFormula', params);

//获取Cosing数据
export const getCosingList = (params) => get('getCosingList', params);

//根据ID获取Cosing详细数据
export const getCosingById = (params) => get('getCosingById', params);

//根据关键词搜索Cosing数据
export const postSearchCosing = (params) => post('searchCosing', params);

//获取中国禁用原料列表
export const getCnLimitedList = (params) => get('getCnLimitedList', params);

//搜索中国禁用原料
export const searchCnLimited = (params) => post('searchCnLimited', params);

//获取用户列表
export const getUserList = (params) => get('admin_user_lisg', params);

//禁用用户
export const shutUserById = (params) => post('shut_user', params);

//添加用户
export const addAdminUser = (params) => post('add_admin_user', params);

//根据IECIC数据获取CIR报告列表
export const getCirReportList = (params) => get('cir_report_list', params);

//根据PDF ID获取pdf文件路径
export const getPdfUrlById = (params) => get('get_pdf_url', params);

//关联CIR报告到IECIC 
export const postUpdateCirId = (params) => post('update_cir_to_iecic', params);

//根据CIR ID获取PDF文件
export const getPdfUrlByCirId = (params) => get('get_pdf_url_cir', params);

//删除禁用原料数据
export const postDelProhibited = (params) => post('delProhibitedData', params);

//删除限用原料数据
export const postDelLimited = (params) => post('delLimitedData', params);

//获取Review Remark列表
export const getReviewRemarkList = (params) => get('getReviewRemark', params);

//添加&修改Review Remark
export const updateReviewRemark = (params) => post('updateReviewRemark', params);

//删除Review Remark数据
export const delReviewRemark = (params) => post('delReviewRemark', params);

//搜索Review Remark数据
export const searchReviewRemark = (params) => post('searchReviewRemark', params);

//获取欧美加报告列表
export const getEuaReportList = (params) => get('getEuaReportList', params);

//新建报告
export const createEuaReport = (params) => post('createEuaReport', params);

//上传配方表
export const updateEuaFormolar = (params) => post('updateEuaFormolar', params);

//风险物质管理
export const getRiskRemarkList = (params) => get('getRiskRemarkList', params);

//添加风险物质
export const saveRiskData = (params) => post('saveRiskData', params);

//搜索风险物质
export const searchRiskList = (params) => post('searchRiskList', params);

//删除风险物质
export const delRiskById = (params) => post('delRiskById', params);

//关联风险物质到IECIC
export const matchEvaluteByKeys = (params) => post('matchEvaluteByKeys', params);

//根据IDS获取风险物质
export const getRiskListByIds = (params) => get('getRiskListByIds', params);

//关联风险物质
export const matchToIecicById = (params) => post('matchEvaluteById', params);

//取消风险物质关联
export const cancelToIecicById = (params) => post('cancelEvaluteById', params);

//## 2023年04月17日
//化学品
export const getChemicalsList = (params) => get('ay2-get-chemicals-list', params);

//化学品详情
export const getChemicalsDetail = (params) => get('ay2-get-chemicals-detail', params);

//修改编辑化学品
export const editChemicalsApi = (params) => post('ay2-edit-chemicals', params);

//搜索化学品
export const searchChemicalsApi = (params) => post('ay2-search-chemicals', params);

//批量搜索化学名
export const searchChemicalsByNames = (params) => post('ay2-names-search-chemicals', params);

