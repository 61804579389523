<template>
    <div class="linked-box" :key="dataFresh">
        <div class="search-result" v-if="!noneData">
            <el-table ref="dataTable" size="mini" :data="dataList" stripe style="width: 100%">
                <el-table-column label="ID" width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.id}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="风险物质" class-name="duble-names">
                    <template slot-scope="scope">
                        <p>
                            <span>{{scope.row.name}}</span>
                            <el-button class="copy-btn" size="mini" icon="el-icon-document-copy" type="primary" plain circle  @click="copyText(scope.row.name)"></el-button>
                        </p>
                        <p class="en-name">
                            <span>{{scope.row.evaluate}}</span>
                            <el-button class="copy-btn" size="mini" icon="el-icon-document-copy" type="primary" plain circle  @click="copyText(scope.row.evaluate)"></el-button>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120" align="center" v-if="hide_linked">
                    <template slot-scope="props">
                        <el-button size="mini" type="danger" :disabled="!hide_linked" icon="el-icon-circle-close" plain round @click="unMatch(props.row)">取消关联</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="none-data" v-else>
            <h4>{{errMsg}}</h4>
            <h4 v-if="hide_linked">
                <el-link type="primary" @click="goMatch">立即添加</el-link>
            </h4>
        </div>
    </div>
</template>
<script>
import {getRiskListByIecic} from '../../service/newApi';
export default {
    props: ['iecic_id', 'hide_linked'],
    data(){
        return {
            page_no: 1,
            page_size: 20,
            noneData: true,
            dataList: [],
            errMsg: `未关联任何IECIC数据`,
            dataFresh: 0
        }
    },
    methods: {
        copyText(text){
            // this.$copyText(ite.)
            if(!text){
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(text)
                .then(() => {
                    this.$message({
                        message: `复制成功`,
                        type: 'success'
                    });
                }, err => {
                    this.$message.error(`复制失败，请手动复制 err=${err}`);
                });
        },
        getMatchList(){
            getRiskListByIecic({
                iecic_id: this.iecic_id
            }).then(res => {
                // console.log(res);
                if(res.data){
                    this.initData(res.data);
                }else{
                    this.dataList = [];
                    this.noneData = true;
                    this.errMsg = res.msg;
                    // this.$message.error(res.msg);
                }
                this.dataFresh = Date.now();
            }, err => {
                this.noneData = true;
                this.errMsg = err.msg;
                // this.$message.error(err.msg);
                this.dataFresh = Date.now();
            })
        },
        initData(data){
            this.noneData = false;

            this.dataList = data.list;

            this.$emit('reload-linkedids', data.risk_ids);

            this.dataList.forEach(row => {
                row.has_now = true;
            });
        },
        unMatch(ite){
            this.$emit('cancel-match', ite);
        },
        reloadData(page = 1){
           this.getMatchList(page); 
        },
        goMatch(){
            this.$emit('change-menu', 2);
        }
    },
    mounted: function(){
        this.reloadData();
    }
}
</script>