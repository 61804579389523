<template>
    <el-drawer :title="Title" :with-header="false" :visible.sync="showViews" :append-to-body="true" size="80%">
        <div class="my-draw-header">
            <div class="my-draw-title">{{Title}}</div>
            <div class="my-draw-edit-btns">
                <el-button type="primary" @click="onSubmit" size="medium" round icon="el-icon-thumb">保存</el-button>
                <el-button type="warning" @click="close" size="medium" round icon="el-icon-close">关闭</el-button>
            </div>
        </div>
        <div class="view-draw-box">
            <el-form rel="limitedForm" :model="remarkInfo" label-position="right" label-width="200px">
                <el-form-item label="INCI: ">
                    <el-input v-model="remarkInfo.inci" placeholder="请输入inci"></el-input>
                </el-form-item>
                <el-form-item label="审核意见 - EU: ">
                    <el-input type="textarea" :rows="2" 
                        v-model="remarkInfo.cn_eu" placeholder="请输入欧盟审核意见"></el-input>
                </el-form-item>
                <el-form-item label="审核意见 - US: ">
                    <el-input type="textarea" :rows="2" 
                        v-model="remarkInfo.cn_us" placeholder="请输入美国审核意见"></el-input>
                </el-form-item>
                <el-form-item label="审核意见 - CA: ">
                    <el-input type="textarea" :rows="2" 
                        v-model="remarkInfo.cn_ca" placeholder="请输入加拿大审核意见"></el-input>
                </el-form-item>
                <el-form-item label="Report Remarks - EU: ">
                    <el-input type="textarea" :rows="2" 
                        v-model="remarkInfo.eu_remark" placeholder="请输入欧盟Report Remarks"></el-input>
                </el-form-item>
                <el-form-item label="Report Remarks - EU(补充斜体部分): ">
                    <el-input type="textarea" :rows="3" 
                        v-model="remarkInfo.eu_more" placeholder="请输入欧盟Report Remarks补充内容"></el-input>
                </el-form-item>
                <el-form-item label="Report Remarks - US: ">
                    <el-input type="textarea" :rows="2" 
                        v-model="remarkInfo.us_remark" placeholder="请输入美国Report Remarks"></el-input>
                </el-form-item>
                <el-form-item label="Report Remarks - US(补充斜体部分): ">
                    <el-input type="textarea" :rows="3" 
                        v-model="remarkInfo.us_more" placeholder="请输入美国Report Remarks补充内容"></el-input>
                </el-form-item>
                <el-form-item label="Report Remarks - CA: ">
                    <el-input type="textarea" :rows="2" 
                        v-model="remarkInfo.ca_remark" placeholder="请输入加拿大Report Remarks"></el-input>
                </el-form-item>
                <el-form-item label="Report Remarks - CA(补充斜体部分): ">
                    <el-input type="textarea" :rows="3" 
                        v-model="remarkInfo.ca_more" placeholder="请输入加拿大Report Remarks补充内容"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </el-drawer>
</template>
<script>
import { updateReviewRemark } from '../service/api';
export default {
    data(){
        return {
            id: 0,
            Title: `限用原料编辑`,
            showViews: false,
            remarkInfo: {}
        }
    },
    methods: {
        close(){
            this.showViews = false;
        },
        saveDone(){
            this.$emit('edit-done');
            this.close();
        },
        onSubmit(){

            if(!this.remarkInfo.inci){
                this.$message.error(`请输入化INCI名称！`);
                return;
            }
            // console.log(upObj);
            updateReviewRemark(this.remarkInfo).then(res => {
                this.$message({
                    message: '保存成功！',
                    type: 'success'
                });
                this.saveDone(res.data);
            }, err => {
                this.$message.error(err.msg);
            });
        },
        open(id, data){
            this.id = id;
            this.showViews = true;
            if(this.id > 0){
                this.Title = `${data.inci} 审核汇总`
                this.remarkInfo = data;
            }else{
                this.Title = `添加审核汇总`;
                this.remarkInfo = {
                    inci: '',
                    cn_eu: '',
                    cn_us: '',
                    cn_ca: '',
                    eu_remark: '',
                    eu_more: '',
                    us_remark: '',
                    us_more: '',
                    ca_remark: '',
                    ca_more: '',
                    id: 0
                }
            }
        }
    }
}
</script>