<template>
    <el-drawer :title="Title" :with-header="false" :visible.sync="showViews" 
    :before-close="handleClose" :append-to-body="true" :close-on-press-escape="false" v-loading="loading" body size="85%">
        <div class="my-draw-header">
            <div class="my-draw-title">{{Title}}</div>
            <div class="my-draw-edit-btns">
                <el-button type="warning" @click="handleClose" size="medium" round icon="el-icon-close">关闭</el-button>
            </div>
        </div>
        <div class="view-draw-box">
            <cosing-detail :key="id" :cosingId="id" :title="Title"></cosing-detail>
        </div>
        <view-pdf ref="viewPDFCOM"></view-pdf>
    </el-drawer>
</template>
<script>
import CosingDetail from './cosing-detail.vue';
import viewPdf from '../view-pdf.vue';
export default {
    components: {
        viewPdf,
        CosingDetail
    },
    data(){
        return {
            id: 0,
            showViews: false,
            Title: 'Cosing详情'
        }
    },
    methods: {
        close(){
            this.showViews = false;
        },
        handleClose(){
            this.close();
        },
        open(id){
            this.id = id;
            this.showViews = true;
        },
        viewPdf(data){
            this.$refs.viewPDFCOM.showDrawer({
                title: data.title,
                url: data.url
            });
        }
    }
}
</script>
<style>
.fn-list p{
    margin: 0 auto;
}
</style>