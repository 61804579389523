import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App'
// import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/com.less'
// import './service/com.js'

import JSEncrypt from 'jsencrypt';

import axios from 'axios'
import VueAxios from 'vue-axios'

import router from './routes';

import VueClipboard from 'vue-clipboard2';

import ComFn from './service/com';

Vue.config.productionTip = false;

Vue.use(ElementUI);

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard);

Vue.use(VueAxios, axios); //引用顺序不能颠倒

//添加RSA加密
Vue.prototype.$getRsaCode = function (str) { // 注册方法
  let pubKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCwLLj22HDNyI8iqO4Wj3h+Q0C+
6OkLDY/++a9GbKOvYLQNt6d2twU9h5Z1AWfgXEI0yqMhf3Btzuv79aexGBkPr7EU
tplPfIdmfhFV9dN9IY7vxAcqv4C5PmEcvCNCSs4jbqu+pTobpdqxGRQ3Dj9uiIXC
8B6GT4+nIbZnv49hZwIDAQAB
-----END PUBLIC KEY-----`; // ES6 模板字符串 引用 rsa 公钥
  let encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(pubKey); // 设置 加密公钥
  let data = encryptStr.encrypt(str.toString()); // 进行加密
  return data;
}

Vue.prototype.$ComFn = ComFn;

new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: {
    App
  },
  render: h => h(App)
})

// app.use(axios, VueAxios);

// console.log(app);

// window.addEventListener('popstate', () => {
//   app.currentRoute = window.location.pathname
// })
