<template>
    <el-container>
        <el-header class="bg-gray no-padding">
            <el-menu
                :default-active="checkIndex"
                class="el-menu-demo bg-trans"
                mode="horizontal"
                @select="handleSelect">
                <el-menu-item index="1">使用方法</el-menu-item>
                <!-- <el-menu-item index="2">欧盟INCI数据</el-menu-item> -->
            </el-menu>
        </el-header>
        <el-main>
            <search-comcheck v-on:search-end="searchFreash" 
            v-on:re-search="getList"
            v-on:add-data="addProhibit"></search-comcheck>
            <el-table :data="inciList" stripe>
                <el-table-column prop="id" label="ID" width="100"></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="description" label="说明"></el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
                <el-table-column label="编辑/修改" width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-edit" type="primary" plain round  @click="editRef(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="empty-box"></div> 
            <el-pagination background class="t-center"
                @current-change="pageChange"
                :page-size="page_size"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
            <div class="empty-box"></div>
        </el-main>
        <edit-comcheck ref="EditComcheck" v-on:add-suc="doneAdd"></edit-comcheck>
    </el-container>
</template>

<script>
import EditComcheck from '../components/edit-comcheck.vue';
import SearchComcheck from '../components/search-comcheck.vue';
// import UsInciList from '../components/us-inci-list.vue';
import {getComCheckList} from "../service/api";
export default {
    components: { SearchComcheck, EditComcheck},
    data (){
        return {
            type: 1,
            checkIndex: '1',
            page_size: 20,
            page_no: 1,
            total: 0,
            inciList: [],
        }
    },
    methods:{
        handleSelect(key, index){
            this.checkIndex = index;
            this.type = key;
            this.getList();
        },
        getList(type){
            // if()
            // console.log(country);
            let typ = type ? type : 1;
            getComCheckList({
                type: typ,
                page_no: this.page_no,
                page_size: this.page_size
            }).then(res => {
                let data = res.data;
                if(data.total && data.total > 0){
                    this.total = data.total;
                    this.page_no = data.page_no;
                    this.inciList = data.list;
                    // console.log(222);
                }
                // console.log(res);
            }, err => {
                this.$message.error(err.msg);
            });
        },
        pageChange(page){
            if(page != this.page_no) {
                this.page_no = page;
                this.getList();
            }
        },
        editRef(ite){
            // console.log(ite);
            this.$refs.EditComcheck.editData(ite);
        },
        addProhibit(){
            this.$refs.EditComcheck.editData();
        },
        doneAdd(){
            // this.getInciList();
        },
        searchFreash(data){
            this.inciList = data.list;
            this.page_no  = data.page_no;
            this.total = data.total;
        }
    },
    mounted: function(){
        this.getList();
    }
}
</script>
<style>
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 150px;
    font-size: 12px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>