<template>
    <el-container>
        <el-header class="bg-gray no-padding">
            <div class="top-btns">
                <b class="top-tit">用户管理</b>
                <el-button type="primary"  icon="el-icon-plus" class="out-btn fr" round @click="addNewUser">添加用户</el-button>
            </div>
        </el-header>
        <el-main>
            <el-table :data="userList" stripe>
                <el-table-column prop="id" label="ID" width="100"></el-table-column>
                <el-table-column prop="user_name" label="用户名"></el-table-column>
                <el-table-column prop="nike_name" label="昵称"></el-table-column>
                <el-table-column prop="create_time" :formatter="getDateStr" label="创建时间"></el-table-column>
                <el-table-column prop="create_time" :formatter="getDateStr" label="最后登录时间"></el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status === 0">正常</el-tag>
                        <el-tag type="danger" v-if="scope.row.status === 1">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-edit-outline" type="primary" v-if="scope.row.status === 0" plain round  @click="editUser(scope.row, 1)">编辑</el-button>
                        <el-button size="mini" icon="el-icon-delete" type="danger" v-if="scope.row.status === 0" plain round  @click="shutUser(scope.row, 1)">禁用</el-button>
                        <el-button size="mini" icon="el-icon-circle-check" type="primary" v-if="scope.row.status === 1" plain round  @click="shutUser(scope.row, 0)">恢复</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="empty-box"></div> 
            <el-pagination background class="t-center"
                @current-change="pageChange"
                :page-size="page_size"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
            <div class="empty-box"></div>
            <add-adminuser ref="addAdminUser" :refereId="editId" v-on:add-suc="doneAdd"></add-adminuser>
        </el-main>
    </el-container>
</template>
<script>
import AddAdminuser from '../components/add-adminuser.vue';
import {getUserList, shutUserById} from '../service/api'
export default {
    components: {AddAdminuser},
    data(){
        return {userList: [],
            page_no: 1,
            page_size: 20,
            total: 0
        }
    },
    methods: {
        getList(pageNo){

            let page_no = pageNo ? pageNo : this.page_no;

            getUserList({
                page_no: page_no,
                page_size: this.page_size
            }).then(res => {
                let data = res.data;

                this.total = data.total;

                this.userList = data.list;

                this.page_no = data.page_no;

            }, err => {
                this.$message.error(err.msg);
            });

        },
        pageChange(val){
            if(val != this.page_no){
                this.page_no = val;
                this.getList;
            }
        },
        getDateStr(row, column, cellVal,){
            // console.log(row, column, cellVal, index);

            if(cellVal && parseInt(cellVal) > 0){
                let dateTimes = parseInt(cellVal) * 1000;
                let date = this.$ComFn.getTimeStr(dateTimes);

                return date.date_str;
            }else{
                return '格式错误';
            }
        },
        shutUser(row, tp){
            if(row.id > 0){
                let confirmMsg = `确定要禁用账号：${row.user_name}，禁用后该用户将无法登录！`

                if(tp === 0){
                    confirmMsg = `确定要恢复账号：${row.user_name}吗？`
                }

                this.$confirm(confirmMsg, `重要提示`, {
                    confirmButtonText: `确定`,
                    cancelButtonText: `取消`,
                    type: `warning`
                }).then(()=>{
                    shutUserById({
                        type: tp,
                        id: row.id
                    }).then(res => {
                        row.status = tp;
                        this.$message({
                            type: 'success',
                            message: res.msg
                        });
                    }, err => {
                        this.$message.error(err.msg);
                    })
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '用户取消操作'
                    })
                })
            }else{
                this.$message.error('无效用户');
            }
        },
        doneAdd(){
            this.getList();
        },
        addNewUser(){
            // this.openAdd = true;
            // this.$refs.addAdminUser.openAdd();
            this.$refs.addAdminUser.editData();
        },
        editUser(row){
            this.$refs.addAdminUser.editData(row);
        }
    },
    mounted(){
        this.getList();
    }
}
</script>