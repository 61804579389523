<template>
    <el-drawer :title="Title" :with-header="false" :visible.sync="showViews" 
    :before-close="handleClose" :append-to-body="true" :close-on-press-escape="false" v-loading="loading" body size="85%">
        <div class="my-draw-header">
            <div class="my-draw-title">{{Title}}</div>
            <div class="my-draw-edit-btns">
                <el-button type="warning" @click="handleClose" size="medium" round icon="el-icon-close">关闭</el-button>
            </div>
        </div>
        <div class="view-draw-box">
            <el-form :model="formData" class="edit-formu-box" label-width="200px">
                <el-form-item label="中文名称">
                    <el-input v-model="formData.cn_name" type="textarea" placeholder="请输入中文名称"></el-input>
                </el-form-item>
                <el-form-item label="中文别名">
                    <el-input v-model="formData.cn_another_name" type="textarea" placeholder="请输入中文别名"></el-input>
                </el-form-item>
                <el-form-item label="英文名称">
                    <el-input v-model="formData.en_name" type="textarea" placeholder="请输入英文名称"></el-input>
                </el-form-item>
                <el-form-item label="英文别名">
                    <el-input v-model="formData.en_another_name" type="textarea" placeholder="请输入英文别名"></el-input>
                </el-form-item>
                <el-form-item label="化学式/分子式">
                    <el-input v-model="formData.chemical_formula" type="textarea" placeholder="请输入化学式/分子式"></el-input>
                </el-form-item>
                <el-form-item label="CAS号或序号">
                    <el-input v-model="formData.cas" placeholder="请输入CAS号或序号"></el-input>
                </el-form-item>
                <el-form-item label="环境使用类别">
                    <el-input v-model="formData.cn_scope" placeholder="请输入环境使用类别"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="formData.remark" type="textarea" placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="empty-box"></div>   
        <div class="dialog-footer t-center">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="submitEdit">保 存</el-button>
        </div>
    </el-drawer>
</template>
<script>
import {getChemicalsDetail, editChemicalsApi} from '../../service/api';
export default {
    data(){
        return {
            Title: '编辑修改',
            showViews: false,
            formData: {},
            loading: false,
            freshStatus: false
        }
    },
    methods: {
        handleClose(){
            if(this.freshStatus){
                this.$emit('fresh-page');
            }
            this.showViews = false;
        },
        open(obj){
            if(obj && obj.id > 0){
                this.Title = '编辑化学品';
                this.getDetail(obj.id);
            }else{
                this.Title = '添加化学品';
                this.formData = {
                    id: 0, //ID
                    cn_name: ``, //中文名称
                    cn_another_name: ``, //中文别名
                    en_name: ``, //英文名称
                    en_another_name: ``, //英文别名
                    chemical_formula: ``, //化学式、分子式
                    cas: ``, //CAS编号或序号
                    cn_scope: ``, //环境使用类别
                    remark: ``, //备注
                    from_number: 0, //原始序号
                    ch_class: 0, //数据类别，默认0；1=无别名化学品
                }
            }
            this.freshStatus = false;
            this.showViews = true;
        },
        getDetail(id){
            // console.log(id);
            this.loading = true;
            getChemicalsDetail({
                id: id
            }).then(res => {
                this.formData = res.data;
                this.loading = false;
            }, err => {
                this.$message.error(err.msg);
                this.loading = false;
            });
        },
        submitEdit(){
            let obj = this.formData;

            if(!obj.cn_name){
                this.$message.error('中文名称不能为空！');
                return;
            }

            if(!obj.en_name){
                this.$message.error('英文名称不能为空！');
                return;
            }
            this.loading = true;
            editChemicalsApi(obj).then(res => {
                this.formData = res.data;
                this.$message.success(res.msg);
                this.loading = false;
                this.freshStatus = true;
            }, err => {
                this.$message.error(err.msg);
                this.loading = false;
            })

        }
    }
}
</script>