<template>
    <el-container>
        <el-header class="add-report-header">
            <el-page-header @back="goBack" content="修改原料"></el-page-header>
        </el-header>
        <el-main>
            <!-- <edit-rawformula v-bind:rawId="raw_id" ref="RawEdit"></edit-rawformula> -->
            <el-dialog
            width="30%"
            title="确认保存修改"
            :visible.sync="innerVisible"
            append-to-body>
                <!-- <span>{{}}</span> -->
        
                <div slot="footer" class="dialog-footer t-center">
                    <el-button @click="innerVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confirmUpdate" :loading="loading">{{ loading ? '提交中 ...': '确 定' }}</el-button>
                </div>
            </el-dialog>
            <el-form :model="submitData" class="edit-formu-box" label-width="250px">
                <div class="top-none-edit">
                    <el-form-item label="中文名称">
                        <span>{{submitData.cn_name}}</span>
                    </el-form-item>
                    <el-form-item label="INCI名称/英文名称">
                        <span>{{submitData.inci_name}}</span>
                    </el-form-item>
                    <el-form-item label="中文曾用名">
                        <span>{{submitData.former_cn_name ? submitData.former_cn_name : '/'}}</span>
                    </el-form-item>
                    <el-form-item label="英文曾用名">
                        <span>{{submitData.former_inci_name ? submitData.former_inci_name : '/'}}</span>
                    </el-form-item>
                    <el-form-item label="淋洗类最高用量">
                        <span>{{submitData.rinse_top_num ? submitData.rinse_top_num + '%' : '/'}}</span>
                    </el-form-item>
                    <el-form-item label="驻留类类最高用量">
                        <span>{{submitData.reside_top_num ? submitData.reside_top_num + '%' : '/'}}</span>
                    </el-form-item>
                </div>
                <el-form-item label="备注">
                    <el-input v-model="submitData.remark" type="textarea" placeholder="请输入备注"></el-input>
                </el-form-item>
                <el-form-item label="《化妆品安全技术规范》要求">
                    <el-input v-model="submitData.csts_info" type="textarea" placeholder="请输入《化妆品安全技术规范》要求"></el-input>
                </el-form-item>
                <el-form-item label="权威机构CIR评估结论">
                    <el-input v-model="submitData.aoac_cir" type="textarea" placeholder="请输入权威机构CIR评估结论"></el-input>
                </el-form-item>
                <el-form-item label="CIR淋洗类产品最高历史使用量（%）">
                    <el-input v-model="submitData.cir_rinse_top" placeholder="请输入CIR淋洗类产品最高历史使用量（%）"></el-input>
                </el-form-item>
                <el-form-item label="CIR驻留类产品最高历史使用量（%）">
                    <el-input v-model="submitData.cir_reside_top" placeholder="请输入CIR驻留类产品最高历史使用量（%）"></el-input>
                </el-form-item>
                <el-form-item label="权威参考文献编号">
                    <!-- <el-input v-model="submitData.references_id" placeholder="请输入权威参考文献编号"></el-input> -->
                    <el-input placeholder="请选择权威参考文献编号" v-model="submitData.references_id" class="input-with-select">
                        <el-button slot="append" icon="el-icon-zoom-in"  class="search-in-btn" @click="openReference">选择</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="权威机构备注">
                    <el-input v-model="submitData.cir_remark" type="textarea" placeholder="请输入权威机构备注"></el-input>
                </el-form-item>
                <el-form-item label="评估结论">
                    <el-input v-model="submitData.evaluate" type="textarea" placeholder="请输入评估结论"></el-input>
                </el-form-item>
                <el-form-item label="可能含有的风险物质">
                    <el-input v-model="submitData.risk_sustance" type="textarea" placeholder="请输入可能含有的风险物质"></el-input>
                </el-form-item>
                <el-form-item label="风险物质评估备注">
                    <el-input v-model="submitData.risk_remark" type="textarea" placeholder="请输入风险物质评估备注"></el-input>
                </el-form-item>
            </el-form>
            <div class="empty-box"></div>   
            <div class="dialog-footer t-center">
                <el-button @click="goBack">取 消</el-button>
                <el-button type="primary" @click="submitEdit">确 定</el-button>
            </div>
            <div class="empty-box"></div>
        </el-main>
        <references-list ref="ReferenceList" :refereIds="submitData.references_id" v-on:select-done="referenceDone"></references-list>
    </el-container>
</template>
<script>
import {updateIECIC, getRawInfoById} from '../service/api';
import ReferencesList from '../components/references-list.vue';
export default {
    components: {ReferencesList},
    data (){
        return {
            raw_id: 0,
            innerVisible: false,
            loading: false,
            showRefeList: false,
            referencesIds: '',
            submitData: {
                    id: 0,
                    iecic_num: '',
                    cn_name: '',
                    inci_name: '',
                    former_cn_name: '',
                    former_inci_name: '',
                    rinse_top_num: '',
                    reside_top_num: '',
                    remark: '',
                    csts_info: '',
                    aoac_cir: '',
                    cir_rinse_top: '',
                    cir_reside_top: '',
                    references_id: '',
                    cir_remark: '',
                    evaluate: '',
                    risk_sustance: '',
                    risk_remark: '',
                }
        }
    },
    methods: {
        goBack(){
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/iecic-list');
        },
        submitEdit(){
            // console.log(this.submitData);
            this.innerVisible = true;
            this.loading = false;

        },
        confirmUpdate(){ //确认修改
            this.loading = true;
            let editNow = this.submitData;
            let upObj = {
                id: editNow.id, 
                remark: editNow.remark, 
                csts_info: editNow.csts_info, 
                aoac_cir: editNow.aoac_cir, 
                cir_rinse_top: editNow.cir_rinse_top, 
                cir_reside_top: editNow.cir_reside_top, 
                references_id: editNow.references_id, 
                cir_remark: editNow.cir_remark ? editNow.cir_remark : '', 
                evaluate: editNow.evaluate, 
                risk_sustance: editNow.risk_sustance, 
                risk_remark: editNow.risk_remark
            };

            // console.log(upObj);

            updateIECIC(upObj).then(() => {
                // console.log(res);

                // this.cancelEdit();
                this.$message({
                    message: '保存成功！',
                    type: 'success'
                });
                this.loading = false;
                this.innerVisible = false;
            }, err => {
                console.log(err);
            });
        },
        getRawInfo(id){
            // console.log(id);
            getRawInfoById(id)
                .then(res => {
                    this.submitData = res.data;
                    // console.log(this.rawData);
                }, err => {
                    this.$message.error(err.msg);
                });
        },
        openReference(){
            // console.log(1)
            // this.showRefeList = true;
            this.referencesIds = this.submitData.references_id;
            console.log(this.referencesIds);
            this.$refs.ReferenceList.openDrawer();
        }, 
        referenceDone(ids){
            this.submitData.references_id = ids;
        }
    },
    mounted: function(){
        this.raw_id = this.$route.params.rawId;
        //调用子组件事件获取数据
        this.getRawInfo(this.raw_id);
        // this.getRawInfo();
    }
}
</script>
<style>
.add-report{
    padding: 20px;
}
.steps-box{
    margin: 20px auto;
    /* max-width: 1000px; */
}
.my-form-box{
    max-width: 1000px;
}
.search-in-btn{
    width: 120px;
    background: #409EFF !important;
    border: #409EFF solid 0.5px !important;
    border-radius: 0 4px 4px 0;
    color: #fff !important;
}
</style>