<template>
    <el-drawer :title="Title" :with-header="false" :visible.sync="showViews" :append-to-body="true" size="80%">
        <div class="my-draw-header">
            <div class="my-draw-title">{{Title}}</div>
            <div class="my-draw-edit-btns">
                <el-button type="warning" @click="saveDone" size="medium" round icon="el-icon-close">关闭</el-button>
            </div>
        </div>
        <div class="view-draw-box">
            <el-steps :active="subSteps" simple>
                <el-step title="基本信息" icon="el-icon-edit"></el-step>
                <el-step title="配方表" icon="el-icon-files"></el-step>
                <el-step title="下载报告" icon="el-icon-download"></el-step>
            </el-steps>
            <div class="empty-box"></div>
            <div class="step-boxs" v-show="subSteps === 1">
                <el-form rel="limitedForm" :model="reportInfo" label-position="right" label-width="200px">
                    <el-form-item label="Report No.: ">
                        <el-input v-model="reportInfo.report_no" placeholder="请输入报告编号"></el-input>
                    </el-form-item>
                    <el-form-item label="Sample Name: ">
                        <el-input v-model="reportInfo.report_title" placeholder="请输入产品名称"></el-input>
                    </el-form-item>
                    <el-form-item label="Sample Name（中文）: ">
                        <el-input v-model="reportInfo.report_title_cn" placeholder="请输入产品名称（中文）"></el-input>
                    </el-form-item>
                    <el-form-item label="Product Barcode: ">
                        <el-input v-model="reportInfo.barcode" placeholder="请输入Product Barcode"></el-input>
                    </el-form-item>
                    <el-form-item label="Type of Product: ">
                        <el-input v-model="reportInfo.product_type" placeholder="请输入Type of Product"></el-input>
                    </el-form-item>
                    <el-form-item label="Labeled Age Grading: ">
                        <el-input v-model="reportInfo.labeld_age" placeholder="请输入Labeled Age Grading"></el-input>
                    </el-form-item>
                    <el-form-item label="Manufacturer: ">
                        <el-input v-model="reportInfo.manufacturer" placeholder="请输入Manufacturer"></el-input>
                    </el-form-item>
                    <el-form-item label="Country of Origin: ">
                        <el-input v-model="reportInfo.origin_country" placeholder="请输入Country of Origin"></el-input>
                    </el-form-item>
                    <el-form-item label="Sample Received Date: ">
                        <el-input v-model="reportInfo.received_date" placeholder="请输入Sample Received Date"></el-input>
                    </el-form-item>
                    <el-form-item label="Sample Tested Date: ">
                        <el-input v-model="reportInfo.tested_date" placeholder="请输入Sample Tested Date"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="step-boxs" v-show="subSteps === 2">
                <div class="input-tb">
                    <div class="textar-box">
                        <h3>Ingredient INCI Name</h3>
                        <el-input v-model="formulaData.inci_names" :rows="20" type="textarea" placeholder="请输入INCI Name，每行一个"></el-input>
                    </div>
                    <div class="textar-box">
                        <h3>CAS No.</h3>
                        <el-input v-model="formulaData.cas_nums" :rows="20" type="textarea" placeholder="请输入CAS No.，每行一个"></el-input>
                    </div>
                    <div class="textar-box">
                        <h3>Contents%(w/w)</h3>
                        <el-input v-model="formulaData.contents" :rows="20" type="textarea" placeholder="请输入Contents(%(w/w))，每行一个"></el-input>
                    </div>
                    <div class="textar-box">
                        <h3>Function</h3>
                        <el-input v-model="formulaData.functions" :rows="20" type="textarea" placeholder="请输入Function，每行一个"></el-input>
                    </div>
                </div>
            </div>
            <div class="step-boxs" v-show="subSteps === 3">
                <div class="empty-box"></div>
                <div class="sucend-report">
                    <div class="suc-icons">
                        <i class="el-icon-success"></i>
                    </div>
                    <h3>报告创建成功</h3>
                    <p>点击按钮下载Word</p>
                    <el-button type="primary" icon="el-icon-download" @click="downLoadReport">下载报告</el-button>
                </div>
            </div>
            <div class="empty-box"></div>
            <div class="empty-box border-top"></div>
            <div class="dialog-footer t-center">
                <el-button @click="Previous" v-if="subSteps > 1">上一步</el-button>
                <el-button type="primary" @click="saveData">{{btnTitle}}</el-button>
            </div>
        </div>
    </el-drawer>
</template>
<script>
import { createEuaReport, updateEuaFormolar } from '../service/api';
export default {
    data(){
        return {
            id: 0,
            Title: `限用原料编辑`,
            showViews: false,
            reportInfo: {},
            subSteps: 1,
            formulaData: {
                inci_names: '',
                cas_nums: '',
                contents: '',
                functions: ''
            },
            btnTitle: '保 存'
        }
    },
    methods: {
        close(){
            this.showViews = false;
        },
        saveDone(){
            this.$emit('edit-done');
            this.close();
        },
        Previous(){
            if(this.subSteps > 1){
                this.subSteps -= 1;
            }
        },
        downLoadReport(){
            this.$emit('download-report', this.id);
        },
        exFormual(){

            let obj = this.formulaData;

            let errMsgs = {
                inci_names: {
                    err: `请输入Ingredient INCI Name`, 
                    name: 'Ingredient INCI Name'
                },
                cas_nums: {
                    err: '请输入CAS No.',
                    name: 'CAS No.'
                },
                contents: {
                    err: '请输入Contents%(w/w)',
                    name: 'Contents'
                },
                functions: {
                    err: '请输入Function',
                    name: 'Function'
                }
            }

            let msg = '';
            let canSub = true;

            let subData = {};

            for(let v in obj){
                if(!obj[v]){
                    msg = errMsgs[v].err;
                    canSub = false;
                    break;
                }else{
                    let item = obj[v];
                    // console.log(item);

                    //去除最后一个换行
                    item = item.replace(/\n$/, '');

                    if(item.search(/\n/) < 0){
                        subData[v] = [item];
                    }else{
                        subData[v] = item.split(/\n/);
                    }
                }
            }

            //判断输入的数据是否匹配
            // console.log(subData);
            if(canSub){
                let inciTotal = 0;
                for(let v in subData){
                    let item = subData[v];
                    if(v === 'inci_names'){
                        inciTotal = item.length;
                    }else if(v){
                        let le = item.length;
                        // console.log(v);
                        if(le !== inciTotal){
                            msg = errMsgs[v].name + '数量与Ingredient数量不一致！';
                            canSub = false;
                            break;
                        }
                    }

                    //去除输入内容的前后多余空格
                    for(let i = 0; i < item.length; i++){
                        let ite = item[i];

                        if(ite && ite !== ''){
                            ite = ite.replace(/(^\s*)|(\s*$)/g, '');
                        }
                    }

                }
            }

            if(!canSub){
                this.$message.error(`${msg}`);
                return;
            }

            //将数据进行序列化
            let subArr = [];
            for(let j = 0; j < subData.inci_names.length; j++){
                subArr.push({
                    index: j+1,
                    inci_name: subData['inci_names'][j],
                    cas_no: subData['cas_nums'][j],
                    have: subData['contents'][j],
                    function: subData['functions'][j]
                });
            }

            //打印最终提交数据
            // console.log(subData);

            updateEuaFormolar({
                formula_list: encodeURIComponent(JSON.stringify(subArr)),
                id: this.id
            }).then(res => {
                
                this.$message({
                    message: res.msg,
                    type: "success"
                });
                this.subSteps += 1;
                this.btnTitle = '完 成';
            }, err => {
                this.$message.error(err.msg);
            });
        },
        saveData(){
            let step = this.subSteps;

            if(step === 1){

                let obj = this.reportInfo;
                let canSub = true;
                let msg = '';

                let errMsgs = {
                    report_no: `Report No.`,
                    report_title: `Sample Name`,
                    report_title_cn: `Sample Name（中文）`,
                    barcode: `Product Barcode`,
                    product_type: `Type of Product`,
                    labeld_age: `Labeled Age Grading`,
                    manufacturer: `Manufacturer`,
                    origin_country: `Country of Origin`,
                    received_date: `Sample Received Date`,
                    tested_date: `Sample Tested Date`
                };

                for(let v in obj){
                    if(v !== 'formula_list' && v !== 'id' && !obj[v]){
                        // console.log(v);
                        canSub = false;
                        msg = errMsgs[v];
                        break;
                    }
                }

                if(!canSub){
                    this.$message.error(`请输入${msg}`);
                    return;
                }

                createEuaReport(obj).then(res => {
                    // let data = res.data;
                    this.$message({
                        message: `保存成功`,
                        type: "success"
                    });
                    this.id = res.data;
                    this.subSteps += 1;

                }, err => {
                    this.$message.error(err.msg);
                });
                
            }
            if(step === 2){
                
                this.exFormual();
            }

            if(step === 3){
                this.saveDone();
            }

        },
        open(id, data){
            this.id = id;
            this.btnTitle = '保 存';
            if(this.id > 0){
                this.Title = `${data.report_no} 编辑修改`
                this.reportInfo = {
                    report_no: data.report_no,
                    report_title: data.report_title,
                    report_title_cn: data.report_title_cn,
                    barcode: data.barcode,
                    product_type: data.product_type,
                    labeld_age: data.labeld_age,
                    manufacturer: data.manufacturer,
                    origin_country: data.origin_country,
                    received_date: data.received_date,
                    tested_date: data.tested_date,
                    formula_list: data.formula_list,
                    id: id
                };
                // console.log(1);
                // console.log(data);
                //序列化原料列表
                if(data.formula_list){
                    let formulaData = JSON.parse(data.formula_list);
                    console.log(data.formula_list, formulaData);
                    if(formulaData && formulaData.length > 0){
                        // let formulaData: {
                        //     inci_names: '',
                        //     cas_nums: '',
                        //     contents: '',
                        //     functions: ''
                        // }
                        let inci_names = '';
                        let cas_nums = '';
                        let contents = '';
                        let functions = '';
                        for(let i = 0; i < formulaData.length; i++){
                            let ite = formulaData[i];
                            inci_names += ite.inci_name + '\n';
                            cas_nums += ite.cas_no + '\n';
                            contents += ite.have + '\n';
                            functions += ite.function + '\n';
                        }

                        this.formulaData.inci_names = inci_names;
                        this.formulaData.cas_nums = cas_nums;
                        this.formulaData.contents = contents;
                        this.formulaData.functions = functions;

                        // console.log(this.formulaData);
                    }
                }


            }else{
                this.Title = `新建报告`;
                this.subSteps = 1;
                this.reportInfo = {
                    report_no: '',
                    report_title: '',
                    report_title_cn: '',
                    barcode: '',
                    product_type: '',
                    labeld_age: '',
                    manufacturer: '',
                    origin_country: 'China',
                    received_date: '',
                    tested_date: '',
                    id: 0
                }
            }

            this.showViews = true;
        }
    }
}
</script>
<style lang="less">
.step-boxs{
    margin: 0 10px;
}
.input-tb{
    display: flex;
    .textar-box{
        &:first-child{
            flex: 1.5 !important;
            border-right: none;
        }
        flex: 1;
        background: #fafafa;
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
        h3{
            padding: 0 15px;
        }
    }
}
.sucend-report{
    margin: 20px auto;
    text-align: center;
    .suc-icons i{
        font-size: 60px;
        line-height: 60px;
        color: rgba(76, 209, 55,1.0);
        margin: 10px auto;
    }
    h3{
        font-size: 18px;
        line-height: 36px;
        margin: 10px auto;
    }
    p{
        margin: 0 auto 20px;
        font-size: 14px;
        color: #999;
    }
}
</style>