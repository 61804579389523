<template>
    <div class="linked-box" :key="dataFresh">
        <div class="search-result" v-if="!noneData">
            <el-table ref="dataTable" size="mini" :data="dataList" stripe style="width: 100%">
                <el-table-column label="ID" width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.id}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="标题 & 参考文献 " class-name="duble-names">
                    <template slot-scope="scope">
                        <p>
                            <span>{{scope.row.title}}</span>
                            <el-button class="copy-btn" size="mini" icon="el-icon-document-copy" type="primary" plain circle  @click="copyText(scope.row.title)"></el-button>
                        </p>
                        <p class="en-name">
                            <span>{{scope.row.position}}</span>
                            <el-button class="copy-btn" size="mini" icon="el-icon-document-copy" type="primary" plain circle  @click="copyText(scope.row.position)"></el-button>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="附件" width="150" align="center">
                    <template slot-scope="props">
                        <el-tag v-if="!props.row.pdf_id" class="cir-list-btns" size="mini" type="info">无附件</el-tag>
                        <el-button v-else size="mini" class="cir-list-btns" type="primary" icon="el-icon-connection" plain round @click="viewPdf(props.row)">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="URL" width="150" align="center">
                    <template slot-scope="props">
                        <el-tag v-if="!props.row.url" size="mini" type="info">无外链</el-tag>
                        <el-button v-else size="mini" type="success" icon="el-icon-view" plain round @click="viewUrl(props.row)">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120" align="center" v-if="hide_linked">
                    <template slot-scope="props">
                        <el-button size="mini" type="danger" :disabled="!hide_linked" icon="el-icon-circle-close" plain round @click="unMatch(props.row)">取消关联</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <div class="empty-box"></div>
            <el-pagination background class="t-center" @current-change="pageChange"
                :page-size="page_size" :current-page="page_no" layout="prev, pager, next" :total="total">
            </el-pagination> -->
        </div>
        <div class="none-data" v-else>
            <h4>{{errMsg}}</h4>
            <h4 v-if="hide_linked">
                <el-link type="primary" @click="goMatch">立即添加</el-link>
            </h4>
        </div>
    </div>
</template>
<script>
import {getCirListByIecic} from '../../service/newApi';
export default {
    props: ['iecic_id', 'hide_linked'],
    data(){
        return {
            page_no: 1,
            page_size: 20,
            noneData: true,
            dataList: [],
            errMsg: `未关联任何CIR数据`,
            dataFresh: 0
        }
    },
    methods: {
        copyText(text){
            // this.$copyText(ite.)
            if(!text){
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(text)
                .then(() => {
                    this.$message({
                        message: `复制成功`,
                        type: 'success'
                    });
                }, err => {
                    this.$message.error(`复制失败，请手动复制 err=${err}`);
                });
        },
        getMatchList(){
            getCirListByIecic({
                iecic_id: this.iecic_id,
            }).then(res => {
                // console.log(res);
                if(res.data){
                    this.initData(res.data);
                }else{
                    this.dataList = [];
                    this.noneData = true;
                    this.errMsg = `未关联任何CIR数据`;
                    this.$message.error(res.msg);
                }
                this.dataFresh = Date.now();
            }, err => {
                this.noneData = true;
                this.errMsg = err.msg;
                // this.$message.error(err.msg);
                this.dataFresh = Date.now();
            })
        },
        initData(data){
            this.noneData = false;

            this.dataList = data.list;

            this.$emit('reload-linkedids', data.cir_ids);

            this.dataList.forEach(row => {
                row.has_now = true;
            });
        },
        pageChange(val){
            if(val === this.page_no) return;
            this.reloadData(val);
        },
        unMatch(ite){
            this.$emit('cancel-match', ite);
        },
        reloadData(page = 1){
           this.getMatchList(page); 
        },
        goMatch(){
            this.$emit('change-menu', 2);
        },
        viewPdf(ite){
            console.log(ite);
            this.$emit('view-pdf', ite.pdf_id);
            // if(ite.pdf_id > 0){
            //     getPdfUrlById({
            //         pdf_id: ite.pdf_id
            //     }).then(res => {
            //         let obj = res.data;

            //         let url = `/cirreport/${obj.url}`;
            //         this.$refs.viewPDFCOM.showDrawer({
            //             title: obj.title,
            //             url: url
            //         });
            //     }, err => {
            //         this.$message.error(err.msg);
            //     });
            // }else{
            //     this.$message.error(`没有可用的附件`);
            // }
        },
        viewUrl(ite){
            window.open(ite.url, '_blank');
        }
    },
    mounted: function(){
        this.reloadData();
    }
}
</script>