<template>
    <el-container>
        <el-header class="bg-black">
            <my-header title="化妆品安全评估数据系统"></my-header>
        </el-header>
        <el-container>
            <!-- <el-aside width="160px" class="bg-gray border-right">
                <el-menu :default-active="routerNow" router class="bd-none bg-none">
                    <el-menu-item v-for="item in routList" :key="item.path" :index="item.path">
                        <i :class="item.icon"></i>
                        <span v-text="item.name"></span>
                    </el-menu-item>
                </el-menu>   
            </el-aside> -->
            <el-aside width="200px" class="bg-gray border-right">
                <el-menu :default-active="routerNow" :unique-opened="true" router class="bd-none bg-none">
                    <el-menu-item index="/index">
                        <i class="el-icon-s-home"></i>
                        <span slot="title">首页</span>
                    </el-menu-item>
                    <el-submenu v-for="item in routerGroup" :key="item.index" :index="item.index" class="sub-menu">
                        <template slot="title">
                            <i :class="item.icon"></i>
                            <span>{{item.name}}</span>
                        </template>
                        <el-menu-item v-for="page in item.list" :key="page.index" :index="page.path">
                            <i :class="page.icon"></i>
                            <span v-text="page.name"></span>
                        </el-menu-item>
                    </el-submenu>
                </el-menu>   
            </el-aside>
            <el-main class="my-main-box">
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import routerConfig from '../router-config';
    import myHeader from '../components/my-header';

    export default {
        data(){
            return {
                // routList: routerConfig.pageList,
                routerGroup: routerConfig.pageGroup,
                routerNow: '/index'
            }
        },
        components: {
            myHeader
        },
        created(){
            let routerObj = this.$route;//注意不是router
            this.routerNow = routerObj.path;
            // console.log(routerObj);
            // console.log(this)
        },
        beforeRouteUpdate(to, from, next){
            // console.log(to, from);
            this.routerNow = to.path;
            next();
        }
    }
</script>

<style>
</style>