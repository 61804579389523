<template>
    <div class="ref-draw-list">
        <div class="refer-list">
            <div class="top-search">
                <el-input placeholder="请输入风险物质名称" v-model="searchKey" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search"  class="search-in-btn" @click="searchRef">查询</el-button>
                </el-input>
                <el-button type="primary" class="out-btn" plain @click="reSearch">全部</el-button>
                <el-button type="primary" class="out-btn" icon="el-icon-plus" @click="addNewData">添加</el-button>
            </div>
            <div class="empty-box"></div>
            <el-table :data="dataList" :key="tableChange" stripe>
                <el-table-column prop="id" label="ID" width="100"></el-table-column>
                <el-table-column prop="name" label="名称" width="200"></el-table-column>
                <el-table-column prop="evaluate" label="评估备注"></el-table-column>
                <el-table-column label="风险等级" width="100">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.level" type="warning">{{scope.row.level}}</el-tag>
                        <el-tag v-if="!scope.row.level" type="info">未知</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="查看" width="150">
                    <template slot-scope="scope">
                        <el-button class="vtable-btn"  v-if="!showMatchBtn" size="mini" type="primary" plain round  @click="matchIecicById(scope.row)">
                            <span v-show="scope.row.match_iecic">取消关联</span>
                            <span v-show="!scope.row.match_iecic">关联</span>
                        </el-button>
                        <el-button class="vtable-btn" size="mini" icon="el-icon-edit" type="warning" plain round  @click="editRef(scope.row)">修改</el-button>
                        <el-button class="vtable-btn" size="mini" icon="el-icon-delete" type="danger" plain round  @click="delData(scope.row)">删除</el-button>
                        <el-button class="vtable-btn" v-if="showMatchBtn" size="mini" 
                            icon="el-icon-guide" type="success" v-loading.fullscreen.lock='matchLoading' plain round  @click="matchIECIC(scope.row)">关键词匹配</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="empty-box"></div>
            <el-pagination background class="t-center"
                @current-change="pageChange"
                :page-size="page_size"
                :current-page="page_no"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        <add-riskremark ref="addDataWindow" v-on:add-suc="doneAdd"></add-riskremark>
    </div>
</template>
<script>
import {getRiskRemarkList, searchRiskList, delRiskById, matchEvaluteByKeys, matchToIecicById, cancelToIecicById} from '../service/api';
import AddRiskremark from './add-riskremark.vue';
export default {
    components: {AddRiskremark},
    props: ['iecicData'],
    data(){
        return  {
            editId: 0,
            dataList: [],
            page_no: 1,
            page_size: 20,
            total: 0,
            searchKey: '',
            matchLoading: false,
            showMatchBtn: true,
            matchIecicRiskIds: [],
            tableChange: 0
        }
    },
    methods:{
        initData(data){
            this.total = data.total;
            this.dataList = data.list;
            this.page_no = data.page_no;
            this.matchInIecicIds();
            // this.tableChange += 1;
        },
        getList(page_no, page_size){
            if(!page_no){
                page_no = this.page_no;
            }
            if(!page_size){
                page_size = this.page_size;
            }

            getRiskRemarkList({
                page_no: page_no, 
                page_size: page_size
            }).then(res => {
                    // let data = res.data;
                    this.initData(res.data);
                }, err => {
                    this.$message.error(err.msg);
                });

        },
        reloadData(page){
            if(this.searchKey){
                this.getListBykey(this.searchKey, page);
            }else{
                this.getList(page);
            }
        },
        pageChange(val){
            if(val == this.page_now) return;
            else{
                this.reloadData(val);
            }
        },
        getListBykey(key, page_no){
            searchRiskList({
                key_word: encodeURIComponent(key),
                page_no: page_no ? page_no : 1,
                page_size: this.page_size
            }).then(res => {
                this.initData(res.data);
            }, err => {
                this.$message.error(err.msg);
            });
        },
        searchRef(){
            if(this.searchKey){
                this.page_no = 1;
                this.getListBykey(this.searchKey);
            }else{
                this.$message.error('请输入关键词！');
            }
        },
        reSearch(){
            this.page_no = 1;
            this.searchKey = '';
            this.getList();
        },
        addNewData(){
            // this.openAdd = true;
            this.$refs.addDataWindow.open();
        },
        doneAdd(){
            // this.getList();
            this.reloadData(this.page_no);
        },
        editRef(ite){
            // console.log(ite);
            this.$refs.addDataWindow.open(ite);
        },
        delData(ite){
            // console.log(ite);
            this.$confirm(`确定要删除【${ite.title}】的内容？`, '提示', {
                confirmButtonText: `确定`,
                cancelButtonText: `取消`,
            }).then(() => {
                delRiskById({
                    id: ite.id
                }).then(() => {
                    this.$message({
                        message: `数据删除成功`,
                        type: 'success'
                    });
                    this.doneAdd();
                }, err => {
                    this.$message.error(err.msg);
                });
            }).catch(() => {

            })
        },
        matchIECIC(ite){
            if(ite && ite.search_keys){
                this.$confirm(`关联到IECI数据，通过设定的关键字进行查询匹配，需要较长时间，请谨慎操作`, '提示', {
                    confirmButtonText: `确定匹配`,
                    cancelButtonText: `取消`,
                }).then(() => {
                    this.matchLoading = true;
                    matchEvaluteByKeys({
                        keys: encodeURIComponent(ite.search_keys),
                        risk_id: ite.id
                    }).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.matchLoading = false;
                    }, err => {
                        this.$message.error(err.msg);
                        this.matchLoading = false;
                    });
                }).catch(() => {
                    this.$message({
                        message: `用户取消`,
                        type: `info`
                    });
                })
            }else{
                this.$message.error(`请先设定匹配关键词`);
            }
            
        },
        copyText(ite){
            // this.$copyText(ite.)
            if(!ite){
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(ite.title + ' ' + ite.position)
                .then(() => {
                    this.$message({
                        message: `复制成功`,
                        type: 'success'
                    });
                }, err => {
                    this.$message.error(`复制失败，请手动复制 err=${err}`);
                });
        },
        matchInIecicIds(){
            let iecic = this.matchIecicRiskIds;

            if(!iecic) return;

            let dataList = this.dataList;
            dataList.forEach((v) => {
                let riskid = v.id + '';
                if(iecic.indexOf(riskid) > -1){
                    // console.log(v);
                    v.match_iecic = true;
                }else{
                    v.match_iecic = false;
                }
            });

            this.dataList = dataList;
            // console.log(this.dataList);
            this.tableChange += 1;
            // this.$forceUpdate();
            // this.$nextTick(function() {
            //     console.log('data update !!!!');
            // });

        },
        matchIecicById(ite){
            // console.log(ite);
            if(ite.match_iecic){
                if(ite.id && this.iecicData){
                    let iecic = this.iecicData;
                    this.$confirm(`确定取消风险物质【${ite.name}】与原料【${iecic.cn_name}】的关联吗？`, '提示', {
                        confirmButtonText: `确定`,
                        cancelButtonText: `取消`,
                    }).then(() => {
                        this.matchLoading = true;
                        cancelToIecicById({
                            id: iecic.id,
                            risk_id: ite.id
                        }).then(res => {
                            this.$message({
                                message: res.msg,
                                type: 'success',
                            });
                            this.matchLoading = false;
                            this.changeMatchRiskIds(ite.id, 2);
                            ite.match_iecic = false;
                        }, err => {
                            this.$message.error(err.msg);
                            this.matchLoading = false;
                        });
                    }).catch(() => {
                        this.$message({
                            message: `用户取消`,
                            type: `info`
                        });
                    });
                }else{
                    this.$message.error(`操作无效！`);
                }
            }else{
                if(ite.id && this.iecicData){
                    let iecic = this.iecicData;
                    this.$confirm(`确定将风险物质【${ite.name}】添加到原料【${iecic.cn_name}】吗？`, '提示', {
                        confirmButtonText: `确定关联`,
                        cancelButtonText: `取消`,
                    }).then(() => {
                        this.matchLoading = true;
                        matchToIecicById({
                            id: iecic.id,
                            risk_id: ite.id
                        }).then(res => {
                            this.$message({
                                message: res.msg,
                                type: 'success',
                            });
                            this.matchLoading = false;
                            this.changeMatchRiskIds(ite.id, 1);
                            ite.match_iecic = true;
                        }, err => {
                            this.$message.error(err.msg);
                            this.matchLoading = false;
                        });
                    }).catch(() => {
                        this.$message({
                            message: `用户取消`,
                            type: `info`
                        });
                    });
                }else{
                    this.$message.error(`操作无效！`);
                }
            }
            
        },
        changeMatchRiskIds(id, tp){
            let riskIds = this.matchIecicRiskIds;
            let idstr = id.toString();
            if(tp === 1){
                if(riskIds.indexOf(idstr) > -1 ){
                    return;
                }

                riskIds.push(idstr);
            }

            if(tp === 2){
                if(riskIds.indexOf(idstr) < 0 ){
                    return;
                }
                riskIds.splice(riskIds.indexOf(idstr), 1);
            }
            this.matchIecicRiskIds = riskIds;
            

            // console.log(riskIds);
            let ids_str = '';
            if(riskIds.length > 0){
                ids_str = riskIds.join(',');
            }
            this.iecicData.risk_id = ids_str;
            //更新数据
            this.matchInIecicIds();

            this.$emit('match-done', ids_str);
        }
    },
    mounted: function(){
        // console.log(this.iecicData);
        if(this.iecicData){
            this.showMatchBtn = false;
            if(this.iecicData.risk_ids){
                this.matchIecicRiskIds = this.iecicData.risk_ids.split(',');
                // console.log(this.matchIecicRiskIds);
            }
        }else{
            this.showMatchBtn = true;
        }
        this.getList();
    }
}
</script>
<style>
.top-ref-btns{
    margin: 10px auto;
    padding: 0 15px;
}
.top-ref-btns .el-input{
    float: left;
    width: 70%;
}
.top-ref-btns .add-ref-btn{
    float: right;
}
.select-list{
    margin: 5px auto;
    padding: 0 20px;
    line-height: 20px;
    font-weight: 400;
}

</style>