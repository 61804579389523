<template>
    <el-drawer :title="Title" :visible.sync="showViews" :append-to-body="true" size="80%">
        <iframe :src="PDFUrl" height="100%" seamless="seamless" width="100%"></iframe>
    </el-drawer>
</template>
<script>
export default {
    data(){
        return {
            Title: `PDF阅读器`,
            showViews: false,
            PDFUrl: ``
        }
    },
    methods: {
        showDrawer(ite){
            if(ite){
                this.Title = ite.title;
                this.PDFUrl = 'https://cdn.cosassess.com/public' + ite.url;
                this.showViews = true;
            }else{
                this.$message.error('链接无效！');
                return;
            }
        },
        hideDrawer(){
            this.showViews = false;
        }
    }
}
</script>