import {
    get,
    post
} from './http';

//获取IECIC列表
export const getIecicList = (params) => get('ay2-get-iecic-list', params);

//获取IECIC详情
export const getIecicDetail = (params) => get('ay2-get-iecic', params);

//根据关键词搜索
export const searchIecic = (params) => post('ay2-search-iecic', params);

//根据关键词搜索
export const searchIecicByNames = (params) => post('ay2-search-iecic-by-names', params);

//保存IECIC数据
export const saveIecic = (params) => post('ay2-save-iecic', params);

//根据IECIC ID获取关联数据
export const getLinkedIdsByIecic = (params) => get('ay2-get-linkedids-by-iecic', params);

//根据IECIC ID 获取CIR报告
export const getCirListByIecic = (params) => get('ay2-get-cirlist-by-iecic', params);

//根据IECIC ID 获取RISK列表
export const getRiskListByIecic = (params) => get('ay2-get-risklist-by-iecic', params);

//IECIC关联数据
export const doLinkedIecic = (params) => post('ay2-add-iecic-linked', params);

//取消数据关联
export const cancelLinkedIecic = (params) => post('ay2-del-iecic-linked', params);
