module.exports = {
    pageGroup: [{
        name: '中国',
        index: 'cn',
        icon: 'el-icon-menu meicon',
        list: [{
            index: 'cn-iecic',
            path: '/iecic-list',
            name: 'IECIC数据',
            icon: 'el-icon-document',
            show_type: 100,
        }, {
            index: 'cn-references',
            path: '/references',
            name: '参考文献',
            icon: 'el-icon-folder',
            show_type: 2,
        }, {
            index: 'cn-report',
            path: '/report-list',
            name: '报告',
            icon: 'el-icon-document',
            show_type: 2,
        }, {
            path: '/search',
            name: '编号查询',
            icon: 'el-icon-search',
            show_type: 100,
        }, {
            index: 'cn-check-label',
            path: '/check-label',
            name: '标签审核',
            icon: 'el-icon-s-order',
            show_type: 2,
        }, {
            path: '/cnprohibited-list',
            name: '禁用原料',
            icon: 'el-icon-document-delete',
            show_type: 2,
        }, {
            path: '/risk-evaluate-list',
            name: '风险物质',
            icon: 'el-icon-s-release',
            show_type: 2,
        }, {
            path: '/chemicals-list',
            name: '化学品',
            icon: 'el-icon-coin',
            show_type: 2,
        }]
    },{
        name: '欧美加',
        index: 'eu',
        icon: 'el-icon-menu meicon',
        list: [{
            index: 'eu-uainci',
            path: '/uainci',
            name: '欧美INCI核对',
            icon: 'el-icon-receiving',
            show_type: 2,
        }, {
            index: 'eu-uainci-list',
            path: '/uainci-list',
            name: '欧美INCI数据',
            icon: 'el-icon-coin',
            show_type: 2,
        }, {
            index: 'eu-uaprohibited',
            path: '/uaprohibited-list',
            name: '禁用原料',
            icon: 'el-icon-document-delete',
            show_type: 2,
        }, {
            index: 'eu-limited',
            path: '/limited-list',
            name: '限用原料',
            icon: 'el-icon-folder-checked',
            show_type: 2,
        }, {
            index: 'eu-com-check',
            path: '/com-check-list',
            name: '使用方法',
            icon: 'el-icon-dessert',
            show_type: 2,
        }, {
            index: 'eu-review-formula',
            path: '/review-formula',
            name: '配方审核',
            icon: 'el-icon-s-claim',
            show_type: 2,
        }, {
            index: 'eu-cosing-list',
            path: '/cosing-list',
            name: 'Cosing数据',
            icon: 'el-icon-s-opportunity',
            show_type: 2,
        }, {
            index: 'eu-review-remark',
            path: '/review-remark',
            name: '审核汇总',
            icon: 'el-icon-notebook-2',
            show_type: 2,
        }, {
            index: 'eu-eua-report',
            path: '/eua-report',
            name: '配方审核报告',
            icon: 'el-icon-reading',
            show_type: 2,
        }]
    },{
        name: '管理中心',
        index: 'ad',
        icon: 'el-icon-s-tools meicon',
        list: [{
            index: 'ad-admin',
            path: '/admin-users',
            name: '用户管理',
            icon: 'el-icon-user',
            show_type: 2,
        }]
    }],
    // pageList: [{
    //             path: '/index',
    //             name: '首页',
    //             icon: 'el-icon-menu',
    //             show_type: 100,
    //         },
    //         {
    //             path: '/iecic-list',
    //             name: '原料列表',
    //             icon: 'el-icon-document',
    //             show_type: 100,
    //         }, {
    //             path: '/references',
    //             name: '参考文献',
    //             icon: 'el-icon-folder',
    //             show_type: 2,
    //         }, {
    //             path: '/report-list',
    //             name: '报告',
    //             icon: 'el-icon-document',
    //             show_type: 2,
    //         }, {
    //             path: '/search',
    //             name: '编号查询',
    //             icon: 'el-icon-search',
    //             show_type: 100,
    //         }, {
    //             path: '/uainci',
    //             name: '欧美INCI核对',
    //             icon: 'el-icon-receiving',
    //             show_type: 2,
    //         }, {
    //             path: '/uainci-list',
    //             name: '欧美INCI数据',
    //             icon: 'el-icon-coin',
    //             show_type: 2,
    //         }, {
    //             path: '/uaprohibited-list',
    //             name: '禁用原料',
    //             icon: 'el-icon-document-delete',
    //             show_type: 2,
    //         }, {
    //             path: '/limited-list',
    //             name: '限用原料',
    //             icon: 'el-icon-folder-checked',
    //             show_type: 2,
    //         }, {
    //             path: '/com-check-list',
    //             name: '其它数据',
    //             icon: 'el-icon-dessert',
    //             show_type: 2,
    //         }, {
    //             path: '/check-label',
    //             name: '标签审核',
    //             icon: 'el-icon-s-order',
    //             show_type: 2,
    //         }, {
    //             path: '/review-formula',
    //             name: '配方审核',
    //             icon: 'el-icon-s-claim',
    //             show_type: 2,
    //         }, {
    //             path: '/cosing-list',
    //             name: 'Cosing数据',
    //             icon: 'el-icon-s-opportunity',
    //             show_type: 2,
    //         }, {
    //             path: '/admin-users',
    //             name: '用户管理',
    //             icon: 'el-icon-user',
    //             show_type: 2,
    //         }
    //     ]
        
}