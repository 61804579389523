<template>
    <div class="linked-box">
        <div class="top-search">
            <el-input @keydown.enter.native="doSearch" placeholder="请输入风险物质名称" v-model="searchKey">
                <el-button slot="append" icon="el-icon-search"  class="search-in-btn" @click="doSearch">查询</el-button>
            </el-input>
            <div class="empty-box"></div>
        </div>
        <div class="search-result" v-if="!noneData">
            <el-table ref="dataTable" size="mini" :key="dataFresh" :data="dataList" :row-class-name="getMatchNowClass" stripe style="width: 100%">
                <el-table-column label="ID" width="100">
                    <template slot-scope="scope">
                        <i class="ay-see-icon el-icon-success"></i>
                        <span>{{scope.row.id}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="风险物质" class-name="duble-names">
                    <template slot-scope="scope">
                        <p>{{scope.row.name}}</p>
                        <p class="en-name">{{scope.row.evaluate}}</p>
                    </template>
                </el-table-column>
                <el-table-column label="风险等级" width="80">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.level" type="warning">{{scope.row.level}}</el-tag>
                        <el-tag v-if="!scope.row.level" type="info">未知</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120" align="center">
                    <template slot-scope="props">
                        <el-button v-if="props.row.has_now" size="mini" type="danger" icon="el-icon-circle-close" plain round @click="unMatch(props.row)">取消关联</el-button>
                        <el-button v-else size="mini" type="primary" icon="el-icon-connection" plain round @click="addMatch(props.row)">关联</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="empty-box"></div>
            <el-pagination background class="t-center" @current-change="pageChange"
                :page-size="page_size" :current-page="page_no" layout="prev, pager, next" :total="total">
            </el-pagination>
        </div>
        <div class="none-data" v-else>
            <h4>{{errMsg}}</h4>
        </div>
    </div>
</template>
<script>
import {getRiskRemarkList, searchRiskByKey} from '../../service/api';
export default {
    props: ['linked_ids'],
    data(){
        return {
            page_no: 1,
            page_size: 20,
            noneData: true,
            dataList: [],
            errMsg: `未查询到任何数据`,
            searchType: 1,
            searchKey: '',
            dataFresh: 0,
        }
    },
    methods: {
        getMatchNowClass(ite){
            let row = ite.row;
            if(row.has_now) return 'ay-select-now'
        },
        checkMatchRows(row){
            let matchIds = this.linked_ids;
            if(matchIds && matchIds.indexOf(row.id) > -1){
                return true;
            }else{
                return false;
            }

        },
        initData(data){
            this.page_no = data.page_no;
            this.page_size = data.page_size;
            this.total = data.total;

            this.dataList = data.list;
            this.dataList.forEach(row => {
                row.has_now = this.checkMatchRows(row);
            });
            this.noneData = false;
            this.dataFresh = Date.now();
        },
        getList(page = 1){
            let pageNo = page ? page : 1;
            getRiskRemarkList({
                page_no: pageNo,
                page_size: this.page_size
            }).then(res => {
                this.initData(res.data);
            }, err => {
                this.loading = false;
                this.$message.error(err.msg);
            });
        },
        searchByKey(page){
            let pageNo = page ? page : 1;
            searchRiskByKey({
                key_word: this.searchKey,
                page_no: pageNo,
                page_size: this.page_size
            }).then(res => {
                if(res.data){
                    this.initData(res.data);
                }else{
                    this.noneData = true;
                    this.errMsg = res.msg;
                    this.$message.error(res.msg);
                    this.loading = false;
                }
            }, err => {
                this.loading = false;
                this.$message.error(err.msg);
            })
        },
        doSearch(){
            if(!this.searchKey){
                this.getList(1);
                return;
            }
            this.loading = true;
            this.searchByKey(1);
        },
        reloadData(page){
            
            let PageNo = page ? page : this.page_no;

            if(this.searchKey){
                this.searchByKey(PageNo);
            }else{
                this.getList(PageNo);
            }
        },
        pageChange(val){
            if(val === this.page_no) return;
            this.reloadData(val);
        },
        addMatch(ite){
            this.$emit('add-match', ite);
        },
        unMatch(ite){
            this.$emit('cancel-match', ite);
        }
    },
    mounted: function(){
        this.reloadData();
    }
}
</script>