<template>
    <div class="cosing-detail">
        <div class="cosing-des" v-if="cosingId > 0">
            <!-- <h3 class="ay-h-titile" v-if="showCosingTitle"><span class="tit-span">{{ CosingInfo.inci_name }}</span></h3> -->
            <el-descriptions :column="2" 
            label-class-name="ay-des-lable"
            content-class-name="ay-des-content" size="medium" border>
                <el-descriptions-item label="INCI名称: ">
                    {{ CosingInfo.inci_name }}
                    <el-button  class="copy-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(CosingInfo.inci_name)"></el-button>
                </el-descriptions-item>
                <el-descriptions-item label="CAS编号">
                    {{ CosingInfo.cas_num }}
                    <el-button  class="copy-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(CosingInfo.cas_num)"></el-button>
                </el-descriptions-item>
                <el-descriptions-item label="EC编号">
                    {{ CosingInfo.ec_num }}
                </el-descriptions-item>
                <el-descriptions-item label="原料图片">
                    <span v-if="CosingInfo.img_file"><img :src="'https://cdn.cosassess.com/public/chimericalimgs/' + CosingInfo.img_file" /></span>
                </el-descriptions-item>
                <el-descriptions-item label="Description">
                    <div class="html-box" v-html="CosingInfo.description"></div>
                </el-descriptions-item>
                <el-descriptions-item label="Functions">
                    <p v-for="ite in CosingInfo.functions" :key="ite">{{ite.name}}</p>
                </el-descriptions-item>
                <el-descriptions-item label="Cosmetics Regulation provisions">{{CosingInfo.cosmetics_regulation}}</el-descriptions-item>
                <el-descriptions-item label="SCCS opinions">
                    <div class="fn-list">
                        <p v-for="ite in CosingInfo.sccs_infos" :key="ite">
                            <span>{{ite.title}}</span>
                            <el-button @click="oepnPdf(ite)" class="ma-left" plain round icon="el-icon-view" type="primary">查看报告</el-button>
                        </p>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="原始数据">
                    <el-button type="primary" class="out-btn" icon="el-icon-s-promotion" plain size="mini" round @click="viewCosing(CosingInfo.cosing_id)">查看Cosing数据</el-button>
                </el-descriptions-item>
            </el-descriptions>
        </div>
        <h3 v-else class="ay-no-info">未查询到相关Cosing 数据</h3>
    </div>
</template>
<script>
import {getCosingById} from '../../service/api';
export default {
    props: ['cosingId'],
    data(){
        return {
            CosingInfo: {}
        }
    },
    methods: {
        getData(){
            // console.log(this.cosingId);
            if(this.cosingId > 0){
                getCosingById({
                    id: this.cosingId
                }).then(res => {
                    // console.log(res);
                    // this.reportText = res.data.text;
                    this.CosingInfo = res.data;
                    // this.title = this.CosingInfo.inci_name;
                    this.loading = false;
                }, err => {
                    this.loading = false;
                    this.$message.error(err.msg);
                });
            }
        },
        copyText(txt){
            // console.log(window);
            if(!txt){
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(txt)
                .then(() => {
                    this.$message({
                        message: `复制成功`,
                        type: 'success'
                    });
                }, err => {
                    this.$message.error(`复制失败，请手动复制 err=${err}`);
                });
        },
        viewCosing(id){
            let url = `https://ec.europa.eu/growth/tools-databases/cosing/index.cfm?fuseaction=search.details_v2&id=${id}`;

            this.$confirm('即将离开系统，前往第三方网站，请确认！', '跳转提示', {
                confirmButtonText: '继续访问',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                window.open(url, '_blank');
            }).catch(() =>{

            });
        },
        oepnPdf(ite){
            if(ite && ite.file_name && ite.file_name.indexOf('.pdf') > -1){
                let url = `/cosing/${ite.file_name}`;

                this.$emit('view-pdf', {
                    title: ite.title,
                    url: url
                });

                // window.open(url, '_blank');
            }else{
                this.$message.error('文件无效，无法访问，请联系管理员！');
                return;
            }
        },
    },
    mounted: function(){
        this.getData();
    }
}
</script>