<template>
    <el-container>
        <el-header class="bg-gray no-padding">
            <el-menu
                :default-active="checkIndex"
                class="el-menu-demo bg-trans"
                mode="horizontal"
                @select="handleSelect">
                <el-menu-item index="2">欧盟限用原料</el-menu-item>
                <el-menu-item index="3">加拿大限用原料</el-menu-item>
            </el-menu>
        </el-header>
        <el-main>
            <search-limited ref="TopLimitedSearch" :country="country" v-on:search-end="searchFreash" 
            v-on:add-data="addNewData"
            v-on:re-search="getInciList"></search-limited>
            <el-table :data="proList" stripe>
                <el-table-column prop="id" label="ID" width="100"></el-table-column>
                <el-table-column prop="chemical_name" label="原料名称"></el-table-column>
                <el-table-column prop="cas_num" label="CAS编号"></el-table-column>
                <el-table-column label="原料类型">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.type == 0" type="success">普通原料</el-tag>
                        <el-tag v-if="scope.row.type == 1" type="success">色素</el-tag>
                        <el-tag v-if="scope.row.type == 2" type="success">防腐剂</el-tag>
                        <el-tag v-if="scope.row.type == 3" type="success">防晒剂</el-tag>
                        <!-- <el-tag type="info">标签三</el-tag>
                        <el-tag type="warning">标签四</el-tag>
                        <el-tag type="danger">标签五</el-tag> -->
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="ci_num" label="颜色编号"></el-table-column>
                <el-table-column prop="colour" label="颜色"></el-table-column> -->
                <el-table-column prop="from_num" label="原始序号" width="100"></el-table-column>
                <el-table-column label="查看" width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-view" type="primary" plain round  @click="viewRef(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="编辑" width="200">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-edit" type="warning" plain round  @click="editRef(scope.row)">修改</el-button>
                        <el-button size="mini" icon="el-icon-delete" type="danger" plain round  @click="delData(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="empty-box"></div> 
            <el-pagination background class="t-center"
                @current-change="pageChange"
                :page-size="page_size"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
            <div class="empty-box"></div>
        </el-main>
        <view-limited ref="ViewLimitedDraw" v-on:edit-data="editRef"></view-limited>
        <edit-limited ref="EditLimiteDraw"></edit-limited>
    </el-container>
</template>

<script>
import SearchLimited from '../components/search-limited.vue';
import ViewLimited from '../components/view-limitied.vue';
import EditLimited from '../components/edit-limited.vue';
import {getLimitedList, postDelLimited} from "../service/api";
export default {
    components: {SearchLimited, ViewLimited, EditLimited},
    data (){
        return {
            country: 2,
            checkIndex: '2',
            page_size: 20,
            page_no: 1,
            total: 0,
            proList: [],
        }
    },
    methods:{
        handleSelect(key, index){
            this.checkIndex = index;
            this.country = key;
            this.getInciList();
            this.$refs.TopLimitedSearch.changeContry(key);
        },
        getInciList(country){
            // if()
            // console.log(country);
            let ctry = country ? country : this.country;
            getLimitedList({
                country: ctry,
                page_no: this.page_no,
                page_size: this.page_size
            }).then(res => {
                let data = res.data;
                if(data.total && data.total > 0){
                    this.total = data.total;
                    this.page_no = data.page_no;
                    this.proList = data.list;
                    // console.log(222);
                }
                // console.log(res);
            }, err => {
                this.$message.error(err.msg);
            });
        },
        pageChange(page){
            if(page != this.page_no) {
                this.page_no = page;
                this.getInciList();
            }
        },
        editRef(ite){
            // console.log(ite);
            // this.$refs.EditProhibit.editData(ite);
            // this.$router.push(`/edit-limited/${ite.id}`);
            this.$refs.EditLimiteDraw.open(ite.id);
        },
        viewRef(ite){
            // console.log(ite);
            // this.$router.push(`/view-limited/${ite.id}`);
            this.$refs.ViewLimitedDraw.open(ite.id);
        },
        doneAdd(){
            this.getInciList();
        },
        searchFreash(data){
            this.proList = data.list;
            this.page_no  = data.page_no;
            this.total = data.total;
        },
        delData(ite){
            if(ite && ite.id > 0){
                let msg = `确定要删除【${ite.chemical_name}】的数据吗？删除后将无法恢复！`;
                this.$confirm(msg, '重要提示', {
                    confirmButtonText: `确定删除`,
                    cancelButtonText: `取消`,
                    type: `warning`
                }).then(() =>{
                    postDelLimited({
                        id: ite.id
                    }).then(res => {
                        this.$message({
                            message: res.msg,
                            type: `success`
                        });
                        this.getInciList();
                    }, err => {
                        this.$message.error(err.msg);
                    })
                }).catch(() => {

                });
            }else{
                this.$message.error(`操作失败~`);
            }
        },
        addNewData(){
            this.$refs.EditLimiteDraw.open(0);
        }
    },
    mounted: function(){
        this.getInciList();
    }
}
</script>
<style>
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 150px;
    font-size: 12px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>