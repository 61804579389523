function getStorage(key){
    if(!key) return false;

    let value = localStorage.getItem(key);

    return value;
}

function setStorage(key, value){
    if(!key || !value) return false;

    localStorage.setItem(key, value);

    return true;
}

function delStorage(key) {
    if(!key) return false;

    localStorage.removeItem(key);

    return true;
}

function formarteDate(date) {
    // let dateObj;
    if(date > 0 && date.toString().length === 10){
        let dateObj = new Date(date * 1000);

        if (dateObj.getFullYear()) {
            return dateObj.getFullYear() + '年' + (dateObj.getMonth() + 1) + '月' + dateObj.getDate() + '日';
        } else {
            return '时间格式错误';
        }
    }else{
        let dateObj = new Date(date);
        return dateObj.getFullYear() + '年' + (dateObj.getMonth() + 1) + '月' + dateObj.getDate() + '日';
    }
}

function getTimeStrNow(date) {
    let timeNow = new Date();
    if (date) {
        timeNow = new Date(date);
    }

    // let dateStr = timeNow.getTime
    let dateStr = timeNow.getFullYear() + '-' + (timeNow.getMonth() + 1) + '-' + timeNow.getDate() + ' ';

    dateStr += (timeNow.getHours() < 10 ? '0' + timeNow.getHours() : timeNow.getHours()) + ':';
    dateStr += (timeNow.getMinutes() < 10 ? '0' + timeNow.getMinutes() : timeNow.getMinutes()) + ':';
    dateStr += timeNow.getSeconds() < 10 ? '0' + timeNow.getSeconds() : timeNow.getSeconds();

    return {
        date_str: dateStr,
        date_now: timeNow,
        date_times: timeNow.getTime(),
        date_starCn: timeNow.getFullYear() + '年' + (timeNow.getMonth() + 1) + '月' + timeNow.getDate() + '日'
    };
}


export default {
    setStorage,
    getStorage,
    delStorage,
    formarteDate,
    getTimeStr: getTimeStrNow,

}