<template>
    <el-container>
        <el-header class="bg-gray">
            <div class="my-header">
                <h3>参考文献列表</h3>
            </div>
        </el-header>
        <el-main class="ref-draw-list">
            <div class="refer-list">
                <div class="top-ref-btns">
                    <el-input placeholder="请输入文献标题关键字" v-model="searchKey" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search"  class="search-in-btn" @click="searchRef">查询</el-button>
                    </el-input>
                    <el-button type="danger" class="add-ref-btn" icon="el-icon-plus"  @click="EventAddReference">添加</el-button>
                </div>
                <div class="empty-box"></div>
                <div class="tab-refelist">
                    <table cellspacing="0">
                        <thead>
                            <tr>
                                <td style="width: 10%">ID/编号</td>
                                <td style="width: 25%">参考文献标题</td>
                                <td style="width: 15%">章节</td>
                                <td style="width: 10%">复制</td>
                                <td style="width: 10%">附件</td>
                                <td style="width: 10%">预览</td>
                                <td style="width: 20%">编辑</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in tableData" :key="item.id">
                                <td>{{item.id}}</td>
                                <td>{{item.title}}</td>
                                <td>{{item.position}}</td>
                                <td>
                                    <el-button size="mini" icon="el-icon-document-copy" type="primary" plain round  @click="copyText(item)">复制</el-button>
                                </td>
                                <td>
                                    <el-button size="mini" icon="el-icon-view" v-if="item.pdf_id" type="success" plain round  @click="viewPdf(item)">查看</el-button>
                                    <el-tag type="info" v-if="!item.pdf_id">无附件</el-tag>
                                </td>
                                <td>
                                    <el-tooltip class="item" effect="dark" placement="top">
                                        <div slot="content">{{item.url}}</div>
                                        <el-button size="mini" icon="el-icon-link"  type="success" plain round  @click="viewUrl(item)">查看</el-button>
                                    </el-tooltip>
                                </td>
                                <td>
                                    <el-button size="mini" icon="el-icon-edit" type="warning" plain round  @click="editRef(item)">修改</el-button>
                                    <el-button size="mini" icon="el-icon-delete" type="danger" plain round  @click="delRef(item)">删除</el-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <el-pagination background class="t-center"
                    @current-change="pageChange"
                    :page-size="page_size"
                    layout="prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
            <add-reference ref="addReferenceBox" :refereId="editId" v-on:add-suc="doneAdd"></add-reference>
            <view-pdf ref="viewPDFCOM"></view-pdf>
        </el-main>
    </el-container>
</template>
<script>
import {getReferencedList, searchReference, delReference, getPdfUrlById} from '../service/api';
import AddReference from '../components//add-reference.vue';
import viewPdf from '../components/view-pdf.vue';
export default {
    components: {AddReference, viewPdf},
    data(){
        return  {
            editId: 0,
            tableData: [],
            page_no: 1,
            page_size: 20,
            total: 0,
            searchKey: ''
        }
    },
    methods:{
        getList(page_no){

            let pageNo = page_no ? page_no : this.page_no;
            let page_size = this.page_size;

            getReferencedList({
                page_no: pageNo, 
                page_size: page_size
            }).then(res => {
                    let data = res.data;
                    this.total = data.total;
                    this.tableData = data.list;
                    this.page_no = data.page_no;
                    this.page_size = +data.page_size;
                }, err => {
                    this.$message.error(err.msg);
                });

        },
        pageChange(val){
            if(val == this.page_now) return;
            else{
                this.getList(val);
            }
        },
        searchRef(){
            if(this.searchKey){
                searchReference({
                    key_word: encodeURIComponent(this.searchKey)
                }).then(res => {
                    let data = res.data;
                    this.total = data.total;
                    this.tableData = data.list;
                    this.page_no = data.page_no;
                    this.page_size = +data.page_size;
                }, err => {
                    this.$message.error(err.msg);
                });
            }
        },
        EventAddReference(){
            // this.openAdd = true;
            this.$refs.addReferenceBox.openAdd(0);
        },
        doneAdd(){
            this.getList();
        },
        viewUrl(ite){
            // console.log(ite);
            window.open(ite.url, '_blank');
        },
        editRef(ite){
            // console.log(ite);
            this.$refs.addReferenceBox.editData(ite);
        },
        delRef(ite){
            // console.log(ite);
            this.$confirm(`确定要删除【${ite.title}】的内容？`, '提示', {
                confirmButtonText: `确定`,
                cancelButtonText: `取消`,
            }).then(() => {
                delReference({
                    id: ite.id
                }).then(() => {
                    this.$message({
                        message: `数据删除成功`,
                        type: 'success'
                    });
                    this.doneAdd();
                }, err => {
                    this.$message.error(err.msg);
                });
            }).catch(() => {

            })
        },
        copyText(ite){
            // this.$copyText(ite.)
            if(!ite){
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(ite.title + ' ' + ite.position)
                .then(() => {
                    this.$message({
                        message: `复制成功`,
                        type: 'success'
                    });
                }, err => {
                    this.$message.error(`复制失败，请手动复制 err=${err}`);
                });
        },
        viewPdf(ite){
            console.log(ite);
            if(ite.pdf_id > 0){
                getPdfUrlById({
                    pdf_id: ite.pdf_id
                }).then(res => {
                    let obj = res.data;

                    let url = `/cir/${obj.file_name}`;

                    // console.log(url);
                    // url = `/mnapi/pdf/cosing/out_191.pdf`
                    this.$refs.viewPDFCOM.showDrawer({
                        title: obj.title,
                        url: url
                    });
                }, err => {
                    this.$message.error(err.msg);
                });
            }else{
                this.$message.error(`没有可用的附件`);
            }
            
        }
    },
    mounted: function(){
        this.getList();
    }
}
</script>
<style>
.top-ref-btns{
    margin: 10px auto;
    padding: 0 15px;
}
.top-ref-btns .el-input{
    float: left;
    width: 70%;
}
.top-ref-btns .add-ref-btn{
    float: right;
}
.select-list{
    margin: 5px auto;
    padding: 0 20px;
    line-height: 20px;
    font-weight: 400;
}

</style>