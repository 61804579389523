<template>
    <el-drawer :title="Title" :with-header="false" :visible.sync="showViews" :append-to-body="true" size="80%">
        <div class="my-draw-header">
            <div class="my-draw-title">{{Title}}</div>
            <div class="my-draw-edit-btns">
                <el-button type="primary" @click="onSubmit" size="medium" round icon="el-icon-thumb">保存</el-button>
                <el-button type="warning" @click="close" size="medium" round icon="el-icon-close">关闭</el-button>
            </div>
        </div>
        <div class="view-draw-box">
            <el-form rel="limitedForm" :model="LimitedInfo" label-position="right" label-width="200px">
                <el-form-item label="化学名称: ">
                    <el-input v-model="LimitedInfo.chemical_name" placeholder="请输入化学名称"></el-input>
                </el-form-item>
                <el-form-item label="国家/地区: ">
                    <el-radio-group v-model="LimitedInfo.country">
                        <el-radio :label="1">美国</el-radio>
                        <el-radio :label="2">欧盟</el-radio>
                        <el-radio :label="3">加拿大</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="原料类型: ">
                    <el-radio-group v-model="LimitedInfo.type">
                        <el-radio :label="0">普通原料</el-radio>
                        <el-radio :label="1">色素</el-radio>
                        <el-radio :label="2">防腐剂</el-radio>
                        <el-radio :label="3">防晒剂</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="原始序号: ">
                    <el-input v-model="LimitedInfo.from_num" placeholder="请输入原始序号"></el-input>
                </el-form-item>
                <el-form-item label="通用名称: ">
                    <el-input v-model="LimitedInfo.common_name" placeholder="请输入通用名称"></el-input>
                </el-form-item>
                <el-form-item label="CAS编号: ">
                    <el-input v-model="LimitedInfo.cas_num" placeholder="请输入CAS编号"></el-input>
                </el-form-item>
                <el-form-item label="同义词: ">
                    <el-input v-model="LimitedInfo.synonym" placeholder="请输入同义词"></el-input>
                </el-form-item>
                <el-form-item label="使用类型: ">
                    <el-input type="textarea" :rows="4" placeholder="请输入使用类型" v-model="LimitedInfo.type_of_use"></el-input>
                </el-form-item>
                <el-form-item label="最大使用浓度: ">
                    <el-input type="textarea" :rows="4" placeholder="请输入最大使用浓度" v-model="LimitedInfo.max_concentration"></el-input>
                </el-form-item>
                <el-form-item label="其他限制条件: ">
                    <el-input type="textarea" :rows="4" placeholder="请输入其它限制条件" v-model="LimitedInfo.more_limitition"></el-input>
                </el-form-item>
                <el-form-item label="标签警示语: ">
                    <el-input type="textarea" :rows="6" placeholder="请输入标签警示语" v-model="LimitedInfo.warnings"></el-input>
                </el-form-item>
                <el-form-item  v-if="LimitedInfo.type == 1" label="颜色（色素专有）: ">
                    <el-input v-model="LimitedInfo.colour" placeholder="请输入颜色"></el-input>
                </el-form-item>
                <el-form-item v-if="LimitedInfo.type == 1" label="色素编号（色素专有）: ">
                    <el-input v-model="LimitedInfo.ci_num" placeholder="请输入颜色编号"></el-input>
                </el-form-item>
                <!-- <el-form-item>
                    <el-button type="primary" @click="onSubmit">保存</el-button>
                </el-form-item> -->
            </el-form>
        </div>
    </el-drawer>
</template>
<script>
import { getLimitedById, updateLimited } from '../service/api';
export default {
    data(){
        return {
            id: 0,
            Title: `限用原料编辑`,
            showViews: false,
            LimitedInfo: {}
        }
    },
    methods: {
        close(){
            this.showViews = false;
        },
        saveDone(){
            this.close();
        },
        getText(){
            if(this.id > 0){
                getLimitedById({
                    id: this.id
                }).then(res => {
                    // console.log(res);
                    // this.reportText = res.data.text;
                    this.LimitedInfo = res.data;
                }, err => {
                    this.$message.error(err.msg);
                });
            }
        },
        onSubmit(){

            if(!this.LimitedInfo.chemical_name){
                this.$message.error(`请输入化学名称！`);
                return;
            }

            if(this.LimitedInfo.type === ''){
                this.$message.error(`请选择原料类型`);
                return;
            }

            if(!this.LimitedInfo.country){
                this.$message.error(`请选择国家/地区`);
                return;
            }

            if(this.LimitedInfo.type === 1 && !this.LimitedInfo.ci_num){
                this.$message.error(`请输入颜色色号！`);
                return;
            }

            let upObj = {
                chemical_name: this.LimitedInfo.chemical_name,
                common_name: this.LimitedInfo.common_name,
                cas_num: this.LimitedInfo.cas_num,
                synonym: this.LimitedInfo.synonym,
                type_of_use: this.LimitedInfo.type_of_use,
                max_concentration: this.LimitedInfo.max_concentration,
                more_limitition: this.LimitedInfo.more_limitition,
                warnings: this.LimitedInfo.warnings,
                ec_num: this.LimitedInfo.ec_num,
                type: this.LimitedInfo.type,
                colour: this.LimitedInfo.colour,
                ci_num: this.LimitedInfo.ci_num,
                country: this.LimitedInfo.country,
                from_num: this.LimitedInfo.from_num,
                id: this.id
            }
            // console.log(upObj);
            updateLimited(upObj).then(res => {
                this.$message({
                    message: '保存成功！',
                    type: 'success'
                });
                // this.$route.params.id = this.id;
                // if(this.id == 0){
                //     this.$router.replace(`/edit-limited/${res.data}`);
                // }
                this.id = res.data;
                this.getText();
            }, err => {
                this.$message.error(err.msg);
            });
        },
        open(id){
            this.id = id;
            // console.log(this.$route.params.id);
            this.showViews = true;
            if(this.id > 0){
                this.getText();
            }else{
                this.Title = `添加限用原料`;
                this.LimitedInfo = {
                    chemical_name: '',
                    common_name: '',
                    cas_num: '',
                    synonym: '',
                    type_of_use: '',
                    max_concentration: '',
                    more_limitition: '',
                    warnings: '',
                    ec_num: '',
                    type: '',
                    colour: '',
                    ci_num: '',
                    country: '',
                    from_num: '',
                    id: 0
                }
            }
        }
    }
}
</script>