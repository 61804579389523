<template>
    <el-dialog :title="Title" center 
    :visible.sync="openAddRefer" 
    :close-on-click-modal="false"
    :append-to-body="true"
    :before-close="handleClose" width="65%">
        <el-form :model="referData" :rules="rules">
            <el-form-item label="文献标题" label-width="120px" prop="title">
                <el-input v-model="referData.title" @change="inputChange" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="章节" label-width="120px" prop="position">
                <el-input v-model="referData.position" @change="inputChange" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文献链接" label-width="120px" prop="url">
                <el-input v-model="referData.url" @change="inputChange" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="备注" label-width="120px" prop="remark">
                <el-input v-model="referData.remark" @change="inputChange" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="附件" label-width="120px" >
                <el-tag v-if="!referData.pdf_id" size="mini" type="info">无附件</el-tag>
                <el-tag v-if="referData.pdf_id > 0" size="mini" type="success">已添加</el-tag>
                <el-upload
                class="upload-demo fr ma-left"
                ref="uploadPdfBox"
                :action="fileUploadURL" 
                :on-change="checkFileType"
                :on-exceed="onExceed"
                :on-error="uplaodError"
                :on-success="uploadSuc"
                :auto-upload="false"
                :data="referData"
                :limit="1">
                <!-- <i class="el-icon-upload"></i> -->
                <!-- <div class="el-upload__text">将文件拖到此处，或<em>点击选择</em></div> -->
                    <el-button slot="trigger" size="mini" round>选择PDF文件</el-button>
                    <el-button plain class="ma-left" size="mini" round type="primary" @click="uploadNow">上传文件</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelEdit">取 消</el-button>
            <el-button type="primary" @click="saveData">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import {saveReference} from '../../service/api';
export default {
    props: ['refereId'],
    data(){
        return  {
            openAddRefer: false,
            fileUploadURL: '/mnapi/uploadReferencePdf',
            formIsChange: false,
            Title: `添加参考文献`,
            referData: {
                id: 0,
                title: '',
                position: '',
                url: '',
                remark: '',
                pdf_id: 0,
            },
            rules: {
                title: [{
                    required: true, 
                    message: '请输入参考文献标题', 
                    trigger: 'blur'
                },{ 
                    min: 10, 
                    max: 2000, 
                    message: '长度在 10 到 2000 个字符', 
                    trigger: 'blur' 
                }],
                url: [{
                    required: true, 
                    message: '请输入参考文献链接', 
                    trigger: 'blur'
                },{ 
                    min: 10, 
                    max: 1000, 
                    message: '长度在 10 到 1000 个字符', 
                    trigger: 'blur' 
                }]
            }
        }
    },
    methods:{
        close(){
            this.openAddRefer = false;
        },
        saveData(){
            saveReference({
                id: this.referData.id,
                title: encodeURIComponent(this.referData.title), 
                url: encodeURIComponent(this.referData.url),
                position: encodeURIComponent(this.referData.position),
                remark: encodeURIComponent(this.referData.remark),
                pdf_id: this.referData.pdf_id
            }).then(() => {
                // console.log(res);
                this.$message({
                    message: `保存成功`,
                    type: 'success'
                });
                this.formIsChange = false;
                this.$emit('add-suc');
                this.close();
            }, err => {
                this.$message.error(err.msg);
            });
        },
        openAdd(tp){
            if(tp === 0){
                this.referData = {
                    id: 0,
                    title: '',
                    position: '',
                    url: '',
                    remark: '',
                    pdf_id: 0,
                }
            }
            this.openAddRefer = true;
        },
        editData(data){
            if(data.id && data.title){
                this.referData = data;
            }
            this.openAddRefer = true;
        },
        uplaodError (err){
            console.log(err)
        },
        uploadSuc(suc){
            console.log(suc);
            if(suc.code === 0 && suc.data){
                this.referData.pdf_id = suc.data.pdf_id;
                this.formIsChange = true;
            }else{
                this.$message.error(suc.msg);
            }
        },
        clearFiles(){
            this.$refs.uploadPdfBox.clearFiles();
        },
        checkFileType(file){
            let fileType = file.raw.type;

            if(file.size > 1024 * 6000){
                this.$message.error('文件太大了，不能超过6M');
                this.clearFiles();
                return false;
            }
            console.log(file.type, file.raw);
            // console.log(fileType);
            if(fileType != 'application/pdf'){
                this.$message.error('请上传正确的PDF文件！');
                // console.log(this);
                this.clearFiles();
                return false;
            }
            return true;
        },
        uploadNow(){
            this.$refs.uploadPdfBox.submit();
        },
        handleClose(done){
            if(this.formIsChange){
                this.$confirm(`修改内容尚未保存，是否要保存？`, '重要提示', {
                    confirmButtonText: `保存并关闭`,
                    cancelButtonText: `不保存`
                })
                    .then(() => {
                        this.saveData();
                        done();
                    }).catch(() => {

                    });
            }else{
                done();
            }
        },
        inputChange(value){
            if(value){
                this.formIsChange = true;
            }
        }
    },
    mounted: function(){
        // this.getList();
    }
}
</script>
<style>
</style>