<template>
    <el-container class="search-main">
        <el-main>
            <div class="top-search">
                <el-input @keydown.enter.native="doSearch" placeholder="请输入内容" v-model="searchKey" class="input-with-select">
                    <el-select v-model="searchType" slot="prepend"  placeholder="请选择">
                        <el-option label="中文名称" value="1"></el-option>
                        <el-option label="INCI名称" value="2"></el-option>
                        <el-option label="序号" value="3"></el-option>
                        <el-option label="CAS号" value="4"></el-option>
                    </el-select>
                    <el-button type="primary" class="search-in-btn" slot="append" 
                    icon="el-icon-search" @click="doSearch">搜索</el-button>
                </el-input>
                <el-button type="primary" class="out-btn" plain @click="reSearch">全部</el-button>
                <el-button type="primary" plain @click="reloadData">刷新数据</el-button>
            </div>
            <div class="search-result">
                <el-table :data="dataList" :key="dataChange" size="mini" row-key="id" ref="listTable" stripe style="width: 100%" toggleRowExpansion>
                    <el-table-column prop="iecic_num" label="序号" width="80"></el-table-column>
                    <el-table-column label="原料名称（中文&INCI）" class-name="duble-names">
                        <template slot-scope="props">
                            <p>{{props.row.cn_name}}</p>
                            <p class="en-name">{{props.row.inci_name}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="iecic_rinse" label="淋洗类用量(%)" width="120"></el-table-column>
                    <el-table-column prop="iecic_reside" label="驻留类用量(%)" width="120"></el-table-column>
                    <el-table-column prop="csts_info" label="技术规范要求"></el-table-column>
                    <el-table-column prop="evaluate" label="评审意见"></el-table-column>
                    <el-table-column label="详情" width="80" align="right">
                        <template slot-scope="props">
                            <el-link type="primary" @click="viewDetail(props.row)">详情</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="120">
                        <template  slot-scope="props">
                            <el-button size="mini" type="primary" icon="el-icon-edit" round @click="handleEdit(props.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-main>
        <el-footer>
            <el-pagination background class="t-center" @current-change="pageChange"
                :page-size="page_size" :current-page="current_page" layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-footer>
        <edit-iecic ref="EditIecicTem" v-on:edit-done="reloadData"></edit-iecic>
        <!-- <view-references ref="viewReference"></view-references> -->
        <!-- <cir-reportlist ref="cirReportList" v-on:view-pdf="getCirPdfUrl"></cir-reportlist>
        <view-pdf ref="viewPDFCOM"></view-pdf>
        <view-cosing ref="viewCosingTem" v-on:view-pdf="viewPdf"></view-cosing> -->
        <iecic-detail ref="viewIecicDetailTem" v-on:edit="handleEdit"></iecic-detail>
    </el-container>
</template>
<script>
import {getPdfUrlById, getPdfUrlByCirId } from '../service/api';

import {getIecicList, searchIecic} from '../service/newApi';
import EditIecic from '../components/iecic/edit-iecic.vue';
// import ViewReferences from '../components/view-references.vue';
// import CirReportlist from '../components/cir-reportlist.vue';
// import viewPdf from '../components/view-pdf.vue';
// import IecicRiskdetail from '../components/iecic-risk-detail';
// import ViewCosing from '../components/cosing/view-cosing';
import IecicDetail from '../components/iecic/iecic-detail';

export default {
    components: { 
        EditIecic, 
        // ViewReferences,
        IecicDetail
    },
    data(){
        return {
            searchKey: '',
            searchType: '1',
            page_no: 1,
            page_size: 20,
            total: 0,
            current_page: 1,
            dataList: [],
            dataChange: 0
        }
    },
    methods: {
        getDataByKey(KeyWord){
            // console.log(this.page_no);
            this.$ComFn.setStorage('IECIC_SEARCHKEY', JSON.stringify({
                search_type: this.searchType,
                key_word: KeyWord,
            }));

            searchIecic({
                key_word: KeyWord,
                search_type: parseInt(this.searchType),
                page_no: this.page_no,
                page_size: this.page_size
            }).then(res => {
                this.initData(res.data);
            }, err => {
                this.$message.error(err.msg);
            })
        },
        doSearch(){

            let KeyWord = this.searchKey;

            if(!KeyWord){
                // this.$message.error('请输入关键字！');
                this.getList(1);
                return;
            }

            this.page_no = 1;
            this.total = 0;

            this.getDataByKey(KeyWord);

        },
        reSearch(){
            this.searchKey = '';
            this.page_no = 1;
            this.total = 0;
            this.getList();
        },
        initData(data){
            this.page_no = data.page_no;
            this.total = data.total;
            this.current_page  = data.page_no;
            this.dataList = data.list;
            // console.log(data);
            this.$ComFn.setStorage('IECIC_PAGE', this.page_no);
            this.dataChange += 1;
        },
        getList(page_no){
            this.$ComFn.delStorage('IECIC_SEARCHKEY');
            let pageNo = page_no ? page_no : 1;
            if(this.total !== 0 && (pageNo - 1) * this.page_size > this.total){
                this.$message.error('没有更多数据了！');
                return;
            }
            getIecicList({
                page_no: pageNo,
                page_size: this.page_size
            }).then(res => {
                // console.log(res);
                this.initData(res.data);
            }, err => {
                this.$message.error(err.msg);
            })
        },
        pageChange(val){
            // console.log(this.searchKey);
            if(val !== this.page_no){

                if(!this.searchKey){
                    this.getList(val);
                }else{
                    // console.log(val);
                    this.page_no = val;
                    this.getDataByKey(this.searchKey);
                }
            }
        },
        viewDetail(row){
            this.$refs.viewIecicDetailTem.open(row.id);
        },
        showTableLine(row){
            let $table = this.$refs.listTable;

            //关闭其它行
            this.dataList.map(item => {
                if(row.id !== item.id){
                    $table.toggleRowExpansion(item, false);
                }
            });
            $table.toggleRowExpansion(row);
        },
        handleEdit(item){
            // this.$router.push(`/edit-rawformula/${item.id}`);
            this.$refs.EditIecicTem.open(item);
        },
        viewReference(ids){
        // console.log(ids);
            this.$refs.viewReference.openDrawer(ids);
        },
        viewCosingBtn(id){
            // this.$router.push(`/view-cosing/${id}`);
            this.$refs.viewCosingTem.open(id);
        },
        initKeyWord(obj){
            this.searchKey = obj.key_word;
            this.searchType = obj.search_type;
        },
        viewCirList(row){
            this.$refs.cirReportList.openDrawer(row);
        },
        getCirPdfUrl(id){
            if(id && id > 0){
                getPdfUrlById({
                    pdf_id: id
                }).then(res => {
                    let obj = res.data;

                    let url = `/cir/${obj.file_name}`;

                    this.viewPdf({
                        title: obj.title,
                        url: url
                    });
                    
                }, err => {
                    this.$message.error(err.msg);
                });
            }else{
                this.$message.error('参数无效！');
            }
        },
        viewCirReport(id){
            if(id && id > 0){
                getPdfUrlByCirId({
                    cir_id: id
                }).then(res => {
                    let obj = res.data;

                    let url = `/cir/${obj.file_name}`;

                    // console.log(url);
                    // url = `/mnapi/pdf/cosing/out_191.pdf`
                    this.$refs.viewPDFCOM.showDrawer({
                        title: obj.title,
                        url: url
                    });
                }, err => {
                    this.$message.error(err.msg);
                });
            }else{
                this.$message.error('参数无效！');
            }
        },
        reloadData(){
            if(this.searchKey && this.searchKey !== ''){
                this.getDataByKey(this.searchKey);
            }else{
                this.getList(this.page_no);
            }
            // window.location.reload();
        }
    },
    mounted: function(){

        // let hisData = this.$ComFn.getStorage('IECIC_LISTHIS');

        let searchObj = this.$ComFn.getStorage('IECIC_SEARCHKEY');
        // console.log(searchObj);
        // this.getList(1);
        let pageHis = this.$ComFn.getStorage('IECIC_PAGE');

        if(parseInt(pageHis) > 0){
            this.page_no = parseInt(pageHis);
        }else{
            this.page_no = 1;
        }

        if(searchObj){
            try{
                let seachData = JSON.parse(searchObj)
                this.initKeyWord(seachData);
                this.doSearch();
            }catch(e){
                this.getList(this.page_no);
                return;
            }
        }else{
            this.getList(this.page_no);
        }

    }
}
</script>
<style lang="less">
.search-result{
    .search-total{
        p{
            font-size: 14px;
            margin: 5px auto;
            line-height: 30px;
            b{
                margin: auto 5px;
                font-weight: 400;
                color: #ff6348;
            }
            &.none-total{
                margin-left: 5px;
            }
        }
    }
    .el-table__expanded-cell[class*=cell]{
        padding: 20px;
    }
    .more-iceci-box{
        width: 100%;
        margin: 0;
        th,td{
            line-height: 30px !important;
            border-bottom: solid 1px #fff;
            padding: 10px !important;
        }
        th{
            color: #99a9bf;
            font-size: 12px;
            width: 13%;
            text-align: right;
            vertical-align: top;
            white-space: nowrap;
            background: #fcfcfc;
        }
        td{
            width: 37%;
            background: transparent !important;
        }
    }
    .view-refbtn{
        margin-left: 10px;
    }
}
</style>