<template>
    <el-drawer :title="Title" :with-header="false" :visible.sync="showViews" size="80%">
        <div class="my-draw-header">
            <div class="my-draw-title">{{Title}}</div>
            <div class="my-draw-edit-btns">
                <el-button type="primary" @click="editData" size="medium" round icon="el-icon-edit">编辑</el-button>
                <el-button type="warning" size="medium" @click="close" round icon="el-icon-close">关闭</el-button>
            </div>
        </div>
        <div class="view-draw-box">
            <el-form label-position="left" label-width="200px">
                <el-form-item label="INCI: ">
                    <span>{{ dataInfo.inci }}</span>
                    <el-button  class="copy-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(dataInfo.inci)"></el-button>
                </el-form-item>
                <el-form-item label="审核意见 - EU: ">
                    <div class="report-remark" v-if="dataInfo.cn_eu">
                        <span>{{ dataInfo.cn_eu }}</span>
                        <el-button  class="copy-btn" size="mini" 
                        icon="el-icon-document-copy" 
                        type="primary" plain circle  @click="copyText(dataInfo.cn_eu)"></el-button>
                    </div>
                </el-form-item>
                <el-form-item label="审核意见 - US: ">
                    <div class="report-remark" v-if="dataInfo.cn_us">
                        <span>{{ dataInfo.cn_us }}</span>
                        <el-button  class="copy-btn" size="mini" 
                        icon="el-icon-document-copy" 
                        type="primary" plain circle  @click="copyText(dataInfo.cn_us)"></el-button>
                    </div>
                </el-form-item>
                <el-form-item label="审核意见 - CA: ">
                    <div class="report-remark" v-if="dataInfo.cn_ca">
                        <span>{{ dataInfo.cn_ca }}</span>
                        <el-button  class="copy-btn" size="mini" 
                        icon="el-icon-document-copy" 
                        type="primary" plain circle  @click="copyText(dataInfo.cn_ca)"></el-button>
                    </div>
                </el-form-item>
                <el-form-item label="Report Remarks - EU: ">
                    <div class="report-remark" v-if="dataInfo.eu_remark">
                        <b>{{dataInfo.eu_remark}}</b>
                        <el-button  class="copy-btn" size="mini" 
                        icon="el-icon-document-copy" 
                        type="primary" plain circle  @click="copyText(dataInfo.eu_remark)"></el-button>
                    </div>
                    <div class="report-remark" v-if="dataInfo.eu_more">
                        <span>{{dataInfo.eu_more}}</span>
                        <el-button  class="copy-btn" size="mini" 
                        icon="el-icon-document-copy" 
                        type="primary" plain circle  @click="copyText(dataInfo.eu_more)"></el-button>
                    </div>
                </el-form-item>
                <el-form-item label="Report Remarks - US: ">
                    <div class="report-remark" v-if="dataInfo.us_remark">
                        <b>{{dataInfo.us_remark}}</b>
                        <el-button  class="copy-btn" size="mini" 
                        icon="el-icon-document-copy" 
                        type="primary" plain circle  @click="copyText(dataInfo.us_remark)"></el-button>
                    </div>
                    <div class="report-remark" v-if="dataInfo.us_more">
                        <span>{{dataInfo.us_more}}</span>
                        <el-button  class="copy-btn" size="mini" 
                        icon="el-icon-document-copy" 
                        type="primary" plain circle  @click="copyText(dataInfo.us_more)"></el-button>
                    </div>
                </el-form-item>
                <el-form-item label="Report Remarks - CA: ">
                    <div class="report-remark" v-if="dataInfo.ca_remark">
                        <b>{{dataInfo.ca_remark}}</b>
                        <el-button  class="copy-btn" size="mini" 
                        icon="el-icon-document-copy" 
                        type="primary" plain circle  @click="copyText(dataInfo.ca_remark)"></el-button>
                    </div>
                    <div class="report-remark" v-if="dataInfo.ca_more">
                        <span>{{dataInfo.ca_more}}</span>
                        <el-button  class="copy-btn" size="mini" 
                        icon="el-icon-document-copy" 
                        type="primary" plain circle  @click="copyText(dataInfo.ca_more)"></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <!-- <edit-limited ref="EditLimiteDraw"></edit-limited> -->
    </el-drawer>
</template>
<script>
// import EditLimited from './edit-limited.vue';
// import { getLimitedById } from '../service/api';
export default {
    // components: {EditLimited},
    data(){
        return {
            id: 0,
            Title: `审核汇总`,
            showViews: false,
            dataInfo: {}
        }
    },
    methods: {
        close(){
            this.showViews = false;
        },
        saveDone(){
            this.close();
        },
        copyText(txt){
            // console.log(window);
            if(!txt){
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(txt)
                .then(() => {
                    this.$message({
                        message: `复制成功`,
                        type: 'success'
                    });
                }, err => {
                    this.$message.error(`复制失败，请手动复制 err=${err}`);
                });
        },
        open(data){
            this.dataInfo = data;
            this.showViews = true;
        },
        editData(){
            this.$emit('edit-data', this.dataInfo);
        }
    }
}
</script>