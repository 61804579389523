<template>
    <el-container>
        <el-header class="bg-gray">
            <div class="my-header">
                <h3>风险物质管理</h3>
            </div>
        </el-header>
        <el-main>
            <risk-comlist ref="RiskComList" :showAddBtn="false"></risk-comlist>
        </el-main>
    </el-container>
</template>
<script>
import RiskComlist from '../components/risk-comlist.vue';
export default {
    components: {RiskComlist},
}
</script>
<style>
</style>