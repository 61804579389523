<template>
    <el-drawer :title="Title" :visible.sync="showViews" size="40%">
        <el-table :data="cosingList" style="width: 100%">
            <el-table-column label="所属数据">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.same_type === 2" type="warning">禁用原料 II</el-tag>
                    <el-tag v-if="scope.row.same_type === 3" type="warning">限用原料 III</el-tag>
                    <el-tag v-if="scope.row.same_type === 4" type="warning">色素 IV</el-tag>
                    <el-tag v-if="scope.row.same_type === 5" type="warning">防腐剂 V</el-tag>
                    <el-tag v-if="scope.row.same_type === 6" type="warning">防晒剂 VI</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="Cosing数据">
                <template slot-scope="scope">
                    <el-button size="mini" icon="el-icon-view" type="success" plain round  @click="viewCosingList(scope.row)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <view-cosing ref="ViewCosingDraw"></view-cosing>
    </el-drawer>
</template>
<script>
import ViewCosing from './view-cosing.vue';
export default {
    components: { ViewCosing },
    data(){
        return{
            Title: `Cosing数据`,
            showViews: false,
            cosingList: [],
        }
    },
    methods:{
        close(){
            this.showViews = false;
        },
        viewCosingList(ite){
            this.$refs.ViewCosingDraw.open(ite.cosing_id);
        },
        open(ite){
            this.cosingList = ite;
            this.showViews = true;
        }
    }
}
</script>