<template>
    <el-container>
        <el-main>
            <h3>限用原料</h3>
            <!-- <h3 v-if="country == 1">美国（US）INCI</h3>
            <h3 v-if="country == 2">欧盟 (EU) INCI</h3>
            <h3 v-if="country == 3">欧盟 (CA)) INCI</h3> -->
            <el-form label-width="100px">
                <el-form-item label="国家/地区">
                    <el-radio-group v-model="country">
                        <el-radio label="2">欧洲（US）</el-radio>
                        <el-radio label="3">加拿大（UA）</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="原料名称">
                    <el-input type="textarea" rows="4" v-model="keys" placeholder="请输入原料名称/CAS编号，每一行代表一个名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="doSearch('name')">化学名称查询</el-button>
                    <el-button type="danger" @click="doSearch('cas')">CAS编号查询</el-button>
                </el-form-item>
            </el-form>

            <el-table :data="dataList"
            stripe :row-class-name="tableRowClassName">
                <el-table-column type="expand" label="展开" width="50">
                    <template  slot-scope="props">
                        <el-form label-position="left" label-width="200px">
                            <el-form-item label="使用类型: ">
                                <div class="html-box" v-html="props.row.type_of_use"></div>
                                <el-button  class="copy-btn fl-cp-btn" size="mini" 
                                icon="el-icon-document-copy" 
                                type="primary" plain circle  @click="copyText(scope.row.type_of_use)"></el-button>
                            </el-form-item>
                            <el-form-item label="最大使用浓度: ">
                                <div class="html-box" v-html="props.row.max_concentration"></div>
                                <el-button  class="copy-btn fl-cp-btn" size="mini" 
                                icon="el-icon-document-copy" 
                                type="primary" plain circle  @click="copyText(scope.row.max_concentration)"></el-button>
                            </el-form-item>
                            <el-form-item label="其他限制条件: ">
                                <div class="html-box" v-html="props.row.more_limitition"></div>
                                <el-button class="copy-btn fl-cp-btn" size="mini" 
                                icon="el-icon-document-copy" 
                                type="primary" plain circle  @click="copyText(scope.row.more_limitition)"></el-button>
                            </el-form-item>
                            <el-form-item label="标签警示语: ">
                                <div class="html-box" v-html="props.row.warnings"></div>
                                <el-button class="copy-btn fl-cp-btn" size="mini" 
                                icon="el-icon-document-copy" 
                                type="primary" plain circle  @click="copyText(scope.row.warnings)"></el-button>
                            </el-form-item>
                            <el-form-item label="颜色（色素专有）: ">
                                <span>{{ props.row.colour }}</span>
                                <el-button class="copy-btn" size="mini" 
                                icon="el-icon-document-copy" 
                                type="primary" plain circle  @click="copyText(scope.row.colour)"></el-button>
                            </el-form-item>
                            <el-form-item label="色素编号（色素专有）: ">
                                <span>{{ props.row.ci_num }}</span>
                                <el-button class="copy-btn" size="mini" 
                                icon="el-icon-document-copy" 
                                type="primary" plain circle  @click="copyText(scope.row.ci_num)"></el-button>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column prop="key" label="查询名称"></el-table-column>
                <el-table-column label="INCI/化学名称">
                    <template slot-scope="scope">
                        {{country == 2 ? scope.row.common_name : scope.row.chemical_name}}
                    </template>
                </el-table-column>
                <el-table-column prop="cas_num" label="CAS"></el-table-column>
                <el-table-column label="原料类型" v-if="country==2">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.type == 0" type="success">普通原料</el-tag>
                        <el-tag v-if="scope.row.type == 1" type="success">色素</el-tag>
                        <el-tag v-if="scope.row.type == 2" type="success">防腐剂</el-tag>
                        <el-tag v-if="scope.row.type == 3" type="success">防晒剂</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="Cosing数据" v-if="country==2">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-view"
                        v-if="scope.row.cosing_list"
                         type="success" plain round  @click="viewCosingList(scope.row)">查看</el-button>
                        
                        <el-button size="mini"
                        v-if="!scope.row.cosing_list" disable
                         type="info" plain round>未找到</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="结果" width="80">
                    <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.pair ? 'danger' : 'success'"
                    disable-transitions>{{scope.row.pair ? 'YES' : 'NO'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="编辑/修改" width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-edit" type="primary" plain round  @click="editRef(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <edit-prohibit ref="EditUaInci" v-on:add-suc="doneAdd"></edit-prohibit>
        <view-same-list ref="showCosingList"></view-same-list>
    </el-container>
</template>
<script>
import {checkLimitedUse} from "../service/api";
import EditProhibit from './edit-prohibit.vue';
import ViewSameList from './view-same-list.vue';
export default {
    components: {EditProhibit, ViewSameList},
    // props: ['country'],
    data(){
        return {
            dataList: [],
            keys: '',
            country: '2',
            searchBy: 'name'
        }
    }, 
    methods: {
        indexMethod(index) {
            return index + 1;
        },
        doSearch(tp){
            // console.log(1);
            if(!tp){
                tp = this.searchBy;
            }
            this.searchBy = tp;
            let searchNames = this.keys;
            if(searchNames){
                let searchArry;
                // console.log(searchNames.search(/\n/));
                if(searchNames.search(/\n/) < 0){
                    searchArry = [searchNames];
                }else{
                    searchArry = searchNames.split(/\n/);
                }

                let subArr = [];

                for(let i = 0; i < searchArry.length; i++){
                    let ite = searchArry[i];
                    if(ite && ite !== ''){
                        // ite = ite.replace(/(\s)*(）|\))(\s)*/g, '）');
                        // ite = ite.replace(/(\s)*(（|\()(\s)*/g, '（');
                        ite = ite.replace(/(^\s*)|(\s*$)/g, '');
                        // ite = ite.replace(/^\s*/g, '');
                        subArr.push(ite);
                    }
                }


                // console.log(subArr);
                checkLimitedUse({
                    search_type: tp,
                    country: this.country,
                    keys: encodeURIComponent(JSON.stringify(subArr))
                }).then(res => {
                    // let data = res.data;
                    this.dataList = res.data;
                    // console.log(res);
                }, err => {
                    this.$message.error(err.msg);
                });
            }else{
                this.$message.error(`请输入关键词！`);
            }
        },
        tableRowClassName({row}){
            // console.log(row);
            return row && row.pair ? 'none-pair' : '';

        },
        editRef(ite){
            // console.log(ite);
            // this.$refs.EditUaInci.editData(ite);
            this.$router.push(`/edit-limited/${ite.id}`);
        },
        doneAdd(){
            // this.searchBy = 'name';
            this.doSearch();
        },
        copyText(txt){
            // console.log(window);
            if(!txt){
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(txt)
                .then(() => {
                    this.$message({
                        message: `复制成功`,
                        type: 'success'
                    });
                }, err => {
                    this.$message.error(`复制失败，请手动复制 err=${err}`);
                });
        },
        viewCosingList(ite){
            this.$refs.showCosingList.open(ite.cosing_list);
        }
    }
}
</script>