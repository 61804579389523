<template>
    <el-container>
        <el-header class="bg-gray">
            <div class="my-header">
                <h3>MINISO 审核汇总</h3>
                <el-button type="primary" class="right-btn" icon="el-icon-plus" @click="addInci">添加</el-button>
            </div>
        </el-header>
        <el-main>
            <search-review-remark ref="TopSearchReview" 
                v-on:do-search="searchList" 
                v-on:re-search="getList">
            </search-review-remark>
            <el-table :data="dataList" stripe>
                <el-table-column prop="inci" label="INCI名称">
                </el-table-column>
                <!-- <el-table-column show-overflow-tooltip="true" label="审核意见">
                    <el-table-column prop="cn_eu" label="欧盟" >
                    </el-table-column>
                    <el-table-column prop="cn_us" label="美国" >
                    </el-table-column>
                    <el-table-column prop="cn_ca" label="加拿大" >
                    </el-table-column>
                </el-table-column> -->
                <el-table-column align="center" label="Report Remarks">
                    <el-table-column label="EU" >
                        <template slot-scope="scope">
                            <h4>{{scope.row.eu_remark}}</h4>
                            <p>{{scope.row.eu_more}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="US" >
                        <template slot-scope="scope">
                            <h4>{{scope.row.us_remark}}</h4>
                            <p>{{scope.row.us_more}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="CA">
                        <template slot-scope="scope">
                            <h4>{{scope.row.ca_remark}}</h4>
                            <p>{{scope.row.ca_more}}</p>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="更多" width="100">
                    <template slot-scope="scope">
                        <el-button class="vtable-btn" size="mini" icon="el-icon-view" type="primary" plain round  @click="viewDetail(scope.row)">查看</el-button>
                        <el-button class="vtable-btn" size="mini" icon="el-icon-edit" type="warning" plain round  @click="editRemark(scope.row)">修改</el-button>
                        <el-button class="vtable-btn" size="mini" icon="el-icon-delete" type="danger" plain round  @click="delData(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <el-footer>
            <el-pagination background class="t-center"
                @current-change="pageChange"
                :page-size="page_size"
                :current-page="current_page"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </el-footer>
        <!-- <el-dialog
        title="新建报告"
        :visible.sync="openNewReport"
        :fullscreen="true">
            <add-report v-on:done-exit="editDone"></add-report>
        </el-dialog> -->
        <view-review-remark ref="ViewReviewDetail" v-on:edit-data="editRemark"></view-review-remark>
        <edit-review-remark ref="EditReviewDetail" v-on:edit-done="editDone"></edit-review-remark>
    </el-container>
</template>

<script>
import EditReviewRemark from '../components/edit-review-remark.vue';
import SearchReviewRemark from '../components/search-review-remark.vue';
import ViewReviewRemark from "../components/view-review-remark.vue";
import {getReviewRemarkList, delReviewRemark, searchReviewRemark} from '../service/api';
export default {
    components: {ViewReviewRemark, EditReviewRemark, SearchReviewRemark},
    data (){
        return {
            page_no: 1,
            page_size: 20,
            page_now: 1,
            current_page: 1,
            total: 0,
            dataList : [],
            showEdit: false,
            keyWord: ''
        }
    },
    methods:{
        initData(data){
            this.page_no = data.page_no;
            this.page_now = data.page_no;
            this.total = data.total;
            this.dataList = data.list;
        },
        getList(page_no){

            if(!page_no){
                page_no = this.page_no;
            }

            let page_size = this.page_size;

            this.keyWord = '';

            getReviewRemarkList({
                page_no: page_no , 
                page_size: page_size
            }).then(res => {
                if(res.code === 0){
                    let data = res.data;
                    this.initData(data);
                }else{
                    this.$message.error(res.msg);
                }
            }, err => {
                this.$message.error(err.msg);
            });
        },
        searchList(key, page_no){
            if(!page_no){
                page_no = this.page_no;
            }

            let page_size = this.page_size;
            // console.log(this.country);
            let keyWord = key.replace(/^[\s]|[\s*]$/g, '');

            this.keyWord = keyWord;

            searchReviewRemark({
                key_word: encodeURIComponent(keyWord),
                page_no: page_no , 
                page_size: page_size
            }).then(res => {
                // this.$emit('search-end', res.data);
                this.initData(res.data);
            }, err => {
                this.$message.error(err.msg);
            })
        },
        pageChange(val){
            if(val == this.page_now) return;
            else{
                if(!this.keyWord){
                    this.getList(val);
                }else{
                    this.searchList(this.keyWord, val);
                }
            }
        },
        editRemark(data){
            this.$refs.EditReviewDetail.open(data.id, data);
        },
        editDone(){
            this.getList();
        },
        addInci(){
            this.$refs.EditReviewDetail.open(0);
        },
        viewDetail(data){
            this.$refs.ViewReviewDetail.open(data);
        },
        delData(data){
            if(data && data.id > 0){
                let msg = `确定要删除【${data.inci}】的数据吗？删除后将无法恢复！`;
                this.$confirm(msg, '重要提示', {
                    confirmButtonText: `确定删除`,
                    cancelButtonText: `取消`,
                    type: `warning`
                }).then(() =>{
                    delReviewRemark({
                        id: data.id
                    }).then(res => {
                        this.$message({
                            message: res.msg,
                            type: `success`
                        });
                        this.getList();
                    }, err => {
                        this.$message.error(err.msg);
                    })
                }).catch(() => {

                });
            }else{
                this.$message.error(`删除失败，数据不存在`);
            }
        }
    },
    mounted: function(){
        this.getList();
    }
}
</script>
<style>
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 150px;
    font-size: 12px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>