<template>
    <div class="top-search">
        <el-input @keydown.enter.native="doSearch" placeholder="请输入内容" v-model="serachInput" class="input-with-select">
            <el-button type="primary" class="search-in-btn" slot="append" 
            icon="el-icon-search" @click="doSearch">搜索</el-button>
        </el-input>
        <el-button type="primary" class="out-btn" plain @click="reSearch">全部</el-button>
        <!-- <el-button type="primary" class="out-btn" icon="el-icon-plus" plain @click="addData">添加原料</el-button> -->
    </div>
</template>
<script>
// import {searchCosing} from '../service/api';
export default {
    data(){
        return {
            serachInput: '',
            searchType: 'chemical_name',
            page_no: 1,
            page_size: 20
        }
    },
    methods: {
        doSearch(){
            if(!this.serachInput){
                this.$message.error('请输入关键字！');
                return;
            }
            // console.log(this.country);
            let keyWord = this.serachInput.replace(/^[\s]|[\s*]$/g, '');
            this.$emit('search-end', keyWord);
            // searchCosing({
            //     key: encodeURIComponent(keyWord),
            //     page_no: this.page_no,
            //     page_size: this.page_size
            // }).then(res => {
            //     this.$emit('search-end', res.data);
            // }, err => {
            //     this.$message.error(err.msg);
            // })
        },
        reSearch(){
            this.serachInput = '';
            this.$emit('re-search');
        },
        addData(){
            this.$router.push(`/edit-limited/0`);
        }
    },
    mounted() {
        this.serachInput = localStorage.cosing_key ? localStorage.cosing_key : '';
    }
}
</script>
<style>
.top-search{
    margin: 0 auto 20px;
}
.input-with-select{
    width: 70%;
}
.el-select{
    width: 130px;
}
.search-in-btn i{
    color: #fff;
}
.list-search{
    float: right;
}
.draw-box{
    margin: 15px;
}
</style>