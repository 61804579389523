<template>
    <div class="ay-bg-box" >
        <h3 class="ay-h-titile">
            <span class="tit-span">风险物质</span>
            <el-button class="ma-left" icon="el-icon-plus" :disabled="!showEdit" size="small" @click="addRiskIds" type="primary">添加</el-button>
            <el-tag class="ma-left" type="warning" v-if="!showEdit">请先保存IECIC基础数据</el-tag>
        </h3>
        <div class="ay-own-box">
            <risk-linked-list :key="getDataTimes"
                ref="riskLinkedListTem"
                :iecic_id="iecicData.id" :hide_linked="true"
                v-on:change-menu="addRiskIds"
                v-on:cancel-match="unMatch" v-on:reload-linkedids="reLoadLinkedIds"></risk-linked-list>
        </div>
        <edit-risk ref="editRiskTem" :refereId="editId" v-on:add-suc="reloadData"></edit-risk>
        <risk-checked ref="iecicRiskCheckTem" :riskIds="linkedIds" v-on:edit-done="reloadData"></risk-checked>
    </div>
</template>
<script>
import {cancelLinkedIecic} from '../../service/newApi';
import EditRisk from './edit-risk.vue';
import RiskChecked from './risk-checked.vue';
import RiskLinkedList from './risk-linked-list.vue';
export default {
    props: ['iecicData', 'showEdit'],
    components: {
        EditRisk,
        RiskChecked,
        RiskLinkedList
    },
    data(){
        return {
            dataList: [],
            noneData: true,
            errMsg: '无风险物质数据',
            getDataTimes: 0,
            iecicId: 0,
            linkedIds: []
        }
    },
    methods:{
        copyText(text){
            if (!text) {
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(text).then(() => {
                this.$message({
                    message: `复制成功`,
                    type: 'success'
                });
            }, err => {
                this.$message.error(`复制失败，请手动复制 err=${err}`);
            });
        },
        getData(){
            if(!this.iecicData) return;
            this.iecicId = this.iecicData.id;
            this.getDataTimes = Date.now();
        },
        reLoadLinkedIds(ids){
            this.linkedIds = ids;
        },
        editData(ite){
            this.$refs.editRiskTem.open(ite);
        },
        reloadData(){
            this.getData();
        },
        unMatch(ite){
            let msg = `确定要取消风险物质【${ite.name}】与原料【${this.iecicData.cn_name}}】的关联吗？`
            this.$confirm(msg, '重要提示', {
                    confirmButtonText: `确定`,
                    cancelButtonText: `取消`
                }).then(() => {
                    cancelLinkedIecic({
                        iecic_id: this.iecicId,
                        type: 'risk',
                        linked_id: ite.id
                    }).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.reloadData();
                    }, err => {
                        this.$message.error(err.msg);
                        this.loading = false;
                    });
                }).catch(() => {
                    
                });
        },
        addRiskIds(){
            let data = this.iecicData;
            this.$refs.iecicRiskCheckTem.open(data);
        }
    },
    mounted: function(){
        this.getData();
        this.getDataTimes = 0;
    }
}
</script>