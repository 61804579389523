<template>
    <div class="table-formula">
        <table>
            <thead>
                <tr>
                    <td style="width:5%">序号</td>
                    <td style="width:15%">标准中文名</td>
                    <td style="width:20%">INCI名称</td>
                    <td style="width:10%">原料含量（%）</td>
                    <td style="width:10%">原料中成分含量（%）</td>
                    <td style="width:10%">实际成分含量（%）</td>
                    <td style="width:10%">使用目的</td>
                    <td style="width:20%">备注</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in listdata" :key="item.$index">
                    <td v-if="item.show_td" :rowspan="item.rowspan_num">{{item.index}}</td>
                    <td>{{item.cn_name}}</td>
                    <td>{{item.en_name}}</td>
                    <td v-if="item.show_td" :rowspan="item.rowspan_num">{{item.have}}</td>
                    <td>{{item.formula_have}}</td>
                    <td>{{item.formula_child_have}}</td>
                    <td v-if="item.show_td" :rowspan="item.rowspan_num">{{item.usefor}}</td>
                    <td>{{item.remark}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    props: ['listdata']
}
</script>
<style>
.table-formula{
    margin: 0 auto;
    font-size: 12px;
}
.table-formula table{
    border-collapse: collapse;
    border-left: solid 0.5px #b2bec3;
    border-top: solid 0.5px #b2bec3;
}
.table-formula table thead{
    font-weight: 600;
}
.table-formula table thead td{
    background-color: #fafafa;
}
.table-formula table td{
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    border-right: solid 0.5px #b2bec3;
    border-bottom: solid 0.5px #b2bec3;
}
</style>