<template>
    <el-container>
        <el-header class="bg-gray no-padding">
            <el-menu
                :default-active="checkIndex"
                class="el-menu-demo bg-trans"
                mode="horizontal"
                @select="handleSelect">
                <el-menu-item index="2">欧盟禁用原料</el-menu-item>
                <el-menu-item index="3">加拿大禁用原料</el-menu-item>
            </el-menu>
        </el-header>
        <el-main>
            <div v-if="checkIndex < 4">
                <search-prohibited :country="country" v-on:search-end="searchFreash" 
                v-on:re-search="getInciList"
                v-on:add-data="addProhibit"></search-prohibited>
                <el-table :data="proList" stripe>
                    <el-table-column prop="id" label="ID" width="100"></el-table-column>
                    <el-table-column prop="chemical_name" label="原料名称"></el-table-column>
                    <el-table-column prop="cas_num" label="CAS编号"></el-table-column>
                    <el-table-column prop="guid_name" show-overflow-tooltip label="通用名称"></el-table-column>
                    <el-table-column prop="ec_num" label="EC编号"></el-table-column>
                    <el-table-column prop="from_num" label="原始序号" width="100"></el-table-column>
                    <el-table-column label="编辑/修改" width="200">
                        <template slot-scope="scope">
                            <el-button size="mini" icon="el-icon-edit" type="primary" plain round  @click="editRef(scope.row)">修改</el-button>
                            <el-button size="mini" icon="el-icon-delete" type="danger" plain round  @click="delData(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="empty-box"></div> 
                <el-pagination background class="t-center"
                    @current-change="pageChange"
                    :page-size="page_size"
                    layout="prev, pager, next"
                    :total="total">
                </el-pagination>
                <div class="empty-box"></div>
            </div>
        </el-main>
        <edit-prohibit ref="EditProhibit" v-on:add-suc="doneAdd"></edit-prohibit>
    </el-container>
</template>

<script>
import EditProhibit from '../components/edit-prohibit.vue';
import SearchProhibited from '../components/search-prohibited.vue';

// import UsInciList from '../components/us-inci-list.vue';
import {getProhibitedList, postDelProhibited} from "../service/api";
export default {
    components: {SearchProhibited, EditProhibit},
    data (){
        return {
            country: 2,
            checkIndex: '2',
            page_size: 20,
            page_no: 1,
            total: 0,
            proList: [],
        }
    },
    methods:{
        handleSelect(key, index){
            this.country = key;
            this.getInciList();
            this.checkIndex = index;
        },
        getInciList(country){
            // if()
            // console.log(country);
            let ctry = country ? country : this.country;
            getProhibitedList({
                country: ctry,
                page_no: this.page_no,
                page_size: this.page_size
            }).then(res => {
                let data = res.data;
                if(data.total && data.total > 0){
                    this.total = data.total;
                    this.page_no = data.page_no;
                    this.proList = data.list;
                    // console.log(222);
                }
                // console.log(res);
            }, err => {
                this.$message.error(err.msg);
            });
        },
        pageChange(page){
            if(page != this.page_no) {
                this.page_no = page;
                this.getInciList();
            }
        },
        editRef(ite){
            // console.log(ite);
            this.$refs.EditProhibit.editData(ite);
        },
        addProhibit(){
            this.$refs.EditProhibit.editData();
        },
        doneAdd(){
            this.getInciList();
        },
        searchFreash(data){
            this.proList = data.list;
            this.page_no  = data.page_no;
            this.total = data.total;
        },
        delData(ite){
            if(ite && ite.id > 0){
                let msg = `确定要删除【${ite.chemical_name}】的数据吗？删除后将无法恢复！`;
                this.$confirm(msg, '重要提示', {
                    confirmButtonText: `确定删除`,
                    cancelButtonText: `取消`,
                    type: `warning`
                }).then(() =>{
                    postDelProhibited({
                        id: ite.id
                    }).then(res => {
                        this.$message({
                            message: res.msg,
                            type: `success`
                        });
                        this.getInciList();
                    }, err => {
                        this.$message.error(err.msg);
                    })
                }).catch(() => {

                });
            }else{
                this.$message.error(`操作失败~`);
            }
        }
    },
    mounted: function(){
        this.getInciList();
    }
}
</script>
<style>
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 150px;
    font-size: 12px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>