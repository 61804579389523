<template>
    <el-container>
        <!-- <el-header height="150px" class="search-header">
            <div class="search-inputbox">
                <el-input type="textarea" 
                    rows="4"
                    placeholder="请输入原料名称（中文），每行一个" 
                    v-model="inputNames"
                    label="原料名称">
                </el-input>
                <el-button slot="append" type="primary" @click="doSearch">立即查询</el-button>
            </div>  
        </el-header> -->
        <el-main>
            <el-form label-width="100px">
                <el-form-item label="查询名称">
                    <el-input type="textarea" rows="4" v-model="inputNames" placeholder="请输入名称/关键字，每一行代表一个名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :disabled="!inputNames" @click="doSearch(1)">中文名称查询</el-button>
                    <el-button type="danger" :disabled="!inputNames" @click="doSearch(2)">英文名称查询</el-button>
                    <el-button type="warning" :disabled="!inputNames" @click="clearData">清空数据</el-button>
                </el-form-item>
            </el-form>
            <div class="searchend-head">
                <span class="fl tit">查询结果</span>
                <el-button class="fr" type="success" icon="el-icon-circle-check" plain @click="checkUse()">用量检查</el-button>
                <el-button class="fr" type="primary" icon="el-icon-document-copy" plain @click="copyeUse()">复制用量</el-button>
                <!-- <el-button class="fr" type="success" @click="checkUse()">复制驻留用量</el-button> -->
            </div>
            <el-table :data="searchList"
            :key="tableKey"
            size="mini"
            stripe
            :row-class-name="tableRowClassName">
                <el-table-column prop="key" label="搜索名称"></el-table-column>
                <!-- <el-table-column prop="pair" label="查询结果"></el-table-column> -->
                <el-table-column prop="iecic_num" label="序号" :formatter="isGetInfo">
                    <template slot="header">
                        <div class="tb-header-box">
                            <label>序号</label>
                            <el-button size="mini" class="table-head-btn" icon="el-icon-copy-document" type="primary" plain circle  @click="copyData(1)"></el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="cn_name" label="中文名称" min-width="150px">
                    <template slot="header" >
                        <div class="tb-header-box">
                            <label>中文名称</label>
                            <el-button size="mini" class="table-head-btn" icon="el-icon-copy-document" type="primary" plain circle  @click="copyData(4)"></el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="inci_name" label="INCI名称/英文名称" min-width="150px">
                    <template slot="header" >
                        <div class="tb-header-box">
                            <label>INCI/英文名称</label>
                            <el-button size="mini" class="table-head-btn" icon="el-icon-copy-document" type="primary" plain circle  @click="copyData(3)"></el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="iecic_rinse" label="淋洗类用量"></el-table-column>
                <el-table-column prop="iecic_reside" label="驻留类用量"></el-table-column>
                <el-table-column prop="input_value" label="检查值"></el-table-column>
                <el-table-column prop="sum_use" label="总量"></el-table-column>
                <el-table-column prop="check_end" :label="checkTitle">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="!scope.row.check_end">--</el-tag>
                        <el-tag type="success" v-if="scope.row.check_end === 1">符合</el-tag>
                        <el-tag type="danger" v-if="scope.row.check_end === 2">超量</el-tag>
                        <el-tag type="info" v-if="scope.row.check_end === 3">未知</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="aoac_cir" label="CIR结论"></el-table-column>
                <el-table-column label="风险物质">
                    <template slot-scope="scope">
                        <div v-for="risk in scope.row.risk_info" :key="risk.id">
                            <p>{{risk.name}}</p>
                            <!-- <p class="en-name">{{risk.evaluate}}</p> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
                <el-table-column prop="csts_info" label="技术规范要求" min-width="150px">
                    <template slot="header" >
                        <div class="tb-header-box">
                            <label>技术规范要求</label>
                            <el-button size="mini" class="table-head-btn" icon="el-icon-copy-document" type="primary" plain circle  @click="copyData(2)"></el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template  slot-scope="props">
                        <el-button size="mini" type="primary" icon="el-icon-view" plain round @click="viewDetail(props.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <check-uselist ref="checkDialog" v-on:check-now="checkData"></check-uselist>
        </el-main>
        <iecic-detail ref="viewDetailTem" v-on:edit-done="reloadData"></iecic-detail>
    </el-container>
</template>
<script>
import checkUselist from '../components/check-uselist.vue';
import {searchIecicByNames} from '../service/newApi';
import IecicDetail from '../components/iecic/iecic-detail.vue';
// import math from 'mathjs';
export default {
  components: { checkUselist, IecicDetail},
    data(){
        return {
            boxSearchList: false,
            inputNames: '',
            searchList: [],
            useCheckList: [],
            useCheckType: 0,
            checkTitle: '',
            tableKey: 1,
        }
    },
    methods: {
        openSearchList(){
            this.boxSearchList = true;
        },
        doSearch(tp){
            let searchNames = this.inputNames;

            if(searchNames){
                let searchArry;
                // console.log(searchNames.search(/\n/));
                if(searchNames.search(/\n/) < 0){
                    searchArry = [searchNames];
                }else{
                    searchArry = searchNames.split(/\n/);
                }

                let subArr = [];

                for(let i = 0; i < searchArry.length; i++){
                    let ite = searchArry[i];
                    if(ite && ite !== ''){
                        if(tp === 1){
                            ite = ite.replace(/(\s)*(）|\))(\s)*/g, '）');
                            ite = ite.replace(/(\s)*(（|\()(\s)*/g, '（');
                        }
                        subArr.push(ite);
                    }
                }

                // return;
                // console.log(subArr);
                searchIecicByNames({
                    type: tp,
                    names: encodeURIComponent(JSON.stringify(subArr))
                }).then(res => {
                    // console.log(res);
                    this.initTableData(res.data);
                }, err => {
                    console.log(err);
                })
            }
        },
        clearData(){
            this.inputNames = null;
        },
        isGetInfo(rows){
            return rows && rows.pair ? rows.iecic_num : '未找到';
        },
        tableRowClassName({row}){
            // console.log(row);
            return row && row.pair ? '' : 'none-pair';
        },
        checkUse(){
            this.$refs.checkDialog.openCheck();
        },
        initTableData(list){
            this.searchList = list;
            this.tableKey = Math.random();
        },
        viewDetail(item){
            // this.$router.push(`/edit-rawformula/${item.id}`);
            this.$refs.viewDetailTem.open(item.id);
        },
        checkData(obj){
            // console.log(obj);
            // console.log(1);
            let check_key = '';
            if(obj.type === 1){
                this.checkTitle = '驻留类用量检查';
                check_key = 'iecic_reside';
            }else{
                this.checkTitle = '淋洗类用量检查';
                check_key = 'iecic_rinse';
            }

            //对用量相同原料进行叠加
            let use_lim = [];
            let has_arr = [];
            let list = obj.list;

            let endList = this.searchList;
            // console.log(endList);

            for(let j = 0 ; j < list.length; j++){
                let use = parseFloat(list[j]);
                let item = endList[j].iecic_num;

                let se_num = has_arr.indexOf(item);
                if(se_num > -1){
                    use_lim[se_num] += parseFloat(use);
                }else{
                    use_lim.push(use);
                    has_arr.push(item);
                }
            }

            // console.log(use_lim, has_arr);

            for(let i = 0; i < obj.list.length; i++){
                let use = parseFloat(obj.list[i]);
                let item = parseFloat(endList[i][check_key]);

                let iecic_num = endList[i].iecic_num;
                let sum_num = has_arr.indexOf(iecic_num);

                let sum_use = use_lim[sum_num];
                endList[i].input_value = obj.list[i];

                endList[i].sum_use = sum_use;
                // console.log(use, item);
                if(item && item > 0){
                    if(use && use <= item && sum_use < item){
                        endList[i].check_end = 1;
                    }else{
                        endList[i].check_end = 2;
                    }
                }else{
                    endList[i].check_end = 3
                }
            }

            this.initTableData(endList);
            // this.searchList = endList;
            // console.log(this.searchList);
            // this.$forceUpdate();
            // this.tableKey = Math.random();

        },
        copyeUse(){
            let str = '驻留类用量\t淋洗类用量\n';

            if(!this.searchList || this.searchList.length <= 0){
                this.$message.error(`没有可用数据`);
            }

            for(let i = 0; i < this.searchList.length; i++){
                let item = this.searchList[i];

                str += item.iecic_reside ? item.iecic_reside : '';
                str += '\t';
                str += item.iecic_rinse ? item.iecic_rinse : ''; 
                str += '\n';
            }

            this.$copyText(str)
                .then(() => {
                    this.$message({
                        message: `复制成功`,
                        type: 'success'
                    });
                }, err => {
                    this.$message.error(`复制失败，请手动复制 err=${err}`);
                });
        },
        copyData(tp){
            let data = this.searchList;

            let str = '';
            let total_tit = '';

            if(data.length > 0){
                if(tp === 1){
                    total_tit = '序号';
                    data.forEach((k) =>{
                        str += k.iecic_num + '\n';
                    });
                }
                if(tp === 2){
                    total_tit = '技术规范要求';
                    data.forEach((k) =>{
                        str += k.csts_info + '\n';
                    });
                }
                if(tp === 3){
                    total_tit = 'INCI/英文名称';
                    data.forEach((k) =>{
                        str += k.inci_name + '\n';
                    });
                }
                if(tp === 4){
                    total_tit = '中文名称';
                    data.forEach((k) =>{
                        str += k.cn_name + '\n';
                    });
                }
                // console.log(str);
                this.$copyText(str)
                    .then(() => {
                        this.$message({
                            message: `${total_tit}复制成功`,
                            type: 'success'
                        });
                    }, err => {
                        this.$message.error(`复制失败，请手动复制 err=${err}`);
                    });
            }else{
                this.$message.error('无有效数据！');
            }
        }
    }
}
</script>
<style>
/* .search-header{
    background: #fafafa;
    border-bottom: #eee solid 0.5px;
}
.top-search{
    margin: 0 auto 20px;
}
.search-inputbox{
    margin: 15px;
    width: 100%;
    overflow: hidden;
}
.search-inputbox .el-textarea,
.search-inputbox .el-button{
    float: left;
}
.search-inputbox .el-textarea{
    margin: 0 20px auto auto;
    width: 60%;
} */
/* .searchend-head{
    height: 40px;
    margin: 10px auto;
    overflow: hidden;
}
.searchend-head span.tit{
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
}
.searchend-head button{
    margin: auto 15px;
}
.input-with-select{
    width: 70%;
}
.el-select{
    width: 130px;
}
.search-in-btn{
    width: 80px;
}
.list-search{
    float: right;
}
.draw-box{
    margin: 15px;
} */
</style>