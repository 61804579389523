<template>
    <el-drawer :with-header="false" :visible.sync="showDrawer" 
    :before-close="handleClose" :append-to-body="true" :close-on-press-escape="false" 
    v-loading="loading" body size="75%">
        <el-container>
            <el-header class="top-search ay-drawer-header">
                <span class="title-s">{{Title}}</span>
                <el-button class="fr" type="danger" @click="handleClose" circle icon="el-icon-close"></el-button>
            </el-header>
            <el-container>
                <el-aside width="150px" class="ay-aside">
                    <el-menu :default-active="LeftMenuIndex" @select="changeMenu">
                        <el-menu-item :index="1">
                            <span slot="title">已关联</span>
                        </el-menu-item>
                        <el-menu-item :index="2">
                            <span slot="title">风险物质</span>
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <el-main>
                    <risk-linked-list v-if="LeftMenuIndex === 1"
                     ref="riskLinkedListTem"
                     :iecic_id="iecicId" :hide_linked="true"
                     v-on:cancel-match="unMatch" v-on:reload-linkedids="reloadLinkedIds"
                     v-on:change-menu="changeMenu"></risk-linked-list>
                    <risk-all-list v-else ref="riskAllListTem"
                    :linked_ids="linkedIds"
                    v-on:cancel-match="unMatch" v-on:add-match="addMatch"></risk-all-list>
                </el-main>
            </el-container>
        </el-container>
    </el-drawer>
</template>
<script>
import {doLinkedIecic, cancelLinkedIecic} from '../../service/newApi';

import RiskAllList from './risk-all-list.vue';
import RiskLinkedList from './risk-linked-list.vue';
export default {
    props: ['riskIds'],
    components: {
        RiskAllList,
        RiskLinkedList
    },
    data(){
        return {
            showDrawer: false,
            loading: false,
            Title: `关联原料`,
            iecicData: {},
            reloadIecic: true,
            linkedIds: [],
            LeftMenuIndex: 1,
            iecicId: 0
        }
    },
    methods: {
        changeMenu(index){
            this.LeftMenuIndex = index;
        },
        handleClose(){
            if(this.reloadIecic){
                this.$emit('edit-done');
            }
            this.showDrawer = false;
        },
        open(iecicData){
            
            this.iecicData = iecicData;
            this.iecicId = iecicData.id;
            this.showDrawer = true;
            console.log(iecicData);
            // console.log(this.risk_ids);
            
        },
        reloadData(){
            if(this.LeftMenuIndex === 1){
                this.$refs.riskLinkedListTem.reloadData();
            }else{
                this.$refs.riskAllListTem.reloadData();
            }

            this.loading = false;
        },
        reloadLinkedIds(ids){
            this.linkedIds = ids;
        },
        delIecicIds(id){
            let ids = this.linkedIds;
            if(ids.indexOf(id) > -1){
                let new_ids = [];
                ids.forEach(k => {
                    if(k !== id){
                        new_ids.push(k);
                    }
                });
                this.linkedIds = new_ids
            }
            this.reloadIecic = true;
        },
        addIecicIds(id){
            let ids = this.linkedIds;
            if(ids && ids.indexOf(id) < 0){
                ids.push(id);
                this.linkedIds = ids
            }
            this.reloadIecic = true;
        },
        addMatch(ite){
            let msg = `确定要将风险物质【${ite.name}】关联到原料【${this.iecicData.cn_name}}】吗？`
            this.$confirm(msg, '重要提示', {
                    confirmButtonText: `确定`,
                    cancelButtonText: `取消`
                }).then(() => {
                    doLinkedIecic({
                        iecic_id: this.iecicId,
                        type: 'risk',
                        linked_id: ite.id
                    }).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        // ite.has_now = true;
                        // this.loading = false;
                        this.addIecicIds(ite.id);
                        this.reloadData();
                    }, err => {
                        this.$message.error(err.msg);
                        this.loading = false;
                    });
                }).catch(() => {
                    this.loading = false;
                });
        },
        unMatch(ite){
            let msg = `确定要取消风险物质【${ite.name}】与原料【${this.iecicData.cn_name}}】的关联吗？`
            this.$confirm(msg, '重要提示', {
                    confirmButtonText: `取消`,
                    cancelButtonText: `取消`
                }).then(() => {
                    cancelLinkedIecic({
                        iecic_id: this.iecicId,
                        type: 'risk',
                        linked_id: ite.id
                    }).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        // ite.has_now = false;
                        // this.loading = false;
                        this.delIecicIds(ite.id);
                        this.reloadData();
                    }, err => {
                        this.$message.error(err.msg);
                        this.loading = false;
                    });
                }).catch(() => {
                    
                });
        }
    }
}
</script>