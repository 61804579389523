<template>
    <el-container>
        <el-header class="bg-gray">
            <div class="my-header">
                <h3>原料列表</h3>
                <el-button type="primary" class="right-btn" icon="el-icon-plus" @click="newReport">新建报告</el-button>
            </div>
        </el-header>
        <el-main>
            <el-table :data="reportList" border stripe style="width: 100%">
                <!-- <el-table-column prop="id" label="ID" width="60">
                </el-table-column> -->
                <el-table-column
                prop="id"
                label="ID"
                width="80">
                </el-table-column>
                <el-table-column
                prop="pro_name"
                label="产品名称">
                </el-table-column>
                <el-table-column
                prop="create_time" :formatter="formatDate"
                label="创建时间">
                </el-table-column>
                <el-table-column prop="report_status" :formatter="reportStatus"
                label="状态">
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                        size="mini" type="primary" plain
                        @click="handleEdit(scope.row)" :disabled="isEdit(scope.row)">查看报告</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <el-footer>
            <el-pagination background class="t-center"
                @current-change="pageChange"
                :page-size="page_size"
                :current-page="current_page"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </el-footer>
        <!-- <el-dialog
        title="新建报告"
        :visible.sync="openNewReport"
        :fullscreen="true">
            <add-report v-on:done-exit="editDone"></add-report>
        </el-dialog> -->
    </el-container>
</template>

<script>
import {getReportList} from '../service/api';
import localFn from '../service/com.js';
export default {
    components: {},
    data (){
        return {
          page_no: 1,
          page_size: 20,
          page_now: 1,
          current_page: 1,
          total: 0,
          reportList : [],
          editData: null,
          showEdit: false,
          openNewReport: false
        }
    },
    methods:{
        getList(page_no, page_size){
            if(!page_no){
                page_no = 1;
            }
            if(!page_size){
                page_size = 20;
            }
            getReportList({
                page_no: page_no , 
                page_size: page_size})
                .then(res => {
                // console.log(res);
                    if(res.code === 0){
                        let dataNow = res.data;
                        this.page_no = dataNow.page_no;
                        this.page_now = dataNow.page_no;
                        this.total = dataNow.total;
                        this.reportList = dataNow.list;
                    }
                }, err => {
                    console.log(err, 2111);
                });
        },
        pageChange(val){
            if(val == this.page_now) return;
            else{
                this.getList(val);
            }
        }, 
        handleEdit(item){
            // this.showEdit = true;
            // this.editData = item;
            // console.log(item);
            this.$router.push(`/view-report/${item.id}`);
        },
        reportStatus(sta){
            // console.log(sta);
            let stat_str = '';
            switch(sta.report_status){
                case 1:
                    stat_str = '配方表上传成功';
                    break;

                case 2:
                    stat_str = '配方表解析成功';
                    break;

                case 3:
                    stat_str = '已生成报告';
                    break;
                
                default:
                    stat_str = '未知';
                    break;
            }
            return stat_str;
        },
        isEdit(row){
            if(row.report_status === 3){
                return false;
            }else{
                return true;
            }
        },
        formatDate(obj){
            let date_str = localFn.formarteDate(obj.create_time);
            return date_str;
        },
        newReport(){
            // this.openNewReport = true;
            this.$router.push('/create-report');
        },
        editDone(){
            this.openNewReport = false;
            this.getList(1);
        }
    },
    mounted: function(){
        this.getList(1);
    }
}
</script>
<style>
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 150px;
    font-size: 12px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>