<template>
    <el-dialog title="其它数据编辑/添加" center :visible.sync="openDialg" width="65%">
        <el-form :model="referData">
            <el-form-item label="NAME/名称" label-width="120px" prop="inci_name">
                <el-input v-model="referData.name" type="textarea" :rows="3" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="说明" label-width="120px" prop="cn_name">
                <el-input v-model="referData.description" type="textarea" :rows="3" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="其它备注" label-width="120px" prop="old_name">
                <el-input v-model="referData.remark" type="textarea" :rows="3" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="国家/地区" label-width="120px" prop="country">
                <el-radio-group v-model="referData.country">
                    <el-radio :label="1">美国</el-radio>
                    <el-radio :label="2">欧盟</el-radio>
                    <el-radio :label="3">加拿大</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="数据类型" label-width="120px" prop="type">
                <el-radio-group v-model="referData.type">
                    <el-radio :label="1">使用方法（FUNCTION）</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelEdit">取 消</el-button>
            <el-button type="primary" @click="saveData">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import {saveComCheck} from '../service/api';
export default {
    data(){
        return  {
            openDialg: false,
            referData: {
                id: 0,
                name: '',
                description: '',
                remark: '',
                type: 0,
                country: 0
            }
        }
    },
    methods:{
        cancelEdit(){
            this.openDialg = false;
        },
        saveData(){
            
            saveComCheck({
                id: this.referData.id,
                name: encodeURIComponent(this.referData.name),
                description: encodeURIComponent(this.referData.description),
                remark: encodeURIComponent(this.referData.remark),
                type: this.referData.type,
                country: this.referData.country
            }).then(() => {
                // console.log(res);
                this.$message({
                    message: `保存成功`,
                    type: 'success'
                });
                this.$emit('add-suc');
                this.cancelEdit();
            }, err => {
                this.$message.error(err.msg);
            });
        },
        openAdd(){
            this.openDialg = true;
        },
        editData(data){
            if(data && data.id && data.id > 0){
                // console.log(data);
                this.referData = data;
            }else{
                this.referData = {
                    id: 0,
                    name: '',
                    description: '',
                    remark: '',
                    type: 0,
                    country: 0
                }
            }
            this.openDialg = true;
        }
    },
    mounted: function(){
        // this.getList();
    }
}
</script>
<style>
</style>