<template>
    <div class="report-editer">
        <!-- <div id="editorToolbar" class="editor-toolbar"></div> -->
        <div id="editorText" class="editor-text">
            <div v-html="reportHtml"></div>
        </div>
        <el-row class="foot-bt-box">
            <el-button @click="closePage">关 闭</el-button>
            <el-button type="primary" @click="saveEditorText">保存修改</el-button>
        </el-row>
    </div>
</template>
<script>
import wangEditor from 'wangeditor';
import { doneReport } from '../service/api';
export default {
    props: ['reportHtml', 'report_id'],
    data(){
        return {
            editor: null,
            editorData: '',
            confirmMsg: '确定保存当前报告吗？'
        }
    },
    methods: {
        closePage(){
            this.$confirm('确定退出编辑报告页面？', '重要提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('save-text');
            }).catch(() => {
                return;       
            });
        },
        postSaveReport(tex, report_id){
            // console.log(report_id, 222, tex);
            if(report_id > 0 && tex){
                doneReport({
                    report_id: report_id,
                    report_text: encodeURIComponent(tex)
                }).then(res => {
                    // console.log(res, 333);
                    if(res.code === 0){
                        // this.dialogObj.confirmMsg ='报告保存成功！'
                        // this.dialogObj.dialogVisible = true;
                        this.$confirm('是否退出当前页面？', '保存成功', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'success'
                        }).then(() => {
                            this.$emit('save-text');
                        }).catch(() => {
                            return;       
                        });
                    }else{
                        this.$notify.error({
                            title: '保存失败',
                            message: res.msg
                        });
                    }

                }, err => { 
                    this.$notify.error({
                        title: '保存失败',
                        message: err.msg
                    });
                });
            }else{
                this.$notify.error({
                    title: '保存失败',
                    message: `参数错误，请检查！`
                });
            }
        },
        saveEditorText(){
            // let textStr = this.editorData;
            let textStr = this.editorData;
            // console.log(textStr);
            if(!textStr){
                this.$confirm('报告未做任何修改，是否确认保存自动生成内容？', '重要提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    textStr = this.reportHtml;
                    this.postSaveReport(textStr, this.report_id);
                }).catch(() => {
                    return;       
                });
            }else{
                this.postSaveReport(textStr, this.report_id);
            }

            

        }
    },
    mounted() {
        const Editor = new wangEditor('#editorText');

        Editor.config.menus = [
            'head',
            'bold',
            'fontSize',
            'fontName',
            'indent',
            'justify',
            'undo',
            'redo',
        ];

        Editor.config.height = 600;

        Editor.config.zIndex = 200;


        Editor.config.onchange = (newHtml) => {
            this.editorData = newHtml;
        }

        Editor.create();

        this.editor = Editor;
    },
    beforeDestroy(){
        this.editor.destroy();
        this.editor = null;
    }
};
</script>
<style>
.editor-text{
    border: 1px solid #eee;
}
</style>