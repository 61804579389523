<template>
    <div class="ref-draw-list">
        <el-drawer
            title="参考文献列表"
            size="65%"
            :visible.sync="drawer"
            direction="rtl"
            append-to-body="true"
            :before-close="handleClose">
            <div class="refer-list">
                <div class="top-ref-btns">
                    <el-input placeholder="请输入文献标题关键字" v-model="searchKey" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search"  class="search-in-btn" @click="searchRef">查询</el-button>
                    </el-input>
                    <el-button type="danger" class="add-ref-btn" icon="el-icon-plus"  @click="addReference">添加</el-button>
                </div>
                <div class="empty-box"></div>  
                <h4 class="select-list">当前选择：{{selectIds.join(",")}}</h4>
                <div class="tab-refelist">
                    <table cellspacing="0">
                        <thead>
                            <tr>
                                <td>ID/编号</td>
                                <td>参考文献标题</td>
                                <td>选择/操作</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in tableData" :key="item.id" :class="tableSelectClass(item)">
                                <td>{{item.id}}</td>
                                <td>{{item.title}}</td>
                                <td>
                                    <el-button v-if="!item.select" size="mini" plain round  @click="selectTable(item)">选择</el-button>
                                    <el-button v-if="item.select" size="mini" type="primary" plain round  @click="selectTable(item)">取消</el-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <el-pagination background class="t-center"
                    @current-change="pageChange"
                    :page-size="page_size"
                    layout="prev, pager, next"
                    :total="total">
                </el-pagination>
                <div class="empty-box"></div>  
                <div class="end-btn-box">
                    <el-button type="primary" @click="closeDrawer">完 成</el-button>
                </div>
            </div>
        </el-drawer>
        <add-reference ref="addReferenceBox" :refereId="editId" v-on:add-suc="doneAdd"></add-reference>
    </div>
</template>
<script>
import {getReferencedList, searchReference} from '../service/api';
import AddReference from './add-reference.vue';
export default {
    components: {AddReference},
    props: ['refereIds'],
    data(){
        return  {
            drawer: false,
            editId: 0,
            tableData: [],
            selectIds: [],
            page_no: 1,
            page_size: 20,
            total: 0,
            searchKey: ''
        }
    },
    methods:{
        handleClose(done){
            // this.drawer = false;
            this.closeDrawer();
            done();
        },
        closeDrawer(){

            let seIds = this.selectIds.join(',');
            this.$emit('select-done', seIds);
            this.drawer = false;
        },
        selectTable(row){
            let testIndex = this.selectIds.indexOf(row.id);
            if( testIndex < 0){
                this.selectIds.push(row.id);
            }else{
                this.selectIds.splice(testIndex, 1);
            }
            // console.log(this.selectIds);
            this.initData();
        },
        tableSelectClass(row) {
            if (row.select) {
                return 'success-row';
            }else{
                return '';
            }
        },
        initData(){
            this.tableData.forEach(row => {
                console.log(this.selectIds);
                if(this.selectIds.indexOf(row.id) > -1){
                    row.select = true;
                }else{
                    row.select = false;
                }
            });
            // console.log(this.tableData);
            // this.$forceUpdate();
        },
        getList(page_no, page_size){
            if(!page_no){
                page_no = this.page_no;
            }
            if(!page_size){
                page_size = this.page_size;
            }

            getReferencedList(page_no, page_size)
                .then(res => {
                    let data = res.data;
                    this.total = data.total;
                    this.tableData = data.list;
                    this.page_no = data.page_no;
                    this.page_size = +data.page_size;
                    this.initData();
                }, err => {
                    this.$message.error(err.msg);
                });

        },
        pageChange(val){
            if(val == this.page_now) return;
            else{
                this.getList(val);
            }
        },
        openDrawer(){
            let idsNow = this.refereIds;
            // console.log(idsNow);
            if(idsNow && idsNow.length > 0){
                // console.log(idsNow.split(','), 111);
                // this.selectIds = idsNow.split(',');
                this.selectIds = [];
                let idAr = idsNow.split(',');
                for(let i = 0; i < idAr.length; i++){
                    this.selectIds.push(parseInt(idAr[i]));
                }
            }else{
                this.selectIds = [];
            }
            this.searchKey = '';
            // console.log(this.refereIds, this.selectIds);
            this.drawer = true;
            this.getList();
        },
        searchRef(){
            if(this.searchKey){
                searchReference({
                    key_word: encodeURIComponent(this.searchKey)
                }).then(res => {
                    let data = res.data;
                    this.total = data.total;
                    this.tableData = data.list;
                    this.page_no = data.page_no;
                    this.page_size = +data.page_size;
                    this.initData();
                }, err => {
                    this.$message.error(err.msg);
                });
            }
        },
        addReference(){
            // this.openAdd = true;
            this.$refs.addReferenceBox.openAdd();
        },
        doneAdd(){
            this.getList();
        }
    },
    mounted: function(){
        // this.getList();
    }
}
</script>
<style>
.top-ref-btns{
    margin: 10px auto;
    padding: 0 15px;
}
.top-ref-btns .el-input{
    float: left;
    width: 70%;
}
.top-ref-btns .add-ref-btn{
    float: right;
}
.select-list{
    margin: 5px auto;
    padding: 0 20px;
    line-height: 20px;
    font-weight: 400;
}

</style>