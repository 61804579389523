<template>
    <div class="top-search">
        <el-input @keydown.enter.native="doSearch" placeholder="请输入内容" v-model="serachInput" class="input-with-select">
            <el-select v-model="searchType" slot="prepend"  placeholder="请选择">
                <el-option label="化学名称" value="chemical_name"></el-option>
                <el-option label="CAS" value="cas_num"></el-option>
                <el-option label="EC" value="ec_num"></el-option>
            </el-select>
            <el-button type="primary" class="search-in-btn" slot="append" 
            icon="el-icon-search" @click="doSearch">搜索</el-button>
        </el-input>
        <el-button type="primary" class="out-btn" plain @click="reSearch">全部</el-button>
        <el-button type="primary" class="out-btn" icon="el-icon-plus" plain @click="addData">添加原料</el-button>
    </div>
</template>
<script>
import {searchProhibitedByKey} from '../service/api';
export default {
    props: ['country'],
    data(){
        return {
            serachInput: '',
            searchType: 'chemical_name',
        }
    },
    methods: {
        doSearch(){
            if(!this.serachInput){
                this.$message.error('请输入关键字！');
                return;
            }
            // console.log(this.country);
            let keyWord = this.serachInput.replace(/^[\s]|[\s*]$/g, '');
            searchProhibitedByKey({
                key_word: encodeURIComponent(keyWord),
                search_type: this.searchType,
                country: this.country
            }).then(res => {
                this.$emit('search-end', res.data);
            }, err => {
                this.$message.error(err.msg);
            })
        },
        reSearch(){
            this.serachInput = '';
            this.$emit('re-search');
        },
        addData(){
            this.$emit('add-data');
        }
    }
}
</script>
<style>
.top-search{
    margin: 0 auto 20px;
}
.input-with-select{
    width: 70%;
}
.el-select{
    width: 130px;
}
.search-in-btn i{
    color: #fff;
}
.list-search{
    float: right;
}
.draw-box{
    margin: 15px;
}
</style>