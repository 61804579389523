<template>
    <el-container>
        <el-header class="add-report-header">
            <el-page-header @back="goBack" content="新建报告"></el-page-header>
        </el-header>
        <el-main>
            <el-steps :active="step_number" finish-status="success">
                <el-step title="1、产品信息"></el-step>
                <el-step title="2、上传配方表"></el-step>
                <el-step title="3、核对配方表"></el-step>
                <el-step title="4、保存报告"></el-step>
            </el-steps>
            <div class="add-report">
                <div class="my-cut-block"></div>
                <!-- <div class="empty-box">
                    <el-alert v-if="errorNow"
                        :title="errorNow"
                        type="error">
                    </el-alert>
                </div> -->
                <div class="steps-box" v-if="step_number === 0">
                    <!-- <h4>1、填写产品信息</h4> -->
                    <el-form ref="form" :model="form" label-width="200px" class="my-form-box">
                        <el-form-item label="产品名称">
                            <el-input v-model="form.pro_name" placeholder="请输入产品名称"></el-input>
                        </el-form-item>
                        <el-form-item label="注册人/备案人名称">
                            <el-input v-model="form.sign_name" placeholder="请输入注册人/备案人名称"></el-input>
                        </el-form-item>
                        <el-form-item label="注册人/备案人地址">
                            <el-input v-model="form.sign_address" placeholder="请输入注册人/备案人地址"></el-input>
                        </el-form-item>
                        <el-form-item label="产品类型">
                            <el-radio-group v-model="form.pro_type">
                            <el-radio label="1">驻留类</el-radio>
                            <el-radio label="2">淋洗类</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="微生物检测">
                            <el-radio-group v-model="form.microorganism">
                            <el-radio label="1">不需要</el-radio>
                            <el-radio label="2">需要</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="适用部位">
                            <el-checkbox-group v-model="form.usefor">
                            <el-checkbox label="面部" name="type"></el-checkbox>
                            <el-checkbox label="颈部" name="type"></el-checkbox>
                            <el-checkbox label="全身" name="type"></el-checkbox>
                            <el-checkbox label="头发" name="type"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="产品使用方法">
                            <el-input type="textarea"
                            :rows="3" v-model="form.instructions" placeholder="请输入产品使用方法"></el-input>
                        </el-form-item>
                        <el-form-item label="产品总类">
                            <el-select v-model="form.pro_class_id" placeholder="请选择产品类别/目的">
                            <el-option v-for="item in pro_class_data" :key="item.id" 
                            :label="item.class_name" 
                            :value="item.id">
                                <span style="float: left">{{ item.class_name }}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.per_day_usefor + ' (' + item.per_day_unit + ')' }}</span>
                            </el-option>
                            <!-- <el-option label="防晒霜" value="beijing"></el-option> -->
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="日均使用量/驻留因子">
                            <el-select v-model="form.pro_daily_types" placeholder="请选择日均用量">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item> -->
                        <!-- <el-form-item label="即时配送">
                            <el-switch v-model="form.delivery"></el-switch>
                        </el-form-item>
                        <el-form-item label="活动形式">
                            <el-input type="textarea" v-model="form.desc"></el-input>
                        </el-form-item> -->
                        <el-form-item class="foot-bt-box">
                            <el-button type="primary" @click="saveReport">下一步</el-button>
                            <!-- <el-button>取消</el-button> -->
                        </el-form-item>
                    </el-form>
                </div>
                <div class="steps-box" v-if="step_number === 1">
                    <!-- <h4>2、上传配方表</h4> -->
                    <el-card class="box-card upload-box"  shadow="never">
                        <el-upload
                        class="upload-demo t-center upload-inner-box"
                        ref="uploadExcel"
                        :action="fileUploadURL" 
                        :on-change="checkFileType"
                        :on-error="uplaodError"
                        :on-success="uploadSuc"
                        :auto-upload="false"
                        :limit="1">
                        <!-- <i class="el-icon-upload"></i> -->
                        <!-- <div class="el-upload__text">将文件拖到此处，或<em>点击选择</em></div> -->
                        <el-button slot="trigger" size="medium" plain type="primary">选择配方表</el-button>
                        <div class="el-upload__tip" slot="tip">只能上传.xml/.xlsx文件，且不超过300kb（0.3M）</div>
                        </el-upload>
                        <el-row class="foot-bt-box t-center">
                            <el-button size="medium" type="success" @click="uploadNow">上传配方表</el-button>
                        </el-row>
                    </el-card>
                    <div class="my-cut-block"></div>
                    <el-row class="foot-bt-box t-center">
                        <el-button @click="lastStep">上一步</el-button>
                        <el-button type="primary" @click="endFormula">下一步</el-button>
                    </el-row>
                </div>
                <div class="steps-box" v-if="step_number === 2">
                    <!-- <h4>3、核对配方表</h4> -->
                    <div class="my-out-list">
                        <formula-list :listdata="formula_list"></formula-list>
                    </div>
                    <el-row class="foot-bt-box">
                        <el-button @click="lastStep">上一步</el-button>
                        <el-button type="primary" @click="confirmFormula">下一步</el-button>
                    </el-row>
                </div>
                <div class="steps-box" v-if="step_number === 3">
                    <!-- <h4>4、保存报告</h4> -->
                    <report-editer :reportHtml="reportText" :report_id="report_id" v-on:save-text="saveDone"></report-editer>
                    
                </div>

                <el-dialog
                title="重要提示"
                :visible.sync="dialogObj.dialogVisible"
                width="30%"
                :append-to-body="true"
                :show-close="false" center>
                    <span>{{dialogObj.confirmMsg}}</span>
                    <span slot="footer" class="dialog-footer">
                        <!-- <el-button @click="btnVisible(0)">取 消</el-button> -->
                        <el-button type="primary" @click="btnVisible(1)">确 定</el-button>
                    </span>
                </el-dialog>
            </div>
        </el-main>
        <!-- <el-footer>Footer</el-footer> -->
    </el-container>
</template>
<script>
import { getProConfig, createReport, getFormulaList, createReportText } from '../service/api';
import reportEditer from '../components/report-editer.vue';
import formulaList from '../components/formula-list.vue';
export default {
    components: { reportEditer, formulaList },
    data (){
        return {
            step_number: 0,
            report_id: 0,
            formula_id: 0,
            fileUploadURL: '/mnapi/upload',
            form: {
                pro_name: '',
                sign_name: '',
                sign_address: '',
                pro_type: '',
                microorganism: '',
                usefor: [],
                instructions: '',
                pro_class_id: null
                // pro_daily_types: ''
            },
            formula_file: [],
            appendix: '', //附录内容
            pro_class_data: [],
            errorMsgList: {
                pro_name: '请输入产品名称',
                sign_name: '请输入注册人/备案人名称',
                sign_address: '请输入注册人/备案人地址',
                pro_type: '请选择产品类型',
                microorganism: '请选择是否需要微生物检测',
                usefor: '请选择适用部位',
                instructions: '请输入附录内容',
                pro_class_id: '请选择产品总类'
            },
            formula_list: [],
            errorNow: null,
            reportText: '',
            dialogObj: {
                dialogVisible: false,
                confirmMsg: '',
                status: 0
            }
        }
    },
    methods: {
        lastStep(){
            if(this.step_number > 0){
                this.step_number -=1;
            }
        },
        goBack(){
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/report-list');
        },
        saveReport(){
            this.errorNow = null;
            let stepNow = this.step_number;
            if(stepNow > 0) return;

            let formData = this.form;
            let errorMsg = null;

            for(let k in formData){
                if(!formData[k]){
                    errorMsg = this.errorMsgList[k]
                    break;
                }
            }
            if(errorMsg){
                this.$message.error(errorMsg);
                this.errorNow = errorMsg;
            }
            // console.log(formData);
            createReport(formData).then(res => {
                // console.log('保存数据成功', res);
                if(res.data && res.data.report_id > 0){
                    this.report_id = res.data.report_id;
                    this.step_number +=1;
                }else{
                    console.log('创建失败！');
                }
            }, err => {
                console.log(err);
            })


        },
        clearFiles (){
            this.$refs.uploadExcel.clearFiles();
        },
        checkFileType (file){
            // console.log(file, files);
            let fileType = file.raw.type;
            // console.log(1);
            // if(files && files.length > 1){
            //     this.clearFiles();
            //     console.log(2);
            // }
            // if(file)
            if(file.size > 1024 * 1000){
                this.errorNow = '文件太大了，不能超过1M';
                this.clearFiles();
                return false;
            }
            // console.log(file.type, file.raw);
            // console.log(fileType);
            if(fileType != 'application/vnd.ms-excel' && fileType != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                this.errorNow = '请上传正确的Excel文件（.xls 或 .xlsx）';
                // console.log(this);
                this.clearFiles();
                return false;
            }
            return;
        },
        uplaodError (err){
            // console.log(err)
            this.$message.error(err);
        },
        uploadSuc(suc){
            // console.log(suc);
            this.$message({
                message: '上传成功！',
                type: 'success'
            });
            if(suc.code === 0 && suc.data){
                this.formula_id = suc.data.formula_id;
            }
        },
        uploadNow (){
            this.errorNow = null;
            let stepNow = this.step_number;
            if(stepNow !== 1) return;

            this.$refs.uploadExcel.submit();

        },
        endFormula(){
            if(this.report_id > 0 && this.formula_id > 0){

                getFormulaList({
                    report_id: this.report_id,
                    formula_id: this.formula_id
                }).then(res => {
                    if(res.code === 0 && res.data){
                        //前往下一步，并且展示配方表，在这里处理用户添加本企业用量
                        this.$message({
                            message: '保存成功！',
                            type: 'success'
                        });
                        this.formula_list = res.data;
                        this.step_number = 2;
                    }else{
                        this.errorNow = res.msg;
                    }
                    // console.log(res);
                }, err => {
                    this.$message.error(err.msg);
                })
            }
        },
        confirmFormula(){
            if(this.report_id > 0 && this.formula_id > 0){
                createReportText({
                    report_id: this.report_id,
                    formula_id: this.formula_id
                }).then(res => {
                    console.log(res);
                    if(res.code === 0 && res.data){
                        this.reportText = res.data;
                        this.step_number = 3;
                    }else{
                        this.errorNow = res.msg;
                    }
                }, err => { 
                    this.$notify.error({
                        title: '操作失败',
                        message: err.msg
                    });
                });

            }
        },
        btnVisible(btn){
            if(btn === 1){//点击确认按钮
                this.dialogObj.dialogVisible = false;
                this.step_number = 0;
            }else{
                this.dialogObj.dialogVisible = false;
            }
        },
        saveDone(){
            this.$emit('done-exit');
            this.goBack();
        }
    },
    mounted: function(){
        getProConfig().then(res => {
            // console.log(res);
            if(res.code === 0 && res.data){
                this.pro_class_data = res.data;
            }else{
                console.log('获取配置列表失败');
            }
        }, err => {
            console.log(err);
        });

        // console.log(baseUrl);upl
    }
}
</script>
<style>
.add-report{
    padding: 20px;
}
.steps-box{
    margin: 20px auto;
    /* max-width: 1000px; */
}
.my-form-box{
    max-width: 1000px;
}
</style>