<template>
    <el-container>
        <el-main>
            <el-form class="check-inputs">
               <el-row>
                    <el-col :span="8">
                        <el-input
                            type="textarea"
                            :rows="10"
                            placeholder="请输入原料名称/INCI，每行一个"
                            v-model="nameList">
                        </el-input>
                    </el-col>
                    <el-col :span="8">
                        <el-input
                            type="textarea"
                            :rows="10"
                            placeholder="请输入原料含量，与原料名称对应，每行一个"
                            v-model="valueList">
                        </el-input>
                    </el-col>
                    <el-col :span="8">
                        <el-input
                            type="textarea"
                            :rows="10"
                            placeholder="请输入标签内容，原料中有英文逗号[,]，请替换为|"
                            v-model="labelString">
                        </el-input>
                    </el-col>
                    <el-col class="no-padding-both" :span="24">
                        <el-alert title="提示：标签中原料间以半角逗号【 , 】分隔，部分原料名称含有半角逗号，请替换为竖线|方便识别。输入内容开始结尾请删除空格和换行。" type="warning" :closable="false"></el-alert>
                    </el-col>
                    <el-col class="no-padding-both" :span="24">
                        <el-button type="primary" @click="doCheck()">开始检查</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <div class="searchend-head">
                <span class="fl tit">检查结果</span>
                <!-- <el-button class="fr" type="success" icon="el-icon-circle-check" plain @click="checkUse()">用量检查</el-button> -->
                <!-- <el-button class="fr" type="primary" icon="el-icon-document-copy" plain @click="copyeUse()">复制用量</el-button> -->
                <!-- <el-button class="fr" type="success" @click="checkUse()">复制驻留用量</el-button> -->
            </div>
            <el-row class="row-infos">
                <el-col :span="12">
                    <el-alert :title="inLable_title" :type="inLable_type" :closable="false"></el-alert>
                </el-col>
                <el-col :span="12">
                    <el-alert :title="inNames_title" :type="inNames_type" :closable="false"></el-alert>
                </el-col>
            </el-row>
            <el-table :data="checkList" stripe show-summary>
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="name" label="原料名称"></el-table-column>
                <!-- <el-table-column prop="have" label="含量"></el-table-column> -->
                <el-table-column prop="sum_have" label="总含量"></el-table-column>
                <el-table-column prop="lable_num" label="标签顺序"></el-table-column>
                <el-table-column prop="is_in_lable" label="是否在标签中">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.is_in_lable">在</el-tag>
                        <el-tag type="danger" v-if="!scope.row.is_in_lable">不在</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="is_pair" label="标签顺序是否正确">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.is_pair">正确</el-tag>
                        <el-tag type="danger" v-if="!scope.row.is_pair">不正确</el-tag>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
    </el-container>
</template>
<script>
export default {
    data(){
        return {
            nameList: null,
            valueList: null,
            labelString: null,
            checkList: [],
            inLable_title: '',
            inLable_type: 'success',
            inNames_title: '',
            inNames_type: 'success',
        }
    },
    methods: {
        doCheck(){
            let name_array = []; //原料列表
            let value_array = []; //原料含量列表
            let label_array = []; //标签列表

            let name_have = []; //原料含量列表(去重)
            let name_obj = [];//去重后原料列表
            // let sort_list = []; //排序后的原料列表
            // let all_list = []; //完整的原料列表

            if(!this.nameList || !this.valueList || !this.labelString){
                this.$message.error('请输入完整数据，原料列表，含量列表，标签');
                return;
            }

            let name_input = this.nameList;
            let value_input = this.valueList;
            let label_input = this.labelString;

            let not_in_label = [];
            let not_in_names = [];

            name_input = name_input.replace(/\n$/, '');
            value_input = value_input.replace(/\n$/, '');
            label_input = label_input.replace(/\n$/, '');

            //原料列表生成
            if(name_input.search(/\n/) < 0){
                name_array = [name_input];
            }else{
                name_array = name_input.split(/\n/);
            }

            if(value_input.search(/\n/) < 0){
                value_array = [value_input];
            }else{
                value_array = value_input.split(/\n/);
            }

            if(label_input.search(/,/) < 0){
                label_array = [label_input.replace(/(^\s*)|(\s*$)/, '')];
            }else{
                label_array = label_input.split(/,/);
            }

            // console.log('lable', label_array);
            //标签数组替换|回半角逗号
            for(let i = 0; i < label_array.length; i++){
                label_array[i] = label_array[i].replace(/\|/, ',');
                label_array[i] = label_array[i].replace(/^(\s*)/g, '');
                label_array[i] = label_array[i].replace(/(\s*)$/g, '');
            }

            for(let i = 0; i < name_array.length; i++){
                let name = name_array[i];
                let value = value_array[i];
                // console.log(i, name_have.indexOf(name));

                //根据名称开始检查
                let nameIndex = name_have.indexOf(name);
                if(nameIndex < 0){
                    name_have.push(name);
                    // console.log(name);
                    let isInLabel = label_array.indexOf(name);
                    if(isInLabel < 0){
                        not_in_label.push(name);
                    }
                    name_obj.push({
                        name: name,
                        value: parseFloat(value),
                        is_in_lable: isInLabel > -1 ? true : false,
                        sum_have: parseFloat(value),
                        lable_num: isInLabel + 1,
                        is_pair: null
                    });
                }else{
                    // console.log(name_obj[name_have.indexOf(name)]);
                    name_obj[nameIndex].sum_have += parseFloat(value);
                }

            }

            // console.log(name_obj);
            //检查标签中原料是否在配方表中
            for(let m = 0; m < label_array.length; m++){
                let lb = label_array[m];

                if(name_array.indexOf(lb) < 0){
                    not_in_names.push(lb);
                }
            }


            //对去重后的数据进行排序
            let len = name_obj.length;
            for(let i = 0; i < len; i++){
                for(let j = 0; j < len - i; j++){
                    // console.log(name_obj[j], name_obj[j + 1]);
                    if(name_obj[j + 1] && name_obj[j + 1].sum_have){
                        if(name_obj[j].sum_have > name_obj[j + 1].sum_have){
                            let temp = name_obj[j+1];
                            name_obj[j+1] = name_obj[j];
                            name_obj[j] = temp;
                        }
                    }
                }
            }

            // console.log(name_obj);

            name_obj = name_obj.reverse();

            //检查标签顺序是否正确
            for(let i = 0; i < name_obj.length; i++){
                let ite = name_obj[i];
                let lable_index = label_array.indexOf(ite.name);
                // console.log(lable_index, i);
                if(ite.sum_have >= 1){
                    if(lable_index === i){
                        name_obj[i].is_pair = true;
                    }else{
                        name_obj[i].is_pair = false;
                    }
                }else{
                    name_obj[i].is_pair = true;
                }

            }

            this.checkList = name_obj;

            // console.log(not_in_names, not_in_label);
            if(not_in_names.length > 0){
                this.inNames_title = `标签中：${not_in_names.join('; ')} 共计${not_in_names.length} 种原料未出现在配方中！`;
                this.inNames_type = 'error';
            }else{
                this.inNames_title = '标签中原料均在配方列表中！';
                this.inNames_type = 'success';
            }
            if(not_in_label.length > 0){
                this.inLable_title = `配方中：${not_in_label.join('; ')} 共计${not_in_label.length} 种原料未出现在标签中！`;
                this.inLable_type = 'error';
            }else{
                this.inLable_title = '配方中原料均在标签列表中！';
                this.inLable_type = 'success';
            }

        }
    }
}
</script>
<style>
.check-inputs{
    margin: 0 auto;
    padding: 10px 0;
}
.check-inputs .el-col,
.row-infos .el-col{
    padding: 10px;
}
.check-inputs .el-col:first-child,
.row-infos .el-col:first-child{
    padding-left: 0;
}
.check-inputs .el-col:last-child,
.row-infos .el-col:last-child{
    padding-right: 0;
}
.no-padding-both{
    padding: 10px 0 !important;
}
</style>