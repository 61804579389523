<template>
    <el-container>
        <el-main>
            <div class="index-navs" v-for="item in menuList" :key="item.index">
                <h3>{{item.name}}</h3>
                <div class="index-menus">
                    <router-link v-for="nav in item.list" :key="nav.path" :to="{path: nav.path}">
                        <i :class="nav.icon"></i>
                        <span>{{nav.name}}</span>
                    </router-link>
                </div>
            </div>
        </el-main>
    </el-container>
</template>
<script>
import routerConfig from '../router-config';
export default {
    data(){
        return {
            menuList: routerConfig.pageGroup
        }
    } 
}
</script>
<style lang="less">
.index-navs{
    h3{
        margin: 10px auto;
        height: 35px;
        padding: 0 15px;
        font-weight: 400;
        border-left: solid 3px rgba(0, 168, 255,1.0);
        line-height: 35px;
        background: rgba(0, 168, 255, 0.05);
    }
    .index-menus{
        padding: 15px 25px;
        overflow: hidden;
        a{
            display: inline-block;
            margin: 15px;
            height: 50px;
            border-radius: 6px;
            overflow: hidden;
            i{
                display: block;
                float: left;
                width: 50px;
                font-size: 30px;
                background: rgba(0, 168, 255,0.1);
                color: rgba(0, 168, 255,1.0);
                text-align: center;
                line-height: 50px;
            }
            span{
                display: block;
                font-size: 18px;
                line-height: 50px;
                // color: rgba(0, 168, 255,1.0);
                color: #333;
                padding: 0 15px;
                float: left;
                background: rgba(220, 221, 225,0.2);
            }
        }
    }
}
</style>