<template>
    <el-dialog title="添加外部系统用户" center :visible.sync="openAddRefer" width="65%">
        <el-form :model="referData" :rules="rules">
            <el-form-item label="用户名" label-width="120px" prop="title">
                <el-input v-model="referData.user_name" :disabled="canEditUserName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" label-width="120px" prop="position">
                <el-input v-model="referData.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" label-width="120px" prop="url">
                <el-input v-model="referData.mobile" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="昵称/备注" label-width="120px" prop="remark">
                <el-input v-model="referData.nike_name" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelEdit">取 消</el-button>
            <el-button type="primary" @click="saveData">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import {addAdminUser} from '../service/api';
export default {
    props: ['refereId'],
    data(){
        return  {
            openAddRefer: false,
            canEditUserName: false,
            referData: {
                id: 0,
                user_name: '',
                password: '',
                mobile: '',
                nike_name: ''
            },
            rules: {
                user_name: [{
                    required: true, 
                    message: '请输入用户名', 
                    trigger: 'blur'
                },{ 
                    min: 6, 
                    max: 20, 
                    message: '用户名长度为6 ~ 20位', 
                    trigger: 'blur' 
                }],
                password: [{
                    required: true, 
                    message: '请输入初始密码', 
                    trigger: 'blur'
                },{ 
                    min: 10, 
                    max: 20, 
                    message: '初始密码长度为10 ~ 20位', 
                    trigger: 'blur' 
                }]
            }
        }
    },
    methods:{
        cancelEdit(){
            this.openAddRefer = false;
        },
        saveData(){
            addAdminUser({
                id: this.referData.id,
                user_name: encodeURIComponent(this.referData.user_name), 
                password: encodeURIComponent(this.referData.password),
                mobile: encodeURIComponent(this.referData.mobile),
                nike_name: encodeURIComponent(this.referData.nike_name)
            }).then(res => {
                // console.log(res);
                this.$message({
                    type: 'success',
                    message: res.msg
                });
                this.$emit('add-suc');
                this.cancelEdit();
            }, err => {
                this.$message.error(err.msg);
            });
        },
        openAdd(){
            this.openAddRefer = true;
        },
        editData(data){
            if(data && data.id > 0){
                this.referData = {
                    id: data.id,
                    user_name: data.user_name,
                    password: '',
                    mobile: data.mobile,
                    nike_name: data.nike_name,
                };
                this.canEditUserName = true;
            }else{
                this.canEditUserName = false;
            }
            this.openAdd();
        }
    }
}
</script>
<style>
</style>