<template>
    <el-container>
        <el-main>
            <h3></h3>
            <!-- <h3 v-if="country == 1">美国（US）INCI</h3>
            <h3 v-if="country == 2">欧盟 (EU) INCI</h3>
            <h3 v-if="country == 3">欧盟 (CA)) INCI</h3> -->
            <el-form label-width="100px">
                <el-form-item label="原料名称">
                    <el-input type="textarea" rows="4" v-model="keys" placeholder="请输入原料名称，每一行代表一个名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="doSearch">立即查询</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="dataList"
            stripe :row-class-name="tableRowClassName">
                <el-table-column prop="name" label="查询名称"></el-table-column>
                <el-table-column prop="id" label="匹配情况">
                    <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.is_check ? 'success' : 'danger'"
                    disable-transitions>{{checkNameBy(scope.row.check_key)}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="from_num" label="原始序号"></el-table-column>
                <el-table-column prop="eu_trivial" show-overflow-tooltip label="EU传统名称"></el-table-column>
                <el-table-column prop="english" show-overflow-tooltip label="英文通用名"></el-table-column>
                <el-table-column prop="french" show-overflow-tooltip label="法文名称"></el-table-column>
            </el-table>
        </el-main>
    </el-container>
</template>
<script>

import {checkCaGuide} from "../service/api";
export default {
    // props: ['country'],
    data(){
        return {
            dataList: [],
            keys: '',
        }
    }, 
    methods: {
        doSearch(){
            // console.log(1);
            let searchNames = this.keys;
            if(searchNames){
                let searchArry;
                // console.log(searchNames.search(/\n/));
                if(searchNames.search(/\n/) < 0){
                    searchArry = [searchNames];
                }else{
                    searchArry = searchNames.split(/\n/);
                }

                let subArr = [];

                for(let i = 0; i < searchArry.length; i++){
                    let ite = searchArry[i];
                    if(ite && ite !== ''){
                        // ite = ite.replace(/(\s)*(）|\))(\s)*/g, '）');
                        // ite = ite.replace(/(\s)*(（|\()(\s)*/g, '（');
                        ite = ite.replace(/(^\s*)|(\s*$)/g, '');
                        subArr.push(ite);
                    }
                }
                // console.log(subArr);
                checkCaGuide({
                    ca_names: encodeURIComponent(JSON.stringify(subArr))
                }).then(res => {
                    // let data = res.data;
                    this.dataList = res.data;
                    // console.log(res);
                }, err => {
                    this.$message.error(err.msg);
                });
            }else{
                this.$message.error(`请输入关键词！`);
            }
        },
        tableRowClassName({row}){
            // console.log(row);
            return row && row.is_check ? '' : 'none-pair';

        },
        checkNameBy(key){
            let str = '';
            switch(key){
                case 'eu_trivial':
                    str = 'EU传统名称';
                    break;
                case 'english':
                    str = '英文通用名';
                    break;
                case 'french':
                    str = '法文名称';
                    break;
                default:
                    str = '未找到';
                    break;
            
            }

            return str;
        }
    }
}
</script>