<template>
    <el-dialog title="加拿大沟通名称"
        :visible.sync="dialogVisible"
        width="40%" center>
        <el-form label-position="right" inline class="demo-table-expand">
            <el-form-item label="英文名称：">
                <span>{{ ca_obj.english }}</span>
            </el-form-item>
            <el-form-item label="EU传统名称：">
                <span>{{ ca_obj.eu_trivial }}</span>
            </el-form-item>
            <el-form-item label="法文名称：">
                <span>{{ ca_obj.french }}</span>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="closeCaDilog">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    data(){
        return {
            dialogVisible: false,
            ca_obj: {}
        }
    },
    methods:{
        closeCaDilog(){
            this.dialogVisible = false;
        },
        openCaDilog(data){
            this.ca_obj = data;
            this.dialogVisible = true;
        }
    }
}
</script>