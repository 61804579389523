<template>
    <el-dialog title="禁用原料编辑" center :visible.sync="openDialg" width="65%">
        <el-form :model="referData">
            <el-form-item label="化学名称" label-width="120px" prop="chemical_name">
                <el-input v-model="referData.chemical_name" placeholder="请输入化学名称" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="通用名称" label-width="120px" prop="guid_name">
                <el-input v-model="referData.guid_name" placeholder="请输入通用名称" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="CAS编号" label-width="120px" prop="cas_num">
                <el-input v-model="referData.cas_num" placeholder="请输入CAS编号" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="EC编号" label-width="120px" prop="ec_num">
                <el-input v-model="referData.ec_num" placeholder="请输入EC编号" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="原始序号" label-width="120px" prop="ec_num">
                <el-input v-model="referData.from_num" placeholder="请输入原始序号" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="国家/地区" label-width="120px" prop="country">
                <el-radio-group v-model="referData.country">
                    <el-radio :label="1">美国</el-radio>
                    <el-radio :label="2">欧盟</el-radio>
                    <el-radio :label="3">加拿大</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelEdit">取 消</el-button>
            <el-button type="primary" @click="saveData">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import {saveProhibited} from '../service/api';
export default {
    data(){
        return  {
            openDialg: false,
            referData: {
                id: 0,
                chemical_name: '',
                guid_name: '',
                cas_num: '',
                ec_num: '',
                from_num: '',
                country: 0
            }
        }
    },
    methods:{
        cancelEdit(){
            this.openDialg = false;
        },
        saveData(){
            
            if(!this.referData.chemical_name){
                this.$message.error(`请输入化学名称！`);
                return;
            }
            if(!this.referData.country){
                this.$message.error(`请选择国家/地区`);
                return;
            }

            saveProhibited({
                id: this.referData.id,
                chemical_name: encodeURIComponent(this.referData.chemical_name), 
                guid_name: this.referData.guid_name ? encodeURIComponent(this.referData.guid_name) : '',
                cas_num: encodeURIComponent(this.referData.cas_num),
                ec_num: encodeURIComponent(this.referData.ec_num),
                from_num: this.referData.from_num ? encodeURIComponent(this.referData.from_num) : '',
                country: this.referData.country
            }).then(() => {
                // console.log(res);
                this.$message({
                    message: '保存成功',
                    type: 'success'
                })
                this.$emit('add-suc');
                this.cancelEdit();
            }, err => {
                this.$message.error(err.msg);
            });
        },
        openAdd(){
            this.openDialg = true;
        },
        editData(data){
            if(data && data.id && data.id > 0){
                this.referData = data;
            }
            // console.log(this.referData);
            this.openDialg = true;
        }
    },
    mounted: function(){
        // this.getList();
    }
}
</script>
<style>
</style>