<template>
    <el-container>
        <el-header class="bg-gray no-padding">
            <el-menu
                :default-active="checkIndex"
                class="el-menu-demo bg-trans"
                mode="horizontal"
                @select="handleSelect">
                <el-menu-item index="1">美国/欧洲INCI名称</el-menu-item>
                <el-menu-item index="2">加拿大沟通名称</el-menu-item>
                <el-menu-item index="3">欧洲/加拿大禁用原料</el-menu-item>
                <el-menu-item index="4">加州65</el-menu-item>
                <el-menu-item index="5">欧洲/加拿大限用原料</el-menu-item>
                <el-menu-item index="6">其它数据核对</el-menu-item>
            </el-menu>
        </el-header>
        <el-main>
            <div class="inci-box" v-if="checkIndex == 1">
                <check-inci-names></check-inci-names>
            </div>
            <div class="inci-box" v-if="checkIndex == 2">
                <check-ca-names></check-ca-names>
            </div>
            <div class="inci-box" v-if="checkIndex == 3">
                <check-prohibited></check-prohibited>
            </div>
            <div class="inci-box" v-if="checkIndex == 4">
                <check-california></check-california>
            </div>
            <div class="inci-box" v-if="checkIndex == 5">
                <check-limited-use></check-limited-use>
            </div>
            <div class="inci-box" v-if="checkIndex == 6">
                <check-comdata></check-comdata>
            </div>
        </el-main>
    </el-container>
</template>
<script>
// import checkInciName from '../components/check-inci-name.vue';
import CheckInciNames from '../components/check-inci-names.vue';
import CheckCaNames from '../components/check-ca-names.vue';
import CheckProhibited from '../components/check-prohibited.vue';
import CheckCalifornia from '../components/check-california.vue';
import CheckLimitedUse from '../components/check-limited-use.vue';
import CheckComdata from '../components/check-comdata.vue';
export default {
    components: {
        CheckInciNames,
        CheckCaNames,
        CheckProhibited,
        CheckCalifornia,
        CheckLimitedUse,
        CheckComdata
    },
    data(){
        return {
            country: 1,
            checkIndex: '1'
        }
    },
    methods: {
        getSearch(){

        },
        handleSelect(key){
            // console.log(key, keyPath);
            // this.country = key;
            // this.$refs.inciList.getInciList(this.country);
            this.checkIndex = key;
        }
    },
    mounted: function(){
        // this.getUaInciList();
    }
}
</script>