<template>
    <el-dialog title="用量检查" center :visible.sync="openDialg" width="65%">
        <el-form :model="formData">
            <el-form-item label="用量" label-width="120px" prop="inci_name">
                <el-input v-model="formData.use_list" type="textarea" :rows="6" placeholder="按顺序输入配方表用量，每行一个" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="产品类型" label-width="120px" prop="country">
                <el-radio-group v-model="formData.use_type">
                    <el-radio :label="1">驻留类</el-radio>
                    <el-radio :label="2">淋洗类</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelEdit">取 消</el-button>
            <el-button type="primary" @click="saveData">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    data(){
        return {
            openDialg: false,
            formData : {
                use_list: [],
                use_type: 1
            }
        }
    },
    methods: {
        cancelEdit(){
            this.openDialg = false;
        },
        openCheck(){
            this.openDialg = true;
        },
        saveData(){

            let useList = this.formData.use_list;

            if(useList){

                let arrList;

                if(useList.search(/\n/) < 0){
                    arrList = [useList];
                }else{
                    arrList = useList.split(/\n/);
                }

                this.$emit('check-now', {
                    type: this.formData.use_type,
                    list: arrList
                });
                this.openDialg = false;
            }
        }
    }
}
</script>