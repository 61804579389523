<template>
    <el-container>
        <!-- <el-header class="bg-gray">
            <div class="my-header">
                <h3>化学品列表</h3>
            </div>
        </el-header> -->
        <el-main class="ref-draw-list">
            <el-tabs v-model="tabNow" @tab-click="tabsChange">
                <el-tab-pane label="化学品查询" name="search">
                    <el-form label-width="100px">
                        <el-form-item label="查询名称">
                            <el-input type="textarea" rows="6" v-model="inputNames" placeholder="请输入名称/关键字，每一行代表一个名称"></el-input>
                        </el-form-item>
                        <el-form-item label="查询字段">
                            <el-radio v-model="namesKey" label="cas">CAS号</el-radio>
                            <el-radio v-model="namesKey" label="cn_name">中文名</el-radio>
                            <el-radio v-model="namesKey" label="en_name">英文名</el-radio>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" :disabled="!inputNames" @click="searchNames">开始搜索</el-button>

                            <el-button type="warning" :disabled="!inputNames" @click="clearData">清空数据</el-button>
                        </el-form-item>
                    </el-form>
                    <div class="searchend-head">
                        <span class="fl tit">查询结果</span>
                    </div>
                    <el-table :data="namesList" stripe size="small">
                        <el-table-column label="搜索词" prop="search_word"></el-table-column>
                        <el-table-column label="搜索结果" width="100">
                            <template slot-scope="scope">
                            <el-tag
                            :type="scope.row.search_result ? 'success' : 'danger'"
                            disable-transitions>{{scope.row.search_result ? '成功': '未找到'}}</el-tag>
                        </template>
                        </el-table-column>
                        <el-table-column prop="cn_name">
                            <template slot="header">
                                <div class="tb-header-box">
                                    <label>中文名称</label>
                                    <el-button size="mini" class="table-head-btn" 
                                    icon="el-icon-copy-document" 
                                    type="primary" plain circle  @click="copyData('cn_name')"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cn_another_name" label="中文别名">
                            <template slot="header">
                                <div class="tb-header-box">
                                    <label>中文别名</label>
                                    <el-button size="mini" class="table-head-btn" 
                                    icon="el-icon-copy-document" 
                                    type="primary" plain circle  @click="copyData('cn_another_name')"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="en_name">
                            <template slot="header">
                                <div class="tb-header-box">
                                    <label>英文名称</label>
                                    <el-button size="mini" class="table-head-btn" 
                                    icon="el-icon-copy-document" 
                                    type="primary" plain circle  @click="copyData('en_name')"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="en_another_name">
                            <template slot="header">
                                <div class="tb-header-box">
                                    <label>英文别名</label>
                                    <el-button size="mini" class="table-head-btn" 
                                    icon="el-icon-copy-document" 
                                    type="primary" plain circle  @click="copyData('en_another_name')"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cas" width="200">
                            <template slot="header">
                                <div class="tb-header-box">
                                    <label>CAS号或序号</label>
                                    <el-button size="mini" class="table-head-btn" 
                                    icon="el-icon-copy-document" 
                                    type="primary" plain circle  @click="copyData('cas')"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="编辑/修改" width="100">
                            <template slot-scope="scope">
                                <el-button size="mini" icon="el-icon-edit" type="primary" plain round  @click="editRef(scope.row)">修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="全部化学品" name="alllist">
                    <div class="top-search">
                        <el-input @keydown.enter.native="doSearch" placeholder="请输入内容" v-model="key_word" class="input-with-select">
                            <el-select v-model="searchkey" slot="prepend"  placeholder="请选择">
                                <el-option label="CAS" value="cas"></el-option>
                                <el-option label="中文名称" value="cn_name"></el-option>
                                <el-option label="中文别名" value="cn_another_name"></el-option>
                                <el-option label="英文名称" value="en_name"></el-option>
                                <el-option label="英文别名" value="en_another_name"></el-option>
                            </el-select>
                            <el-button type="primary" class="search-in-btn" slot="append" 
                            icon="el-icon-search" @click="doSearch">搜索</el-button>
                        </el-input>
                        <el-button type="primary" plain @click="reSearch">全部</el-button>
                        <el-button type="primary" class="out-btn" icon="el-icon-plus" plain @click="addData">添加</el-button>
                    </div>
                    <el-table :data="dataList" stripe>
                        <el-table-column prop="id" label="ID" width="100"></el-table-column>
                        <el-table-column prop="cn_name" label="中文名称"></el-table-column>
                        <el-table-column prop="cn_another_name" label="中文别名"></el-table-column>
                        <el-table-column prop="en_name" label="英文名称"></el-table-column>
                        <el-table-column prop="en_another_name" label="英文别名"></el-table-column>
                        <el-table-column prop="cas" width="200" label="CAS号或序号"></el-table-column>
                        <el-table-column label="编辑/修改" width="100">
                            <template slot-scope="scope">
                                <el-button size="mini" icon="el-icon-edit" type="primary" plain round  @click="editRef(scope.row)">修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="empty-box"></div> 
                    <el-pagination background class="t-center"
                        @current-change="pageChange"
                        :page-size="page_size"
                        layout="prev, pager, next"
                        :total="total">
                    </el-pagination>
                </el-tab-pane>
            </el-tabs>
            
            <div class="empty-box"></div>
        </el-main>
        <edit-chemical v-on:fresh-page="freshData" ref="refEditChemical"></edit-chemical>
    </el-container>
</template>
<script>
import {getChemicalsList, searchChemicalsApi, searchChemicalsByNames} from '../service/api';

import EditChemical from '../components/chemicals/edit-chemical.vue';

export default {
    components: {
        EditChemical
    },
    data(){
        return{
            tabNow: 'search',
            dataList: null,
            page_size: 20,
            total: 0,
            page_no: 1,
            searchkey: 'cas',
            key_word: '',
            namesKey: 'cas',
            inputNames: '',
            namesList: null
        }
    },
    methods: {
        initData(obj){
            this.dataList = obj.list;
            this.total = obj.total;
            this.page_no = obj.page_no;
        },
        getList(page){
            let pageNo = page > 0 ? page : 1;

            getChemicalsList({
                page_no: pageNo,
                page_size: this.page_size
            }).then(res => {
                this.initData(res.data);
            }, err => {
                this.$message.error(err.msg);
            });

        },
        doSearch(page){
            let word = this.key_word;

            let pageNo = page > 0 ? page : 1;

            if(!word){
                this.$message.error('请输入关键字！');
                return;
            }

            searchChemicalsApi({
                key_word: encodeURIComponent(word),
                search_key: this.searchkey,
                page_no: pageNo,
                page_size: this.page_size
            }).then(res => {
                this.initData(res.data);
            }, err => {
                this.$message.error(err.msg);
            });

        },
        pageChange(page){
            if(page !== this.page_no){
                if(this.key_word){
                    this.doSearch(page);
                }else{
                    this.getList(page);
                }
            }else{
                this.$message.error('无效页码');
            }
        },
        addData(){
            this.editRef({id: 0});
        },
        editRef(obj){
            this.$refs.refEditChemical.open(obj);
        },
        freshData(){
            if(this.tabNow === 'search'){
                if(this.inputNames){
                    this.searchNames();
                }
            }else{
                if(this.key_word){
                    this.doSearch(this.page_no);
                }else{
                    this.getList(this.page_no);
                }
            }
        },
        reSearch(){
            this.key_word = '';
            this.getList(1);
        },
        tabsChange(tab){
            // console.log(tab);
            if(tab.name === 'alllist' && !this.dataList){
                this.getList();
            }
        },
        searchNames(){
            let names = this.inputNames;
            let search_key = this.namesKey;

            if(names){
                let searchArry;
                // console.log(searchNames.search(/\n/));
                if(names.search(/\n/) < 0){
                    searchArry = [names];
                }else{
                    searchArry = names.split(/\n/);
                }

                let subArr = [];

                for(let i = 0; i < searchArry.length; i++){
                    let ite = searchArry[i];
                    if(ite && ite !== ''){
                        if(search_key === 'cn_name'){
                            ite = ite.replace(/(\s)*(）|\))(\s)*/g, '）');
                            ite = ite.replace(/(\s)*(（|\()(\s)*/g, '（');
                        }
                        subArr.push(ite);
                    }
                }

                searchChemicalsByNames({
                    names: encodeURIComponent(JSON.stringify(subArr)),
                    search_key: search_key
                }).then(res => {
                    let data = res.data;

                    let reList = [];

                    for(let i = 0; i < data.length; i++){
                        let item = data[i];
                        let obj = {
                            search_word: item.search_word,
                            search_key: item.search_key,
                            search_result: item.search_result
                        }

                        if(item.search_result){
                            let list = item.result_list;

                            for(let k = 0; k < list.length; k++){
                                reList.push(Object.assign(obj,list[k]));
                            }

                        }else{
                            reList.push(obj)
                        }
                    }

                    this.namesList = reList;

                }, err => {
                    this.$message.error(err.msg);
                });
            }else{
                this.$message.error('请输入关键词！');
                return;
            }
        },
        clearData(){
            this.inputNames = null;
        },
        copyData(key){
            let data = this.namesList
            let str = '';
            const titles = {
                cn_name: '中文名称',
                cn_another_name: '中文别名',
                en_name: '英文名称',
                en_another_name: '英文别名',
                cas: 'CAS号或序号'
            }
            let total_tit = titles[key.toString()];
            if(data && data.length > 0){
                data.forEach((k) =>{
                    str += (k[key.toString()] ? k[key.toString()] : '') + '\n';
                });
                // console.log(str);
                this.$copyText(str)
                    .then(() => {
                        this.$message({
                            message: `${total_tit}复制成功`,
                            type: 'success'
                        });
                    }, err => {
                        this.$message.error(`复制失败，请手动复制 err=${err}`);
                    });
            }else{
                this.$message.error('无有效数据！');
            }
        }

    },
    mounted: function(){
    }
}
</script>
<style>
.top-search{
    margin: 0 auto 20px;
}
.top-search .el-input{
    margin-right: 20px;
}
.input-with-select{
    width: 70%;
}
.el-select{
    width: 130px;
}
.search-in-btn i{
    color: #fff;
}
.list-search{
    float: right;
}
.draw-box{
    margin: 15px;
}
</style>