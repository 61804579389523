<template>
    <el-container>
        <el-header class="add-report-header">
            <el-page-header @back="goBack" content="查看报告"></el-page-header>
        </el-header>
        <el-main>
            <report-editer :reportHtml="reportText" :report_id="report_id" v-on:save-text="saveDone"></report-editer>
        </el-main>
    </el-container>
</template>
<script>
import { getReportText } from '../service/api';
import reportEditer from '../components/report-editer.vue';
export default {
    components: { reportEditer },
    data(){
        return {
            reportText: '',
            report_id: 0,        
        }
    },
    methods: {
        goBack(){
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/report-list');
        },
        saveDone(){
            this.goBack();
        },
        getText(){
            if(this.report_id > 0){
                getReportText({
                    report_id: this.report_id
                }).then(res => {
                    // console.log(res);
                    this.reportText = res.data.text;
                }, err => {
                    this.$message.error(err.msg);
                });
            }
        }
    },
    mounted: function(){
        this.report_id = this.$route.params.reportId;
        // console.log(this.$route.params.reportId);
        this.getText();
    }
}
</script>