<template>
    <el-container>
        <el-main>
            <el-form class="check-inputs">
               <el-row>
                    <el-col :span="8">
                        <el-input
                            type="textarea"
                            :rows="10"
                            placeholder="请输入原料名称/INCI，每行一个"
                            v-model="nameList">
                        </el-input>
                    </el-col>
                    <el-col :span="8">
                        <el-input
                            type="textarea"
                            :rows="10"
                            placeholder="请输入CAS编号，与原料名称对应，每行一个"
                            v-model="casList">
                        </el-input>
                    </el-col>
                    <el-col :span="8">
                        <el-input
                            type="textarea"
                            :rows="10"
                            placeholder="请输入Function，与原料对应，每行一个"
                            v-model="functionList">
                        </el-input>
                    </el-col>
                    <el-col class="no-padding-both" :span="24">
                        <el-alert title="提示：原料名称、CAS编号、Function需要一一对应；每行一个" type="warning" :closable="false"></el-alert>
                    </el-col>
                    <el-col class="no-padding-both" :span="24">
                        <el-button type="primary" @click="doCheck()">开始检查</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <div class="searchend-head">
                <span class="fl tit">检查结果</span>
                <!-- <el-button class="fr" type="success" icon="el-icon-circle-check" plain @click="checkUse()">用量检查</el-button> -->
                <!-- <el-button class="fr" type="primary" icon="el-icon-document-copy" plain @click="copyeUse()">复制用量</el-button> -->
                <!-- <el-button class="fr" type="success" @click="checkUse()">复制驻留用量</el-button> -->
            </div>
            <!-- <el-row class="row-infos">
                <el-col :span="12">
                    <el-alert :title="inLable_title" :type="inLable_type" :closable="false"></el-alert>
                </el-col>
                <el-col :span="12">
                    <el-alert :title="inNames_title" :type="inNames_type" :closable="false"></el-alert>
                </el-col>
            </el-row> -->
            <el-table :data="resultList" stripe>
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="name" label="原料名称"></el-table-column>
                <!-- <el-table-column prop="have" label="含量"></el-table-column> -->
                <el-table-column prop="us_inci_pair" label="美国INCI">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.us_inci_pair">YES</el-tag>
                        <el-tag type="danger" v-if="!scope.row.us_inci_pair">NO</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="eu_inci_pair" label="欧洲INCI">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.eu_inci_pair">YES</el-tag>
                        <el-tag type="danger" v-if="!scope.row.eu_inci_pair">NO</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="cas" label="CAS编号"></el-table-column>
                <el-table-column prop="cas_pair" label="CAS正确性">
                    <template slot-scope="scope">
                        <el-tag :type="checkCasPair(scope.row.cas_pair)">{{scope.row.cas_msg}}</el-tag>
                        <!-- <el-tag type="danger" v-if="scope.row.cas_pair">{{scope.row.cas_msg}}</el-tag> -->
                    </template>
                </el-table-column>
                <el-table-column prop="ca_pair" label="加拿大沟通名称">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.ca_pair" @click="viewCaNames(scope.row)">有</el-tag>
                        <el-tag type="danger" v-if="!scope.row.ca_pair">无</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="fn_name" label="FUNCTION"></el-table-column>
                <el-table-column prop="fn_right" label="FN正确性">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.fn_right">YES</el-tag>
                        <el-tag type="danger" v-if="!scope.row.fn_right">NO</el-tag>
                    </template>
                </el-table-column>
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-row class="use-row">
                            <el-col :span="12">
                                <h3>美国</h3>
                                <el-form label-position="left" v-if="scope.row.us_data" inline class="demo-table-expand">
                                    <el-form-item label="INCI名称">
                                        <span>{{ scope.row.us_data.inci_name }}</span>
                                    </el-form-item>
                                    <el-form-item label="CAS编号">
                                        <span>{{ scope.row.us_data.cas }}</span>
                                    </el-form-item>
                                    <el-form-item label="原始序号">
                                        <span>{{ scope.row.us_data.from_num }}</span>
                                    </el-form-item>
                                    <el-form-item label="操作">
                                        <el-button size="mini" icon="el-icon-edit" type="primary" plain round  @click="editRef(scope.row.us_data)">修改</el-button>
                                    </el-form-item>
                                </el-form>
                                <el-alert v-if="!scope.row.us_data"
                                    title="未找到匹配数据"
                                    type="error">
                                </el-alert>
                            </el-col>
                            <el-col :span="12">
                                <h3>欧盟</h3>
                                <el-form label-position="left" v-if="scope.row.eu_data" inline class="demo-table-expand">
                                    <el-form-item label="INCI名称">
                                        <span>{{ scope.row.eu_data.inci_name }}</span>
                                    </el-form-item>
                                    <el-form-item label="CAS编号">
                                        <span>{{ scope.row.eu_data.cas }}</span>
                                    </el-form-item>
                                    <el-form-item label="原始序号">
                                        <span>{{ scope.row.eu_data.from_num }}</span>
                                    </el-form-item>
                                    <el-form-item label="操作">
                                        <el-button size="mini" 
                                        icon="el-icon-edit" type="primary" plain round  
                                        @click="editRef(scope.row.eu_data)">修改</el-button>
                                    </el-form-item>
                                </el-form>
                                <el-alert v-if="!scope.row.eu_data"
                                    title="未找到匹配数据"
                                    type="error">
                                </el-alert>
                            </el-col>
                        </el-row>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <edit-uainci ref="EditUaInci" v-on:add-suc="doneAdd"></edit-uainci>
        <view-canames ref="viewCaDialog"></view-canames>
    </el-container>
</template>
<script>
import {postReviewFormula} from "../service/api";

import EditUainci from '../components/edit-uainci.vue';
import ViewCanames from '../components/view-canames.vue';
export default {
    components: {EditUainci, ViewCanames},
    data(){
        return {
            nameList: null,
            casList: null,
            functionList: null,
            resultList: [],
            // inLable_title: '',
            // inLable_type: 'success',
            // inNames_title: '',
            // inNames_type: 'success',
            // loading: true
        }
    },
    methods: {
        checkCasPair(pair){
            let tp = '';

            switch (pair){
                case 0:
                    tp = 'warning';
                    break;

                case 1:
                    tp = 'danger';
                    break;

                case 2:
                    tp = 'danger';
                    break;
                
                case 3:
                    tp = 'success';
                    break;

                case 4:
                    tp = 'danger';
                    break;

                default:
                    tp = 'warning';
                    break;

            }

            return tp;
        },
        viewCaNames(data){
            // let str = `英文名称：${data.ca_english} \n, 欧洲标准名称：${data.ca_eu_trivial} \n, 法文名称：${data.ca_french}`;
            // this.$alert(str, '加拿大沟通名称', {
            //     confirmButtonText: '关闭',
            //     callback: () =>{
            //         // console.log(ev);
            //     }
            // })
            this.$refs.viewCaDialog.openCaDilog(data.ca_obj);
        },
        doCheck(){
            let inci_array;
            let cas_array;
            let functions_array;
            if(!this.nameList || !this.casList || !this.functionList){
                this.$message.error('请输入完整数据，原料列表，含量列表，标签');
                return;
            }

            let name_input = this.nameList;
            let cas_input = this.casList;
            let functions_input = this.functionList;

            name_input = name_input.replace(/\n$/, '');
            cas_input = cas_input.replace(/\n$/, '');
            functions_input = functions_input.replace(/\n$/, '');

            //原料列表生成
            if(name_input.search(/\n/) < 0){
                inci_array = [name_input];
            }else{
                inci_array = name_input.split(/\n/);
            }

            if(cas_input.search(/\n/) < 0){
                cas_array = [cas_input];
            }else{
                cas_array = cas_input.split(/\n/);
            }

            if(functions_input.search(/\n/) < 0){
                functions_array = [functions_input];
            }else{
                functions_array = functions_input.split(/\n/);
            }

            // console.log(inci_array, cas_array, functions_array);
            postReviewFormula({
                incis: encodeURIComponent(JSON.stringify(inci_array)),
                cases: encodeURIComponent(JSON.stringify(cas_array)),
                functions: encodeURIComponent(JSON.stringify(functions_array))
            }).then(res => {
                // console.log(res);
                // this.loading = false;
                this.resultList = res.data;
            })

        },
        editRef(ite){
            // console.log(ite);
            this.$refs.EditUaInci.editData(ite);
        },
        doneAdd(){

        }
    }
}
</script>
<style>
.check-inputs{
    margin: 0 auto;
    padding: 10px 0;
}
.check-inputs .el-col,
.row-infos .el-col{
    padding: 10px;
}
.check-inputs .el-col:first-child,
.row-infos .el-col:first-child{
    padding-left: 0;
}
.check-inputs .el-col:last-child,
.row-infos .el-col:last-child{
    padding-right: 0;
}
.no-padding-both{
    padding: 10px 0 !important;
}
.demo-table-expand .el-form-item{
    width: 100%;
}
</style>