<template>
    <el-container>
        <el-header class="bg-gray">
            <div class="my-header">
                <h3>欧美加审核报告</h3>
                <el-button type="primary" class="right-btn" icon="el-icon-plus" @click="newEuaReport">新建</el-button>
            </div>
        </el-header>
        <el-main>
            <el-table :data="dataList" stripe>
                <el-table-column prop="report_no" label="编号">
                </el-table-column>
                <el-table-column prop="report_title" label="产品名称">
                </el-table-column>
                <el-table-column prop="report_title_cn" label="产品名称（中文）">
                </el-table-column>
                <el-table-column prop="barcode" label="Product Barcode">
                </el-table-column>
                <el-table-column label="更多" width="100">
                    <template slot-scope="scope">
                        <el-button class="vtable-btn" size="mini" icon="el-icon-download" type="primary" plain round :disabled="scope.row.report_status < 3"  @click="downloadReport(scope.row.id)">下载</el-button>
                        <el-button class="vtable-btn" size="mini" icon="el-icon-edit" type="warning" plain round  @click="editReport(scope.row)">修改</el-button>
                        <!-- <el-button class="vtable-btn" size="mini" icon="el-icon-delete" type="danger" plain round  @click="delData(scope.row)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <el-footer>
            <el-pagination background class="t-center"
                @current-change="pageChange"
                :page-size="page_size"
                :current-page="current_page"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </el-footer>
        <!-- <el-dialog
        title="新建报告"
        :visible.sync="openNewReport"
        :fullscreen="true">
            <add-report v-on:done-exit="editDone"></add-report>
        </el-dialog> -->
        <!-- <view-review-remark ref="ViewReviewDetail" v-on:edit-data="editRemark"></view-review-remark> -->
        <edit-eua-report ref="EditEuaReport" v-on:edit-done="editDone" v-on:download-report="downloadReport"></edit-eua-report>
    </el-container>
</template>

<script>
import EditEuaReport from '../components/edit-eua-report.vue';
import {getEuaReportList} from '../service/api';
export default {
    components: {EditEuaReport},
    data (){
        return {
          page_no: 1,
          page_size: 20,
          page_now: 1,
          current_page: 1,
          total: 0,
          dataList : [],
          showEdit: false
        }
    },
    methods:{
        getList(page_no){

            if(!page_no){
                page_no = this.page_no;
            }

            let page_size = this.page_size;
            getEuaReportList({
                page_no: page_no , 
                page_size: page_size
            }).then(res => {
                if(res.code === 0){
                    let data = res.data;
                    this.page_no = data.page_no;
                    this.page_now = data.page_no;
                    this.total = data.total;
                    this.dataList = data.list;
                }else{
                    this.$message.error(res.msg);
                }
            }, err => {
                this.$message.error(err.msg);
            });
        },
        pageChange(val){
            if(val == this.page_now) return;
            else{
                this.getList(val);
            }
        },
        editReport(data){
            this.$refs.EditEuaReport.open(data.id, data);
        },
        editDone(){
            this.getList();
        },
        newEuaReport(){
            this.$refs.EditEuaReport.open(0);
        },
        downloadReport(id){
            if(id && id > 0){
                window.open(`/mnapi/downEuaReport/${id}`);
            }else{
                this.$message.error('未找到有效的报告！');
            }
        },
        delData(data){
            if(data && data.id > 0){
                let msg = `确定要删除【${data.inci}】的数据吗？删除后将无法恢复！`;
                this.$confirm(msg, '重要提示', {
                    confirmButtonText: `确定删除`,
                    cancelButtonText: `取消`,
                    type: `warning`
                }).then(() =>{
                    // delReviewRemark({
                    //     id: data.id
                    // }).then(res => {
                    //     this.$message({
                    //         message: res.msg,
                    //         type: `success`
                    //     });
                    //     this.getList();
                    // }, err => {
                    //     this.$message.error(err.msg);
                    // })
                }).catch(() => {

                });
            }else{
                this.$message.error(`删除失败，数据不存在`);
            }
        }
    },
    mounted: function(){
        this.getList();
    }
}
</script>
<style>
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 150px;
    font-size: 12px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
.vtable-btn{
    display: block !important;
    margin: 10px auto auto !important;
}
</style>