import Vue from 'vue';
import Router from 'vue-router';

import localFn from './service/com';

//页面控制器从这里开始
// import App from './App.vue';
import Home from './pages/Home';
import Index from './pages/Index';
import NotFound from './pages/404';

import IECICList from './pages/IecicList';

import EditRawFormula from './pages/EditRawformula';
import Login from './pages/Login';
import ReportList from './pages/ReportList';
import SearchList from './pages/Search.vue';
import CreateReport from './pages/CreateReport';
import ViewReport from './pages/ViewReport';
import UAInciIndex from './pages/UAInci';

import References from './pages/References';

import InciList from './pages/InciList';

import ProhibitedList from './pages/ProhibitedList';

import LimitedList from './pages/LimitedList';

import ComCheckList from './pages/ComCheckList';

import CheckLabel from './pages/CheckLabel';

import ReviewFormula from './pages/ReviewFormula';

import CosingList from './pages/CosingList';

import AdminUsers from './pages/AdminUsers';

import CnProhibitedList from './pages/CnProhibitedList';

import ReviewRemark from './pages/ReviewRemark';

import EuaReportList from './pages/EuaReportList';

import RiskRemarkList from './pages/RiskRemarkList.vue';

import ChemicalsList from './pages/Chemicals.vue';

//解决重复点击报错问题
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

Vue.use(Router);

const router = new Router({
        routes: [{
        path: '/', // 首页，主要路由
        name: '',
        component: Home,
        meta: {
            title: '首页',
            needLogin: true
        },
        children: [{
                path: '/index',
                name: '登录成功',
                component: Index,
                meta: {
                    title: '后台首页',
                    needLogin: true
                }
            },
            {
                path: '/iecic-list',
                name: '原料列表',
                component: IECICList,
                meta: {
                    title: '列表页面',
                    needLogin: true
                }
            }, 
            {
                path: '/references',
                name: '参考文献',
                component: References,
                meta: {
                    title: '参考文献',
                    needLogin: true
                }
            },
            {
                path: '/edit-rawformula/:rawId',
                name: '编辑原料',
                component: EditRawFormula,
                meta: {
                    title: '编辑原料',
                    needLogin: true
                }
            },
            {
                path: '/report-list',
                name: '报告列表',
                component: ReportList,
                meta: {
                    title: '报告列表',
                    needLogin: true
                }
            }, 
            {
                path: '/search',
                name: '原料查询',
                component: SearchList,
                meta: {
                    title: '原料查询',
                    needLogin: true
                }
            }, 
            {
                path: '/create-report',
                name: '新建报告',
                component: CreateReport,
                meta: {
                    title: '新建报告',
                    needLogin: true
                }
            },
            {
                path: '/view-report/:reportId',
                name: '查看报告',
                component: ViewReport,
                meta: {
                    title: '查看报告',
                    needLogin: true
                }
            },
            {
                path: '/uainci',
                name: '欧美数据',
                component: UAInciIndex,
                meta: {
                    title: '欧美数据',
                    needLogin: true
                }
            }, 
            {
                path: '/uainci-list',
                name: '欧美INCI数据',
                component: InciList,
                meta: {
                    title: '欧美INCI数据',
                    needLogin: true
                }
            }, {
                path: '/uaprohibited-list',
                name: '禁用原料',
                component: ProhibitedList,
                meta: {
                    title: '禁用原料',
                    needLogin: true
                }
            }, {
                path: '/cnprohibited-list',
                name: '禁用原料',
                component: CnProhibitedList,
                meta: {
                    title: '中国禁用原料',
                    needLogin: true
                }
            }, {
                path: '/limited-list',
                name: '限用原料',
                component: LimitedList,
                meta: {
                    title: '限用原料',
                    needLogin: true
                }
            }, {
                path: '/com-check-list',
                name: '其它数据',
                component: ComCheckList,
                meta: {
                    title: '其它数据',
                    needLogin: true
                }
            }, {
                path: '/check-label',
                name: '标签审核',
                component: CheckLabel,
                meta: {
                    title: '标签审核',
                    needLogin: true
                }
            }, {
                path: '/review-formula',
                name: '配方审核',
                component: ReviewFormula,
                meta: {
                    title: '配方审核',
                    needLogin: true
                }
            }, {
                path: '/cosing-list',
                name: 'Cosing数据',
                component: CosingList,
                meta: {
                    title: 'Cosing数据',
                    needLogin: true
                }
            }, {
                path: '/admin-users',
                name: '用户管理',
                component: AdminUsers,
                meta: {
                    title: '用户管理',
                    needLogin: true
                }
            }, {
                path: '/review-remark',
                name: '审核汇总',
                component: ReviewRemark,
                meta: {
                    title: '审核汇总',
                    needLogin: true
                }
            }, {
                path: '/eua-report',
                name: '配方审核报告',
                component: EuaReportList,
                meta: {
                    title: '配方审核报告',
                    needLogin: true
                }
            }, {
                path: '/risk-evaluate-list',
                name: '风险物质',
                component: RiskRemarkList,
                meta: {
                    title: '风险物质',
                    needLogin: true
                }
            }, {
                path: '/chemicals-list',
                name: '化学品',
                component: ChemicalsList,
                meta: {
                    title: '化学品',
                    needLogin: true
                }
            }
        ]
    },
    {
        path: '/login',
        name: '登录',
        component: Login
    },
    {
        path: '*',
        name: '404',
        component: NotFound
    }
]});

router.beforeEach((to, from, next) => {
    let isLogin = localFn.getStorage('userLoginNow');
    // console.log(isLogin);
    // console.log(to, from);
    if (to.meta.needLogin) { // 判断该路由是否需要登录权限
        if (isLogin) { // 判断是否已经登录
            // console.log(222);
            next()
        } else {
            // console.log(11);
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                } 
            })
        }
    } else {
        next()
    }
})

export default router;