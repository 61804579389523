<template>
    <el-drawer :title="Title" :with-header="false" :visible.sync="showViews" 
    :before-close="handleClose" :append-to-body="true" size="80%">
        <div class="my-draw-header">
            <div class="my-draw-title">{{Title}}</div>
            <div class="my-draw-edit-btns">
                <el-button type="warning" @click="close" size="medium" round icon="el-icon-close">关闭</el-button>
            </div>
        </div>
        <div class="view-draw-box">
            <el-form :model="formData" label-width="120px">
                <el-form-item label="风险物质名称">
                    <el-input v-model="formData.name" @change="inputChange" placeholder="请输入风险物质名称"></el-input>
                </el-form-item>
                <el-form-item label="英文名称">
                    <el-input v-model="formData.en_name" @change="inputChange" placeholder="请输入物质英文名称"></el-input>
                </el-form-item>
                <el-form-item label="风险等级">
                    <el-input v-model="formData.level" @change="inputChange" placeholder="请输入风险等级"></el-input>
                </el-form-item>
                <el-form-item label="评估备注" prop="url">
                    <el-input type="textarea" :rows="5" v-model="formData.evaluate" @change="inputChange" placeholder="请输入风险无助评估备注"></el-input>
                </el-form-item>
                <el-form-item label="匹配关键词">
                    <el-input v-model="formData.search_keys" @change="inputChange" placeholder="请输入匹配关键词，多个词请用半角逗号分隔"></el-input>
                </el-form-item>
                <el-form-item label="更多备注" prop="url">
                    <el-input type="textarea" :rows="5" v-model="formData.remark_more" @change="inputChange" placeholder="请输入更多备注内容"></el-input>
                </el-form-item>
                <el-form-item label="安全用量">
                    <el-input v-model="formData.safe_content" @change="inputChange" placeholder="请输入安全用量"></el-input>
                </el-form-item>
            </el-form>
            <div class="empty-box"></div>
            <div class="empty-box border-top"></div>
            <div class="dialog-footer t-center">
                <el-button @click="cancelEdit">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </div>
        </div>
    </el-drawer>
</template>
<script>
import {saveRiskData} from '../service/api';
export default {
    data(){
        return  {
            showViews: false,
            Title: '编辑/添加风险物质',
            formIsChange: false,
            formData: {
                id: 0,
                name: '',
                en_name: '',
                evaluate: '',
                level: '',
                search_keys: '',
                remark_more: '',
                safe_content: '',
            }
        }
    },
    methods:{
        close(){
            this.showViews = false;
        },
        saveData(){
            saveRiskData({
                id: this.formData.id,
                name: encodeURIComponent(this.formData.name), 
                en_name: encodeURIComponent(this.formData.en_name),
                evaluate: encodeURIComponent(this.formData.evaluate),
                level: encodeURIComponent(this.formData.level),
                search_keys: encodeURIComponent(this.formData.search_keys),
                remark_more: encodeURIComponent(this.formData.remark_more),
                safe_content: encodeURIComponent(this.formData.safe_content)
            }).then(() => {
                // console.log(res);
                this.$message({
                    message: `保存成功`,
                    type: 'success'
                });
                this.formIsChange = false;
                this.$emit('add-suc');
                this.close();
            }, err => {
                this.$message.error(err.msg);
            });
        },
        handleClose(done){
            if(this.formIsChange){
                this.$confirm(`修改内容尚未保存，是否要保存？`, '重要提示', {
                    confirmButtonText: `保存并关闭`,
                    cancelButtonText: `不保存`,
                    type: 'warning',
                    center: true
                })
                    .then(() => {
                        this.saveData();
                        done();
                    }).catch(() => {
                        done();
                    });
            }else{
                done();
            }
        },
        inputChange(value){
            if(value){
                this.formIsChange = true;
            }
        },
        open(data){
            if(data && data.id > 0){
                // this.formData.id = id;
                this.formData = data;
            }else{
                this.formData = {
                    id: 0,
                    name: '',
                    en_name: '',
                    evaluate: '',
                    level: '',
                    search_keys: '',
                    remark_more: '',
                    safe_content: '',
                }
            }

            this.showViews = true;
        }
    },
    mounted: function(){
        // this.getList();
    }
}
</script>
<style>
</style>