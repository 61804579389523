<template>
<el-container>
    <el-header>
        <h1>安颜科技</h1>
    </el-header>
    <el-main>
    <div class="login-box">
        <el-card class="box-card">
            <h3 class="t-center">化妆品备案管理系统</h3>
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="密码登录" name="first">
                    <div class="empty-box"></div>
                    <el-form ref="form" :model="loginForm" label-width="80px">
                        <el-form-item label="登录名">
                            <el-input v-model="loginForm.user_name" placeholder="请输入用户名"></el-input>
                        </el-form-item>
                        <el-form-item label="密  码">
                            <el-input v-model="loginForm.password" type="password" placeholder="请输入登录密码"></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="empty-box">
                        <el-alert v-if="errorNow"
                            :title="errorNow"
                            type="error">
                        </el-alert>
                    </div>
                    <el-row class="foot-bt-box t-center">
                        <el-button type="primary" @click="onLogin">登 录</el-button>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="手机号登录" name="second">
                    <div class="empty-box"></div>
                    <el-form ref="form" :model="loginForm" label-width="80px">
                        <el-form-item label="手机号">
                            <el-input v-model="loginForm.mobile" placeholder="请输入11位手机号">
                                <el-button slot="append" class="in-input-btn" @click="getSMSCode">获取验证码</el-button>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="验证码">
                            <el-input v-model="loginForm.sms_code" type="password" placeholder="请输入短信验证码"></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="empty-box">
                        <el-alert v-if="errorNow"
                            :title="errorNow"
                            type="error">
                        </el-alert>
                    </div>
                    <el-row class="foot-bt-box t-center">
                        <el-button type="primary" :loading="isLogin" @click="SMSLogin">{{btnText}}</el-button>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
            
        </el-card>
    </div>
    </el-main>
    <el-footer class="footer">
        <p>© 2021 杭州安颜科技有限公司<a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备18036615号-1</a></p>
    </el-footer>
</el-container>
</template>
<script>
import { postUserLogin } from '../service/api';
import localFn from '../service/com.js';
export default {
    data() {
        return{
            activeName: 'first',
            loginForm: {
                user_name: '',
                password: '',
                mobile: '',
                sms_code: ''
            },
            errorNow: '',
            isLogin: false,
            btnText: '立即登录'
        }
    },
    methods: {
        getSMSCode(){
            let phone = this.loginForm.mobile;

            if(!phone){
                this.$message.error('请输入11位手机号码！');
                return;
            }

        },
        SMSLogin(){
            let sms = this.loginForm.sms_code;
            let phone = this.loginForm.mobile;

            if(!phone){
                this.$message.error('请输入11位手机号码！');
                return;
            }

            if(!sms){
                this.$message.error('请输入短信验证码！');
                return;
            }

            this.btnText = '正在登录';
            this.isLogin = true;

        },
        onLogin(){
            if(!this.loginForm.user_name){
                this.errorNow = '请输入用户名';
                return;
            } 
            if(!this.loginForm.password){
                this.errorNow = '请输入登录密码';
                return;
            }

            // console.log(this.$getRsaCode(this.loginForm.user_name));
            // console.log(this.$getRsaCode(this.loginForm.password));
            // return;
            postUserLogin({
                user_name: this.$getRsaCode(this.loginForm.user_name),
                password: this.$getRsaCode(this.loginForm.password)
            }).then(res => {
                // console.log(res);
                if(res.code === 0){
                    localFn.setStorage('userLoginNow', true);
                    // console.log(this);
                    // let obj = localFn.getStorage('userLoginNow');
                    // console.log(obj);
                    let query = this.$route.query;
                    // console.log(query);
                    if(query && query.redirect){
                        let goUrl = decodeURIComponent(query.redirect);
                        this.$router.push(goUrl);
                    }else{
                        this.$router.push('/index');
                    }
                }
            }, err => {
                console.log(err);
            });

        }
    }
}
</script>
<style>
.in-input-btn{
    color: #409EFF !important;
}
.footer p{
    font-size: 12px;
    color: #999;
    text-align: center;
}
.footer p a{
    color: #888;
    margin: auto 10px;
    text-decoration: none;
}
</style>