<template>
    <el-container>
        <el-header class="bg-gray">
            <div class="my-header">
                <h3>中国禁用原料</h3>
            </div>
        </el-header>
        <el-main>
            <div class="cn-limited">
                <div class="top-search">
                    <el-input @keydown.enter.native="doSearch" placeholder="请输入内容" v-model="keyWord" class="input-with-select">
                        <el-button type="primary" class="search-in-btn" slot="append" 
                        icon="el-icon-search" @click="doSearch">搜索</el-button>
                    </el-input>
                    <el-button type="primary" class="out-btn" plain @click="reSearch">全部</el-button>
                    <el-button type="primary" class="out-btn" icon="el-icon-plus" plain @click="addData">添加原料</el-button>
                </div>
                <el-table :data="list" stripe>
                    <!-- <el-table-column prop="from_num" label="序号" width="100"></el-table-column> -->
                    <el-table-column prop="cn_name" label="中文名称"></el-table-column>
                    <el-table-column prop="en_name" label="英文名称"></el-table-column>
                    <el-table-column prop="cas" show-overflow-tooltip label="CAS号"></el-table-column>
                    <el-table-column prop="version" label="版本">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.version === 2">2021年</el-tag>
                            <el-tag type="info" v-if="scope.row.version === 1">2015年</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="version_list" label="版本历史"></el-table-column>
                    <el-table-column label="编辑/修改" width="100">
                        <template slot-scope="scope">
                            <el-button size="mini" icon="el-icon-edit" type="primary" plain round  @click="editRef(scope.row)">修改</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="empty-box"></div> 
                <el-pagination background class="t-center"
                    @current-change="pageChange"
                    :page-size="page_size"
                    layout="prev, pager, next"
                    :total="total">
                </el-pagination>
                <div class="empty-box"></div>
            </div>
        </el-main>
    </el-container>
</template>

<script>
import {getCnLimitedList, searchCnLimited} from '../service/api';
export default {
    data(){
        return {
            list: [],
            version: 2,
            keyWord: '',
            total: 0,
            page_no: 1,
            page_size: 20,
        }
    },
    methods: {
        getList(){
            getCnLimitedList({
                version: 2,
                page_no: this.page_no,
                page_size: this.page_size,
            }).then(res => {
                if(res.code === 0){
                    let data = res.data;
                    this.total = data.total;
                    this.list = data.list;
                }else{
                    this.$message.error(res.msg);
                }
            }, err => {
                this.$message.error(err.msg);
            });
        },
        pageChange(page){
            if(page != this.page_no) {
                this.page_no = page;
                if(this.keyWord){
                    this.doSearch();
                }else{
                    this.getList();
                }
            }
        },
        doSearch(){
            searchCnLimited({
                key_word: this.keyWord,
                page_no: this.page_no,
                page_size: this.page_size
            }).then(res => {
                if(res.code === 0){
                    let data = res.data;
                    this.total = data.total;
                    this.list = data.list;
                }else{
                    this.$message.error(res.msg);
                }
            }, err => {
                this.$message.error(err.msg);
            });
        },
        reSearch(){
            this.keyWord = '';
            this.page_no = 1;
            this.getList();
        },
        addData(){//添加原料
            this.$message.error('暂不支持');
        },
        editRef(){//修改原料
            this.$message.error('暂不支持');
        }
    },
    mounted: function(){
        console.log(1);
        this.getList();
    }
}
</script>
<style>
.demo-table-expand {
    font-size: 0;
}
.demo-table-expand label {
    width: 150px;
    font-size: 12px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>