<template>
    <div class="top-search">
        <el-input @keydown.enter.native="doSearch" placeholder="请输入内容" v-model="serachInput" class="input-with-select">
            <el-button type="primary" class="search-in-btn" slot="append" 
            icon="el-icon-search" @click="doSearch">搜索</el-button>
        </el-input>
        <el-button type="primary" class="out-btn" plain @click="reSearch">全部</el-button>
        <!-- <el-button type="primary" class="out-btn" icon="el-icon-plus" plain @click="addData">添加数据</el-button> -->
    </div>
</template>
<script>
export default {
    data(){
        return {
            serachInput: ''
        }
    },
    methods: {
        doSearch(){
            if(!this.serachInput){
                this.$message.error('请输入关键字！');
                return;
            }
            let keyWord = this.serachInput;
            this.$emit('do-search', keyWord, 1);
        },
        reSearch(){
            this.serachInput = '';
            this.$emit('re-search', 1);
        },
        addData(){
            // this.$router.push(`/edit-limited/0`);
            this.$emit('add-data');
        },
        changeContry(country){
            // console.log(country);
            if(country == 2){
                this.searchType = 'common_name';
            }else{
                this.searchType = 'chemical_name';
            }
        }
    }
}
</script>
<style>
.top-search{
    margin: 0 auto 20px;
}
.input-with-select{
    width: 70%;
}
.el-select{
    width: 130px;
}
.search-in-btn i{
    color: #fff;
}
.list-search{
    float: right;
}
.draw-box{
    margin: 15px;
}
</style>