<template>
<el-drawer :with-header="false" :visible.sync="showDrawer"
    :append-to-body="true" @opened="getDetail" :close-on-press-escape="false" 
    v-loading="loading" :before-close="handleClose" body size="85%"  :key="iecicId">
    <el-container direction="vertical">
        <el-haeder class="my-draw-header">
            <div class="my-draw-title">{{Title}}</div>
            <div class="my-draw-edit-btns">
                <el-button type="primary" @click="editData" round icon="el-icon-edit">修改</el-button>
                <el-button type="danger" @click="handleClose" circle icon="el-icon-close"></el-button>
            </div>
        </el-haeder>
        <el-main class="view-draw-box iecic-more-box">
            <!-- <iecic-review :reviewId="dataObj.id"></iecic-review> -->
            <h3 class="ay-h-titile">
                <span class="tit-span">IECIC数据</span>
            </h3>
            <el-descriptions :column="2" 
            label-class-name="ay-des-lable"
            content-class-name="ay-des-content" size="medium" border>
                <el-descriptions-item label="中文名">{{dataObj.cn_name}}</el-descriptions-item>
                <el-descriptions-item label="序号">{{dataObj.iecic_num}}</el-descriptions-item>
                <el-descriptions-item label="INCIC名称/英文名">{{dataObj.inci_name}}</el-descriptions-item>
                <el-descriptions-item label="曾用名">{{dataObj.former_cn_name}}</el-descriptions-item>
                <el-descriptions-item label="英文曾用名">{{dataObj.former_inci_name}}</el-descriptions-item>
                <el-descriptions-item label="淋洗类用量（%）">{{dataObj.iecic_rinse}}</el-descriptions-item>
                <el-descriptions-item label="驻留类用量（%）">{{dataObj.iecic_reside}}</el-descriptions-item>
                <el-descriptions-item label="技术规范要求">{{dataObj.csts_info}}</el-descriptions-item>
                <el-descriptions-item label="评估结论">{{dataObj.evaluate}}</el-descriptions-item>
                <el-descriptions-item label="备注">{{dataObj.remark}}</el-descriptions-item>
                <el-descriptions-item label="CAS编号">{{dataObj.cas}}</el-descriptions-item>
                <el-descriptions-item label="是否被禁用">
                    <el-tag type="success" v-if="dataObj.type === 0">否</el-tag>
                    <el-tag type="warning" v-else>是</el-tag>
                </el-descriptions-item>
            </el-descriptions>
            <h3 class="ay-h-titile"><span class="tit-span">CIR数据</span></h3>
            <el-descriptions :column="2" 
            label-class-name="ay-des-lable"
            content-class-name="ay-des-content" size="medium" border>
                <el-descriptions-item label="CIR淋洗（%）">{{dataObj.cir_rinse}}</el-descriptions-item>
                <el-descriptions-item label="CIR驻留（%）">{{dataObj.cir_reside}}</el-descriptions-item>
                <el-descriptions-item label="CIR儿童（%）">{{dataObj.cir_baby}}</el-descriptions-item>
                <el-descriptions-item label="CIR其它（%）">{{dataObj.cir_other}}</el-descriptions-item>
                <el-descriptions-item label="CIR备注">{{dataObj.cir_remark}}</el-descriptions-item>
                <el-descriptions-item label="CIR评估结论">{{dataObj.aoac_cir}}</el-descriptions-item>
                <el-descriptions-item label="CIR报告">
                    <el-button type="success" v-if="dataObj.cir_report_id"
                     icon="el-icon-view" size="mini" round @click="viewCirPdf(dataObj.cir_report_id)">查看</el-button>
                    <el-button type="info" size="mini" round v-else>无报告</el-button>
                </el-descriptions-item>
            </el-descriptions>
            <iecic-cir :key="dataObj.update_time + dataObj.id" :iecicData="dataObj" showEdit="true" v-on:view-pdf="viewCirPdf"></iecic-cir>
            <iecic-risk :key="dataObj.update_time + dataObj.id" :iecicData="dataObj" showEdit="true"></iecic-risk>
            <h3 class="ay-h-titile"><span class="tit-span">Cosing 数据</span></h3>
            <cosing-detail :key="dataObj.cosing_id" :cosingId="dataObj.cosing_id" v-on:view-pdf="viewPdf"></cosing-detail>
            <!-- <el-descriptions :column="2" 
            label-class-name="ay-des-lable"
            content-class-name="ay-des-content" size="medium" border>
                <el-descriptions-item label="Functions">{{dataObj.functions}}</el-descriptions-item>
                <el-descriptions-item label="其它A">{{dataObj.cir_baby_top}}</el-descriptions-item>
                <el-descriptions-item label="其它B">{{dataObj.cir_baby_top}}</el-descriptions-item>
            </el-descriptions> -->
            <!-- <cir-iecic-list :iecicData="dataObj" showEdit="true" :key="dataObj.update_time + dataObj.id" v-on:reload="reloadData"></cir-iecic-list>
            <risk-iecic-list :iecicData="dataObj" showEdit="true" :key="dataObj.update_time + dataObj.id"></risk-iecic-list> -->
            <div class="empty-box"></div>
        </el-main>
        <view-pdf ref="viewPDFCOM"></view-pdf>
        <edit-iecic ref="EditIecicTem" v-on:edit-done="reloadData"></edit-iecic>
    </el-container>
</el-drawer>
</template>
<script>
import { getIecicDetail } from '../../service/newApi';

import { getPdfUrlById } from '../../service/api';
// import CirIecicList from '../cirreport/cir-iecic-list.vue';

import viewPdf from '../view-pdf.vue';
import CosingDetail from '../cosing/cosing-detail.vue';
import IecicRisk from '../risk/iecic-risk.vue';
import IecicCir from '../cir/iecic-cir.vue';
import EditIecic from './edit-iecic.vue';
export default {
    components: {
        IecicRisk,
        viewPdf,
        CosingDetail,
        IecicCir,
        EditIecic
    },
    data(){
        return {
            showDrawer: false,
            iecicId: 0,
            showEdit: true,
            Title: 'IECIC原料详情',
            dataObj: {},
            loading: false,
        }
    },
    methods: {
        getDetail(){
            this.loading = true;
            getIecicDetail({
                id: this.iecicId
            }).then(res => {
                this.dataObj = res.data;
                this.Title = res.data.cn_name;
                this.loading = false;
            }, err => {
                this.$message.error(err.msg);
                this.loading = false;
            })
        },
        reloadData(){
            // let id = this.iecicId;
            this.getDetail();
        },
        open(id, isHide){
            this.showDrawer = true;
            if(isHide){
                this.showEdit = false;
            }
            this.iecicId = id;
        },
        close(){
            this.showDrawer = false;
        },
        handleClose(){
            this.Title = 'IECIC数据详情';
            this.dataObj = {};
            this.close();
        },
        editData(){
            // this.$emit('edit', this.dataObj);
            this.$refs.EditIecicTem.open(this.dataObj);
        },
        viewPdf(obj){
            this.$refs.viewPDFCOM.showDrawer({
                title: obj.title,
                url: obj.url
            });
        },
        viewCirPdf(id){
            if(id && id > 0){
                getPdfUrlById({
                    pdf_id: id
                }).then(res => {
                    let obj = res.data;

                    let url = `/cir/${obj.file_name}`;

                    // console.log(url);
                    // url = `/mnapi/pdf/cosing/out_191.pdf`
                    this.viewPdf({
                        title: obj.title,
                        url: url
                    })
                }, err => {
                    this.$message.error(err.msg);
                });
            }else{
                this.$message.error('参数无效！');
            }
        }
    }
}
</script>
<style lang="less">
.iecic-more-box{
    padding-top: 0 !important;
}
.ay-des-lable{
    width: 15%;
}
.ay-des-content{
    width: 35%;
}
</style>