<template>
    <el-drawer :title="Title" :with-header="false" :append-to-body="true" :visible.sync="showViews" size="80%">
        <div class="my-draw-header">
            <div class="my-draw-title">{{Title}}</div>
            <div class="my-draw-edit-btns">
                <el-button type="primary" @click="editLimited" size="medium" round icon="el-icon-edit">编辑</el-button>
                <el-button type="warning" size="medium" @click="close" round icon="el-icon-close">关闭</el-button>
            </div>
        </div>
        <div class="view-draw-box">
            <el-form label-position="left" label-width="200px">
                <el-form-item label="INCI名称: ">
                    <span>{{ CosingInfo.inci_name }}</span>
                    <el-button  class="copy-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(CosingInfo.inci_name)"></el-button>
                </el-form-item>
                <el-form-item label="CAS编号: ">
                    <span>{{ CosingInfo.cas_num }}</span>
                    <el-button  class="copy-btn" size="mini" 
                    icon="el-icon-document-copy" 
                    type="primary" plain circle  @click="copyText(CosingInfo.cas_num)"></el-button>
                </el-form-item>
                <el-form-item label="EC编号: ">
                    <span>{{ CosingInfo.ec_num }}</span>
                </el-form-item>
                <el-form-item label="原料图片: ">
                    <span v-if="CosingInfo.img_file"><img :src="'https://cdn.cosassess.com/public/chimericalimgs/' + CosingInfo.img_file" /></span>
                </el-form-item>
                <el-form-item label="Description: ">
                    <div class="html-box" v-html="CosingInfo.description"></div>
                </el-form-item>
                <el-form-item label="Functions: ">
                    <div class="fn-list">
                        <p v-for="ite in CosingInfo.functions" :key="ite">{{ite.name}}</p>
                    </div>
                </el-form-item>
                <el-form-item label="Cosmetics Regulation provisions: ">
                    <span>{{ CosingInfo.cosmetics_regulation }}</span>
                </el-form-item>
                <el-form-item label="SCCS opinions: ">
                    <div class="fn-list">
                        <p v-for="ite in CosingInfo.sccs_infos" :key="ite">
                            <span>{{ite.title}}</span>
                            <el-button @click="oepnPdf(ite)" class="ma-left" size="mini" plain round icon="el-icon-view" type="primary">查看报告</el-button>
                        </p>
                    </div>
                </el-form-item>
                <el-form-item label="原始数据 ">
                    <el-button type="primary" class="out-btn" icon="el-icon-s-promotion" size="mini" plain round @click="viewCosing(CosingInfo.cosing_id)">查看Cosing数据</el-button>
                </el-form-item>
            </el-form>
        </div>
        <view-pdf ref="viewPDFCOM"></view-pdf>
    </el-drawer>
</template>
<script>
import viewPdf from '../components/view-pdf.vue';
import { getCosingById } from '../service/api';
export default {
    components: { viewPdf },
    data(){
        return {
            id: 0,
            showViews: false,
            Title: `Cosing数据详情`,
            CosingInfo: {}
        }
    },
    methods: {
        close(){
            this.showViews = false;
        },
        saveDone(){
            this.close();
        },
        getText(){
            if(this.id > 0){
                getCosingById({
                    id: this.id
                }).then(res => {
                    // console.log(res);
                    // this.reportText = res.data.text;
                    this.CosingInfo = res.data;
                }, err => {
                    this.$message.error(err.msg);
                });
            }
        },
        copyText(txt){
            // console.log(window);
            if(!txt){
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(txt)
                .then(() => {
                    this.$message({
                        message: `复制成功`,
                        type: 'success'
                    });
                }, err => {
                    this.$message.error(`复制失败，请手动复制 err=${err}`);
                });
        },
        viewCosing(id){
            let url = `https://ec.europa.eu/growth/tools-databases/cosing/index.cfm?fuseaction=search.details_v2&id=${id}`;

            this.$confirm('即将离开系统，前往第三方网站，请确认！', '跳转提示', {
                confirmButtonText: '继续访问',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                window.open(url, '_blank');
            }).catch(() =>{

            });
        },
        oepnPdf(ite){
            // let config = this.$ComFn.staticUrl();
            if(ite && ite.file_name && ite.file_name.indexOf('.pdf') > -1){
                let url = `/cosing/${ite.file_name}`;

                this.$refs.viewPDFCOM.showDrawer({
                    title: ite.title,
                    url: url
                });

                // window.open(url, '_blank');
            }else{
                this.$message.error('文件无效，无法访问，请联系管理员！');
                return;
            }
        },
        open(id){
            this.id = id;
            this.getText();
            this.showViews = true;
        }
    }
}
</script>
<style>
.fn-list p{
    margin: 0 auto;
}
</style>