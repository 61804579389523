<template>
    <div class="ay-bg-box" >
        <h3 class="ay-h-titile">
            <span class="tit-span">参考文献</span>
            <el-button class="ma-left" icon="el-icon-plus" :disabled="!showEdit" size="small" @click="addRiskIds" type="primary">添加</el-button>
            <el-tag class="ma-left" type="warning" v-if="!showEdit">请先保存IECIC基础数据</el-tag>
        </h3>
        <div class="ay-own-box">
            <cir-linked-list :key="getDataTimes"
                ref="cirLinkedListTem"
                :iecic_id="iecicData.id" :hide_linked="true"
                v-on:cancel-match="unMatch"
                v-on:view-pdf="viewPdf"
                v-on:reload-linkedids="reLoadLinkedIds"></cir-linked-list>
        </div>
        <edit-cir ref="editCirTem" :refereId="editId" v-on:add-suc="reloadData"></edit-cir>
        <cir-checked ref="iecicCirCheckTem" :cirIds="linkedIds" v-on:edit-done="reloadData"></cir-checked>
    </div>
</template>
<script>
import {cancelLinkedIecic} from '../../service/newApi';
import EditCir from './edit-cir.vue';
import CirChecked from './cir-checked.vue';
import CirLinkedList from './cir-linked-list.vue';
export default {
    props: ['iecicData', 'showEdit'],
    components: {
        EditCir,
        CirChecked,
        CirLinkedList
    },
    data(){
        return {
            dataList: [],
            noneData: true,
            errMsg: '无风险物质数据',
            getDataTimes: 0,
            iecicId: 0,
            linkedIds: []
        }
    },
    methods:{
        copyText(text){
            if (!text) {
                this.$message.error(`空内容，无法复制！`);
                return;
            }
            this.$copyText(text).then(() => {
                this.$message({
                    message: `复制成功`,
                    type: 'success'
                });
            }, err => {
                this.$message.error(`复制失败，请手动复制 err=${err}`);
            });
        },
        getData(){
            if(!this.iecicData) return;
            // console.log(this.iecicData);
            this.iecicId = this.iecicData.id;
            this.getDataTimes = Date.now();
        },
        reLoadLinkedIds(ids){
            this.linkedIds = ids;
        },
        editData(ite){
            this.$refs.editCirTem.open(ite);
        },
        reloadData(){
            this.getData();
        },
        unMatch(ite){
            let msg = `确定要取消参考文献【${ite.title}】与原料【${this.iecicData.cn_name}}】的关联吗？`
            this.$confirm(msg, '重要提示', {
                    confirmButtonText: `确定`,
                    cancelButtonText: `取消`
                }).then(() => {
                    cancelLinkedIecic({
                        iecic_id: this.iecicId,
                        type: 'cir',
                        linked_id: ite.id
                    }).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.reloadData();
                    }, err => {
                        this.$message.error(err.msg);
                        this.loading = false;
                    });
                }).catch(() => {
                    
                });
        },
        addRiskIds(){
            let data = this.iecicData;
            this.$refs.iecicCirCheckTem.open(data);
        },
        viewPdf(id){
            this.$emit('view-pdf', id);
        }
    },
    mounted: function(){
        this.getData();
        this.getDataTimes = 0;
    }
}
</script>