<template>
    <p>Page not found222</p>
</template>

<script>
//   import MainLayout from '../layouts/Main.vue'
  export default {
    components: {
      
    }
  }
</script>