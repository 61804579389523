<template>
    <el-container>
        <el-main>
            <h3>美国/欧洲 INCI 名称核对</h3>
            <!-- <h3 v-if="country == 1">美国（US）INCI</h3>
            <h3 v-if="country == 2">欧盟 (EU) INCI</h3>
            <h3 v-if="country == 3">欧盟 (CA)) INCI</h3> -->
            <el-form label-width="100px">
                <el-form-item label="国家">
                    <el-radio-group v-model="country">
                        <el-radio label="1">美国（US）</el-radio>
                        <el-radio label="2">欧洲（UA）</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="原料名称">
                    <el-input type="textarea" rows="4" v-model="keys" placeholder="请输入原料名称/CAS编号，每一行代表一个名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :disabled="!keys" @click="doSearch('name')">INCI名称查询</el-button>
                    <el-button type="danger" :disabled="!keys" @click="doSearch('cas')">CAS编号查询</el-button>
                    <el-button type="warning" :disabled="!keys" @click="clearData">清空数据</el-button>
                </el-form-item>
            </el-form>

            <el-table :data="dataList"
            stripe :row-class-name="tableRowClassName">
                <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
                <el-table-column prop="key" label="查询名称"></el-table-column>
                <el-table-column prop="inci_name">
                    <template slot="header">
                        <div class="tb-header-box">
                            <label>INCI 名称</label>
                            <el-button size="mini" class="table-head-btn" 
                            icon="el-icon-copy-document" 
                            type="primary" plain circle  @click="copyData('inci_name')"></el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="cn_name">
                    <template slot="header">
                        <div class="tb-header-box">
                            <label>中文名称</label>
                            <el-button size="mini" class="table-head-btn" 
                            icon="el-icon-copy-document" 
                            type="primary" plain circle  @click="copyData('cn_name')"></el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="cas">
                    <template slot="header">
                        <div class="tb-header-box">
                            <label>CAS</label>
                            <el-button size="mini" class="table-head-btn" 
                            icon="el-icon-copy-document" 
                            type="primary" plain circle  @click="copyData('cas')"></el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="匹配情况" width="80">
                    <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.pair ? 'success' : 'danger'"
                    disable-transitions>{{scope.row.pair ? 'YES' : 'NO'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="编辑/修改" width="100">
                    <template slot-scope="scope">
                        <el-button size="mini" icon="el-icon-edit" type="primary" plain round  @click="editRef(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <edit-uainci ref="EditUaInci" v-on:add-suc="doneAdd"></edit-uainci>
    </el-container>
</template>
<script>
import EditUainci from './edit-uainci.vue';
import {checkInciNames, checkInciByCAS} from "../service/api";
export default {
    components: {EditUainci},
    // props: ['country'],
    data(){
        return {
            dataList: [],
            keys: '',
            country: '1',
            searchBy: 'name'
        }
    }, 
    methods: {
        indexMethod(index) {
            return index + 1;
        },
        doSearch(tp){
            // console.log(1);
            if(!tp){
                tp = this.searchBy;
            }
            this.searchBy = tp;
            let searchNames = this.keys;
            if(searchNames){
                let searchArry;
                // console.log(searchNames.search(/\n/));
                if(searchNames.search(/\n/) < 0){
                    searchArry = [searchNames];
                }else{
                    searchArry = searchNames.split(/\n/);
                }

                let subArr = [];

                for(let i = 0; i < searchArry.length; i++){
                    let ite = searchArry[i];
                    if(ite && ite !== ''){
                        // ite = ite.replace(/(\s)*(）|\))(\s)*/g, '）');
                        // ite = ite.replace(/(\s)*(（|\()(\s)*/g, '（');
                        ite = ite.replace(/(^\s*)|(\s*$)/g, '');
                        subArr.push(ite);
                    }
                }
                // console.log(subArr);
                if(tp === 'name'){
                    checkInciNames({
                        country: this.country,
                        inci_names: encodeURIComponent(JSON.stringify(subArr))
                    }).then(res => {
                        // let data = res.data;
                        this.dataList = res.data;
                        // console.log(res);
                    }, err => {
                        this.$message.error(err.msg);
                    });
                }else{
                    checkInciByCAS({
                        inci_cases: encodeURIComponent(JSON.stringify(subArr)),
                        country: this.country
                    }).then(res => {
                        // console.log(res);
                        this.dataList = res.data;
                    }, err => {
                        this.$message.error(err.msg);
                    })
                }
            }else{
                this.$message.error(`请输入关键词！`);
            }
        },
        clearData(){
            this.keys = null;
        },
        copyData(key){
            let data = this.dataList
            let str = '';
            const titles = {
                inci_name: 'INCI 名称',
                cn_name: '中文别名',
                cas: 'CAS',
            }
            let total_tit = titles[key.toString()];
            if(data && data.length > 0){
                data.forEach((k) =>{
                    str += (k[key.toString()] ? k[key.toString()] : '') + '\n';
                });
                // console.log(str);
                this.$copyText(str)
                    .then(() => {
                        this.$message({
                            message: `${total_tit}复制成功`,
                            type: 'success'
                        });
                    }, err => {
                        this.$message.error(`复制失败，请手动复制 err=${err}`);
                    });
            }else{
                this.$message.error('无有效数据！');
            }
        },
        tableRowClassName({row}){
            // console.log(row);
            return row && row.pair ? '' : 'none-pair';

        },
        editRef(ite){
            // console.log(ite);
            this.$refs.EditUaInci.editData(ite);
        },
        doneAdd(){
            // this.searchBy = 'name';
            this.doSearch();
        }
    }
}
</script>