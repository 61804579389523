<template>
    <div class="head-box">
        <h2>{{title}}</h2>
        <div class="right-user">

        </div>
    </div>
</template>
<script>

export default {
    props: ['title'],
}
</script>
<style >

</style>
